import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets';
import { Link } from "react-router-dom";

import './not-found.css';

const NotFound = () => {
    return (
        <div className='page-not-found'>
            <div className='reset-header'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <strong className="logo">
                                <Link to="/">
                                    <img src={Images.logo} className="img-fluid" alt="StalkingStream Logo" />
                                </Link>
                            </strong>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='not-found-section text-center'>
                <div className='not-found-img'>
                    <img src={Images.notFound} className="img-fluid" alt="Not Found" />
                </div>
                <div className='not-found-text'>
                    <h2>404</h2>
                    <h3>Page Not Found</h3>
                </div>
            </div>
        </div>
    )
}

export default NotFound