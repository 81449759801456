import {
  GET_REFERRAL_DATA,
  GET_REFERRAL_LIST,
  BEFORE_REFERRAL,
} from "../../redux/types";

const initialState = {
  arrayOfReferredUser: null,
  nameOfReferredBy: null,
  emailOfReferredBy: null,
  RefLinkString: null,
  RefReward: null,
  refAuth: false,
  refListAuth: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REFERRAL_DATA:
      return {
        ...state,
        nameOfReferredBy: action.payload.nameOfReferredBy,
        emailOfReferredBy: action.payload.emailOfReferredBy,
        RefLinkString: action.payload.referralKey,
        RefReward: action.payload.referralreward,
        refAuth: true,
      };
    case GET_REFERRAL_LIST:
      return {
        ...state,
        arrayOfReferredUser: action.payload.userdata,
        refListAuth: true,
      };
    case BEFORE_REFERRAL:
      return {
        nameOfReferredBy: "",
        emailOfReferredBy: "",
        RefLinkString: "",
        RefReward:0,
        arrayOfReferredUser: "",
        refListAuth: false,
        refAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}
