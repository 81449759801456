import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { Images } from "../../assets/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import lottie from "lottie-web";
import purplePerson from "../../assets/lottie-files/lf30_editor_xwvnmbcj.json";
import whitePerson from "../../assets/lottie-files/lf30_editor_1pasic0k.json";
import whiteWallet from "../../assets/lottie-files/lf30_editor_g5z4wg61.json";
import purpleWallet from "../../assets/lottie-files/lf30_editor_lon2uecv.json";
import { ENV } from "../../config/config";
import { beforeUser, signOut } from "../signup/user.action";
import { useDispatch } from "react-redux";

import "./Header.css";
import Wallet from "../wallet/wallet";

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#purple-person"),
      animationData: purplePerson,
    });
    lottie.loadAnimation({
      container: document.querySelector("#white-person"),
      animationData: whitePerson,
    });
    lottie.loadAnimation({
      container: document.querySelector("#purple-person1"),
      animationData: purplePerson,
    });
    lottie.loadAnimation({
      container: document.querySelector("#white-person1"),
      animationData: whitePerson,
    });
    lottie.loadAnimation({
      container: document.querySelector("#white-wallet1"),
      animationData: whiteWallet,
    });
    lottie.loadAnimation({
      container: document.querySelector("#purple-wallet1"),
      animationData: purpleWallet,
    });
  }, []);
  const user = ENV.getUserKeys();

  return (
    <header id="header">
      <Container>
        <div className="nav-holder">
          <Navbar expand="lg">
            <Container>
              <strong className="logo">
                <Link to="/">
                  <img
                    src={Images.logo}
                    className="img-fluid"
                    alt="StalkingStream Logo"
                  />
                </Link>
              </strong>
              <div className="nav-menu d-flex justify-content-between align-items-center">
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                  <FontAwesomeIcon icon={faBars} className="bars-icon" />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                  <ul className="navbar-nav ml-auto">
                    <li
                      className={`nav-item ${
                        pathname?.toLowerCase() === "/" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to="/">
                        Home{" "}
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        pathname?.toLowerCase() === "/about-us" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to="/about-us">
                        About us
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        pathname?.toLowerCase() === "/packages" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to="/packages">
                        Packages
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        pathname?.toLowerCase() === "/referral" ? "active" : ""
                      }`}
                    >
                      <Link className="nav-link" to="/referral">
                        Referral
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        pathname?.toLowerCase() === "/contact-us"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link className="nav-link" to="/contact-us">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                  {/* work below 480 */}
                  <div className="header-buttons header-collapse-buttons header-collapse-buttons align-items-center">
                    <Link className="white-btn" to="/login">
                      <span
                        id="purple-person1"
                        className="purple-person"
                      ></span>
                      <span id="white-person1" className="white-person"></span>
                      <span className="btn-text">Login</span>
                    </Link>
                    {/* <Link className='purple-btn'>
											<span id="purple-wallet1" className='purple-wallet'></span>
											<span id="white-wallet1" className='white-wallet'></span>
											<span className='btn-text'>Connect Wallet 13</span>
										</Link> */}
                    <Wallet name="purple-wallet" nameTwo="white-wallet" />
                  </div>
                </Navbar.Collapse>
                {/* work from 1920px to 480px */}
                <div className="header-buttons header-main-buttons align-items-center">
                  {user?.accessToken ? (
                    ""
                  ) : (
                    <Link className="white-btn" to="/login">
                      <span id="purple-person" className="purple-person"></span>
                      <span id="white-person" className="white-person"></span>
                      <span className="btn-text">Login</span>
                    </Link>
                  )}
                  {/* <span className='purple-btn' > */}
                  {/* <span id="purple-wallet" className='purple-wallet'></span>
										<span id="white-wallet" className='white-wallet'></span> */}
                  {/* <span className='btn-text'>Connect Wallet tt</span> */}
                  <Wallet name="purple-wallet1" nameTwo="white-wallet1" />
                  {/* </span> */}
                </div>
              </div>
            </Container>
          </Navbar>
        </div>
      </Container>
    </header>
  );
}
export default Header;
