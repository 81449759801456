import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import { Accordion, Row, Col } from 'react-bootstrap';
import "./myAccount.css"
import { connect } from 'react-redux'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { enablesSecurity, getSecurity, beforeSecurity } from '../../components/signup/user.action'
import ToastModal from "../toast/toast";

function Security(props) {

    const [successMsg, setSuccessMsg] = useState(null)
    const [showToast, setShowToast] = useState(false)

    const [data, setData] = useState({
        login: false,
        withdrawal: false,
        accountDeletion: false,
        changeEmail: false,
        changePassword: false,
    })

    useEffect(() => {
        props.beforeSecurity()
        props.getSecurity()
    }, [])

    useEffect(() => {
        if (props.user.getSecurityAuth) {
            const { security } = props.user
            let tempData = data
            tempData["login"] = security?.login
            tempData["withdrawal"] = security?.withdrawal
            tempData["accountDeletion"] = security?.accountDeletion
            tempData["changeEmail"] = security?.changeEmail
            tempData["changePassword"] = security?.changePassword
            setData({ ...tempData })
        }
    }, [props.user.getSecurityAuth])

    useEffect(() => {
        if (props.user.createSecurityAuth) {
            const { security } = props.user
            let tempData = data
            tempData["login"] = security?.login
            tempData["withdrawal"] = security?.withdrawal
            tempData["accountDeletion"] = security?.accountDeletion
            tempData["changeEmail"] = security?.changeEmail
            tempData["changePassword"] = security?.changePassword
            setShowToast(true)
            setSuccessMsg(props.user.createSecurityAuthMessage)
            setData({ ...tempData })
            props.beforeSecurity();

            // console.log("props.user.createSecurityAuth = ", props.user.createSecurityAuth)
        } else {
            setShowToast(false)
        }
    }, [props.user.createSecurityAuth])

    const changeData = (name, value) => {
        let dataObj = { ...data }
        dataObj[name] = value
        setData({ ...dataObj })
        submitForm(dataObj)
    }

    const submitForm = (dataObj) => {
       // console.log("submit form hello ===== ")
        props.beforeSecurity()
        props.enablesSecurity(dataObj)
    }

    return (
        <>
            {
                showToast &&
                <ToastModal
                    show={showToast}
                    setShow={setShowToast}
                    type="success"
                    description={successMsg}
                />
            }
            <div className="security">
                <Form>
                    <Row>
                        <Col md={12}>
                            <h3>Google Authenticator</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="account-inputs d-flex justify-content-between flex-wrap">
                                <div className="swtich-tag"><span>Login</span></div>
                                <div className="switch-section">
                                   
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        name="login"
                                        checked={data?.login}
                                        onChange={(e) => { changeData(e.target.name, e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="account-inputs d-flex justify-content-between flex-wrap">
                                <div className="swtich-tag"><span>Withdrawal requests </span></div>
                                <div className="switch-section">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        name="withdrawal"
                                        checked={data.withdrawal}
                                        onChange={(e) => { changeData(e.target.name, e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="account-inputs d-flex justify-content-between flex-wrap">
                                <div className="swtich-tag"><span>Account deletion request</span></div>
                                <div className="switch-section">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        name="accountDeletion"
                                        checked={data.accountDeletion}
                                        onChange={(e) => { changeData(e.target.name, e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="account-inputs d-flex justify-content-between flex-wrap">
                                <div className="swtich-tag"><span>Change E-Mail request </span></div>
                                <div className="switch-section">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        name="changeEmail"
                                        checked={data.changeEmail}
                                        onChange={(e) => { changeData(e.target.name, e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="account-inputs d-flex justify-content-between flex-wrap">
                                <div className="swtich-tag"><span>Change Password request </span></div>
                                <div className="switch-section">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        name="changePassword"
                                        checked={data.changePassword}
                                        onChange={(e) => { changeData(e.target.name, e.target.checked) }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end align-items-center pt-3 pb-5">
                        <button type="submit" className="purple-border-btn"><span>Save</span></button>
                    </div>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, { enablesSecurity, getSecurity, beforeSecurity })(Security)






// < Accordion defaultActiveKey = "0" >
//     { console.log("ddddddata", data) }
//     < Accordion.Item eventKey = "0" >
//                     <Accordion.Header className="flex-wrap"><FontAwesomeIcon className="google-icon" icon={faGoogle} />  <strong>Google Authenticator</strong></Accordion.Header>
//                     <Accordion.Body>
//                         <ul className="w-100">
//                             <li className="account-inputs d-flex justify-content-between flex-wrap">
//                                 <div> <span>Login</span></div>
//                                 <div>
//                                     <Form>
//                                         {console.log("Dataaa.login", data.login)}
//                                         <Form.Check
//                                             type="switch"
//                                             id="custom-switch"
//                                             name="login"
//                                             checked={data?.login}
//                                             onChange={(e) => { changeData(e.target.name, e.target.checked) }}
//                                         />
//                                     </Form>
//                                 </div>
//                             </li>
//                             <li className="account-inputs d-flex justify-content-between flex-wrap">
//                                 <div> <span>Withdrawal requests </span></div>
//                                 <div>
//                                     <Form>
//                                         <Form.Check
//                                             type="switch"
//                                             id="custom-switch"
//                                             name="withdrawal"
//                                             checked={data.withdrawal}
//                                             onChange={(e) => { changeData(e.target.name, e.target.checked) }}
//                                         />
//                                     </Form>
//                                 </div>
//                             </li>
//                             <li className="account-inputs d-flex justify-content-between flex-wrap">

//                             </li>
//                             <li className="account-inputs d-flex justify-content-between flex-wrap">

//                             </li>
//                             <li className="account-inputs d-flex justify-content-between flex-wrap">

//                             </li>
//                         </ul>
//                     </Accordion.Body>
//                 </ >
{/* <Accordion.Item eventKey="1">
                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item> */}
                // </Accordion >