import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from "react-bootstrap";
import { beforeQr, otpVerfication, getQrCode } from './qr.action'
import { connect } from 'react-redux'
import { ENV } from "../../config/config";
// import "../myAccount/myAccount.css"
// import "./deposit.css"
import { useNavigate } from 'react-router-dom'

function QrCode(props) {
    const [qr, setQr] = useState('');
    const [otp, setOtp] = useState(0);
    const navigate = useNavigate();

    const twoFactorTempSecret = ENV.getUserKeys("twoFactorTempSecret") &&
    ENV.getUserKeys("twoFactorTempSecret").twoFactorTempSecret
      ? ENV.getUserKeys("twoFactorTempSecret").twoFactorTempSecret
      : "" ;
    
    // const userId = ENV.getBalanceKeys()?._id
    useEffect(() => {
        if (localStorage.getItem('encuse'))
        { 
        if(!twoFactorTempSecret)
        {
             props.getQrCode()
          }
        }
    }, [localStorage.getItem('encuse')])

    useEffect(() => {
        if (props.qr.getQrCodeAuth) {
            // console.log("ppprops.qr", props.qr.getQrCode.data)
            setQr(props.qr.getQrCode.data)
            props.beforeQr()
        }
    }, [props.qr.getQrCodeAuth])


    useEffect(() => {
        console.log("props.qr.otpVerified", props.qr.otpVerfied)
        if (props.qr.otpVerfiedAuth) {
            if (props.qr?.otpVerfied?.isVerified) {
                props.setVerified(true)
                props.onHide();
                navigate(props.redirctPath)
                console.log("its hide now")
            }
            props.beforeQr()
        }
    }, [props.qr.otpVerfiedAuth])

    const handleOtpSend = () => {
        if (!otp)
            console.log("otp not sent")
        else {
            let obj = { userToken: otp }
            const query = ENV.objectToQueryString(obj)
            props.otpVerfication(query)
        }

    }

    return (
        <Modal
            // {...props}
            show={props.show}
            onHide={() => {
               
                props.setVerified(false)
                props.onHide()
            }
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="security-modal address-book-create"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please Enter your 2FA code
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="pt-4">
                    <Col lg={2}>
                        <div>
                            <img className="img-fluid" src={qr} alt="" />
                        </div>
                    </Col>
                    <Col lg={10}>
                        <span>Your payment address</span>


                        <div className="mb-4 input position-relative">
                            <label>Security Code</label>
                            <div className="code-inputs"> <input placeholder="Enter security code" onChange={(e) => setOtp(e.target.value)} /></div>
                            <a href="" className="copy-icon">
                                {/* <img src={Copy} alt="" /> */}
                            </a>
                        </div>
                        <p className="guide-alert">Please copy and paste the address above directly to your wallet or any other third-party platform from which you are going to send funds.</p>
                    </Col>

                </Row>
                <div className="d-flex justify-content-center">
                    <Button className="btn-triage-div btn-triage" onClick={handleOtpSend}><span>Send</span></Button>
                </div>
            </Modal.Body>

        </Modal>
    );
}


const mapStateToProps = (state) => ({
    qr: state.qr
})


export default connect(mapStateToProps, { beforeQr, otpVerfication, getQrCode })(QrCode)

// export default connect(mapStateToProps, { beforeCms, getCms })(Cms);