import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../assets/assets";

import "./lowerFooter.css";

function LowerFooter() {
  return (
    <>
      <div className="lower-footer">
        <div className="company-logo">
          <a href="https://www.arhamsoft.com/" target="_blank">
            <img src={Images.AS_Logo} alt="" />
          </a>
        </div>
        <Container>
          <div className="d-flex justify-content-center align-items-center">
            <span className="copyright ">
              <span>&#169;</span>2022 Staking FIXM. All rights Reserved.{" "}
              <Link
                to="/terms-conditions"
                className="terms text-decoration-none"
              >
                {" "}
                Terms{" "}
              </Link>
              &
              <Link to="/privacy-policy" className="terms text-decoration-none">
                {" "}
                Privacy{" "}
              </Link>
              .
            </span>
          </div>
        </Container>
      </div>
    </>
  );
}
export default LowerFooter;
