import { KEY_VERIFIED, KEY_NOT_VERIFIED, BEFORE_KEY,EMAIL_SENT,BEFORE_EMAIL_SENT,NEW_EMAIL_VERIFIED,NEW_EMAIL_NOT_VERIFIED,RESET_NEW_EMAIL_FLAG } from '../../redux/types';

const initialState = {
    newEmailcheck:false,
    newNewVerified:false,
    keyVerfied: false,
    keyVerfiedAuth: false,
    emailSentAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case EMAIL_SENT:
            return {
                ...state,
                emailSentAuth: true,
            }
            case BEFORE_EMAIL_SENT:
                return {
                    ...state,
                    emailSentAuth: false,
                }
        case KEY_VERIFIED:
            return {
                ...state,
                keyVerfied: true,
                keyVerfiedAuth: true,
            }
        case KEY_NOT_VERIFIED:
            return {
                ...state,
                keyVerfied: true,
                keyVerfiedAuth: false,
            }
        case BEFORE_KEY:
            return {
                ...state,
                keyVerfied: false,
                keyVerfiedAuth: false,
                emailSentAuth: false,
            }
        case NEW_EMAIL_VERIFIED:
            return {
                ...state,
                 newEmailcheck:true,
                 newNewVerified:true,
                
            }
            case NEW_EMAIL_NOT_VERIFIED:
            return {
                ...state,
                 newEmailcheck:true,
                 newNewVerified:false,
                
            }
            case RESET_NEW_EMAIL_FLAG:
                return {
                    ...state,
                     newEmailcheck:false,
                     newNewVerified:false,
                    
                }
        default:
            return {
                ...state
            }
    }
}