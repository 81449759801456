import {SET_EARN_TOKEN,RESET_EARN_TOKEN, GET_DASHBOARD, BEFORE_DASHBOARD } from "../../redux/types";
const initialState = {
  dashboard: null,
  getDashboardAuth: false,
  earnToekn: 0,
  earnToeknAuth: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case BEFORE_DASHBOARD:
      return {
        ...state,
        dashboard: null,
        getDashboardAuth: false,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload.data,
        getDashboardAuth: true,
      };
      case SET_EARN_TOKEN:
      return {
        ...state,
        earnToekn: action.payload.data,
        earnToeknAuth: true,
      };
      case RESET_EARN_TOKEN:
      return {
        ...state,
        earnToeknAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}
