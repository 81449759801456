import React, { useState, useEffect } from "react";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { verification, beforeUser } from "../signup/user.action";
import { ENV } from "./../../config/config";
function VerfiyEmail(props) {
  const { token } = useParams();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const userAccessToken = ENV.getUserKeys("accessToken");
   // console.log("verify email page", userAccessToken.accessToken);
    props.verification(token, userAccessToken.accessToken);
    props.beforeUser();
  }, []);
  useEffect(() => {
    if (props.user.isVerified) {
      setLoader(false);
    }
  }, [props.user.isVerified]);
  return <>{loader ? <FullPageLoader /> : navigate("/dashboard")}</>;
}
const mapStateToProps = (state) => ({
  user: state.user,
  error: state.error,
});
export default connect(mapStateToProps, { verification, beforeUser })(
  VerfiyEmail
);

//isVerified
//uniqueString

//get uniqueString from url
//hid an api to update the status of the user and get auth response
//call this function
// ENV.encryptUserData(data.data);
//redirect to dashboard
