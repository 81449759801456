// require('dotenv').config();
const CryptoJS = require("crypto-js");
const dataEncryptionKey = "kalsaOOLLaASASAFFSSEE";
const moment = require("moment");
const { toast } = require("react-toastify");
import Swal from "sweetalert2";

export const ENV = {
  pixulToken: process.env.REACT_APP_PIXUL_TOKEN,
  imagebaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  blockchainexplorerUrl: process.env.REACT_APP_BLOCKAHIN_EXPLORER_URL,
  transferFundUrl: {
    ethFundTranfer: process.env.REACT_APP_ETHEREUM_FUND_TRANSFER,
    fantomFundTranfer: process.env.REACT_APP_FANTOM_FUND_TRANSFER,
    cronosFundTranfer: process.env.REACT_APP_CRONOS_FUND_TRANSFER,
    binanceFundTranfer: process.env.REACT_APP_BINANCE_FUND_TRANSFER,
  },
  numberToChainId: {
    4: 338,
    3: 4002,
    2: 97,
    1: 5,
  },
  defaultChainId: parseInt(process.env.REACT_APP_REQUIRED_CHAIN),
  ethMainRPCURL:
    "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  chainsConfigs: {
    5: {
      number: 1,
      nativeCurrency: {
        name: "Goerli",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: "https://goerli.infura.io/v3/5272532ee1fa4c23a1cbe8ca18895cfc",
      explorer: "https://goerli.etherscan.io/",
      eventKey: "Ethereum",
      title: "Ethereum",
      networkName: "Goerli Testnet",
      // currencyImage: Ethereum,
      marketplaceAddress: "0x61E46Dc1b584bAbEf6b465491C3eAc97438f4aa7",

      //"0xcdc48e2cb609c0c9d2331f5e3a963d386d38819b" ,//"0xfAd5d2936b6F2A2f82F9BAA276DF5eC9FdfdB59D",
      // NFT721Address: "0x93E92258767fAad2e65119ee87eABF71F40d964c",
      // NFT1155Address: "0x7eeE536bD39d38485D38cfCd3CE9d13FB821f25E"
    },
    97: {
      number: 2,
      nativeCurrency: {
        name: "Binance",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
      explorer: "https://testnet.bscscan.com/",
      eventKey: "Binance",
      title: "Binance",
      networkName: "Binance Testnet",
      // currencyImage: Binance,
      marketplaceAddress: "0xd1fa171a25a6eac70cbfcb69ebd0466094f68400",
      // marketplaceAddress: "0xF435F1Fe2912E566FfC06E0f06C6CD6E13148e22",
      NFT721Address: "0xC14677D1b504Ab35c0F670ec8d51943207651490",
      NFT1155Address: "0x649d719Fd04eAF99898F8e824bE78420f0205c90",
    },
    4002: {
      number: 3,
      nativeCurrency: {
        name: "Fantom",
        symbol: "FTM",
        decimals: 18,
      },
      rpcUrl: "https://rpc.testnet.fantom.network",
      explorer: "https://testnet.ftmscan.com/",
      eventKey: "Fantom",
      title: "Fantom",
      networkName: "Fantom Testnet",
      // currencyImage: Fantom,
      marketplaceAddress: "0x1aa6Cd144CD4747baB2Fe8434887adA1a0C7fE7F",
      NFT721Address: "0x3BcBd90A9C6c19e25EEd6325Ccc791B6E8B2691B",
      NFT1155Address: "0x6f82803f1FE9b3E38bc3ee9702F8db60D48E88F4",
    },
    338: {
      number: 4,
      nativeCurrency: {
        name: "Cronos",
        symbol: "CRO",
        decimals: 18,
      },
      rpcUrl: "https://cronos-testnet-3.crypto.org:8545",
      explorer: "https://testnet.cronoscan.com/",
      eventKey: "Cronos",
      title: "Cronos",
      networkName: "Cronos Testnet",
      // currencyImage: Cronos,
      marketplaceAddress: "0x92698A79dB55253E2f39060158D12dF8E36e5F31",
      NFT721Address: "0x945512603d3471588F49B6C6aAC8fEc1Be2d4b72",
      NFT1155Address: "0xfAd5d2936b6F2A2f82F9BAA276DF5eC9FdfdB59D",
    },
  },
  chainId: process.env.REACT_APP_REQUIRED_CHAIN,
  domainURL: process.env.REACT_APP_DOMAIN_URL,
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  url: process.env.REACT_APP_BASE_URL,
  currency: {
    25: "CRO",
    56: "BNB",
    97: "BNB",
    250: "FTM",
    4002: "FTM",
    1: "ETH",
    5: "ETH",
  },
  appName: process.env.REACT_APP_NAME,
  requiredChainName: {
    338: "Cronos Testnet",
    25: "Cronos",
    56: "Binance",
    97: "Binance Testnet",
    250: "Fantom",
    4002: "Fantom Testnet",
    1: "Ethereum",
    5: "Ethereum Testnet",
  },
  amountToApprove:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  currencyFilters: [
    {
      name: "Ethereum",
      label: "ETH",
      symbol: "ETH",
      value: 1,
      icon: "assets/images/ethereum.svg",
      chainIds: [1, 5],
    },
    {
      name: "Binance",
      label: "BNB",
      symbol: "BNB",
      value: 2,
      icon: "assets/images/binance.svg",
      chainIds: [56, 97],
    },
    {
      name: "Fantom",
      label: "FTM",
      symbol: "FTM",
      value: 3,
      icon: "assets/images/fantom.png",
      chainIds: [250, 4002],
    },
    {
      name: "Cronos",
      label: "CRO",
      symbol: "CRO",
      value: 4,
      icon: "assets/images/cronos.svg",
      chainIds: [25, 338],
    },
    // keep it at last index always
    {
      name: "US Dollars",
      label: "USD",
      symbol: "USD",
      value: -1,
      icon: "assets/images/usd.png",
      chainIds: null,
    },
  ],
  currencies: [
    {
      label: "WBNB",
      symbol: "WBNB",
      value: "WBNB",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WBNB_TOKEN,
    },
    {
      label: "WETH",
      symbol: "WETH",
      value: "WETH",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WETH_TOKEN,
    },
    {
      label: "WCRO",
      symbol: "WCRO",
      value: "WCRO",
      icon: "assets/images/cronos.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WCRO_TOKEN,
    },
    {
      label: "WFTM",
      symbol: "WFTM",
      value: "WFTM",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WFTM_TOKEN,
    },
    // {
    //     label: 'BNB',
    //     symbol: 'BNB',
    //     value: 'BNB',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'ETH',
    //     symbol: 'ETH',
    //     value: 'ETH',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'FTM',
    //     symbol: 'FTM',
    //     value: 'FTM',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'CRO',
    //     symbol: 'CRO',
    //     value: 'CRO',
    //     icon: 'assets/images/cronos.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wcroContractAbi
    // },
  ],
  // Headers
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
  // default images placeholders
  globalPlaceholderImage: "/img/place-holder.jpg",
  collectionFeaturedImg: "/img/place-holder.jpg", //Correct
  userDefaultImg: "/img/place-holder.jpg", //Correct
  categoryDefaultImg: "/img/placeholder.png",

  //set user in local storage
  encryptUserData: function (data) {
    let userData = localStorage.getItem("encuse");
    if (userData && !data.accessToken) {
      let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      let originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      if (originalData && originalData.accessToken) {
        data.accessToken = originalData.accessToken;
      }
    }
    data = JSON.stringify(data);
    let encryptedUser = CryptoJS.AES.encrypt(
      data,
      dataEncryptionKey
    ).toString();
    localStorage.setItem("encuse", encryptedUser);
    return true;
  },

  //return required keys
  getUserKeys: function (keys = null) {
    let userData = localStorage.getItem("encuse");
    if (userData) {
      var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      var originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      let user = {};
      if (keys) {
        keys = keys.split(" ");
        for (let key in keys) {
          let keyV = keys[key];
          user[keyV] = originalData[keyV];
        }
      } else {
        user = originalData;
      }
      return user;
    }
    return {};
  },

  getUserKeysLimited: function (keys = null) {
    let userData = localStorage.getItem("encuse");
    if (userData) {
      var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      var originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      let user = {};
      if (keys) {
        keys = keys.split(" ");
        for (let key in keys) {
          let keyV = keys[key];
          if (keyV == "lastName" || keyV == "firstName") {
          } else {
            user[keyV] = originalData[keyV];
          }
        }
      } else {
        user = originalData;
      }

      return user;
    }
    return {};
  },

  //Object to query string
  objectToQueryString: function (body) {
    const qs = Object.keys(body)
      .map((key) => `${key}=${body[key]}`)
      .join("&");
    return qs;
  },

  //validate image types
  isValidImageType: function (file) {
    if (file && file.type) {
      const acceptableTypes = [
        "image/png",
        "image/x-png",
        "image/jpeg",
        "image/jpg",
      ];
      return acceptableTypes.includes(file.type.toLowerCase());
    }
  },

  //slick configurations
  slickSettings: {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          margin: 15,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },

  dateRangeInitialSettings: {
    startDate: moment(),
    endDate: moment().add(6, "months").toDate(),
    minDate: moment(),
    maxDate: moment().add(6, "months").toDate(),
    ranges: {
      "1 Day": [moment().toDate(), moment().add(1, "days").toDate()],
      "3 Days": [moment().toDate(), moment().add(3, "days").toDate()],
      "1 Week": [moment().toDate(), moment().add(6, "days").toDate()],
    },
  },

  countDownRenderer: ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <div className="countdown d-flex  justify-content-start  align-items-center mb-2">
          <div className="values">
            <h4>Days</h4>
            <p>{days}</p>
          </div>
          <div className="values ml-5">
            <h4>Hours</h4>
            <p>{hours}</p>
          </div>
          <div className="values ml-5">
            <h4>Minutes</h4>
            <p>{minutes}</p>
          </div>
          <div className="values ml-5">
            <h4>Seconds</h4>
            <p>{seconds}</p>
          </div>
        </div>
      </>
    );
  },

  decimalNumberValidator: function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    let specialKeys = [46, 8, 9, 27, 13, 110, 190];

    if (e.target.value.includes(".")) {
      specialKeys = [46, 8, 9, 27, 13];
    } else {
      specialKeys = [46, 8, 9, 27, 13, 110, 190];
    }

    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 90 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      // Allow F1 to F12 keys
      (e.keyCode >= 112 && e.keyCode <= 123)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  },

  integerNumberValidator: function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    const specialKeys = [46, 8, 9, 27, 13];

    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 90 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  },

  //convert mongoose exponent floating value into valid values
  exponentialToDecimal: (exponential) => {
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes("e+")) {
      const exponentialSplitted = decimal.split("e+");
      let postfix = "";
      for (
        let i = 0;
        i <
        +exponentialSplitted[1] -
          (exponentialSplitted[0].includes(".")
            ? exponentialSplitted[0].split(".")[1].length
            : 0);
        i++
      ) {
        postfix += "0";
      }
      const addCommas = (text) => {
        let j = 3;
        let textLength = text.length;
        while (j < textLength) {
          text = `${text.slice(0, textLength - j)},${text.slice(
            textLength - j,
            textLength
          )}`;
          textLength++;
          j += 3 + 1;
        }
        return text;
      };
      decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
    }
    if (decimal.toLowerCase().includes("e-")) {
      const exponentialSplitted = decimal.split("e-");
      let prefix = "0.";
      for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
        prefix += "0";
      }
      decimal = prefix + exponentialSplitted[0].replace(".", "");
    }
    return parseFloat(decimal);
  },

  convertXtoY: (val, rateUnit, qty = 1) => {
    return parseFloat((val * qty * rateUnit).toFixed(10)).toFixed(5);
  },

  convertRateToUsd: (value, unit) => {
    return (parseFloat(value) * parseFloat(unit)).toFixed(5);
  },

  convertChainRate: (value, unit) => {
    if (unit !== 0) return (value / unit).toFixed(5);
  },
  ChainOptions: [
    //for Dropdown
    { value: 1, label: "Ethereum", chainIds: [1, 5] },
    { value: 2, label: "Binance", chainIds: [56, 97] },
    { value: 3, label: "Fantom", chainIds: [250, 4002] },
    { value: 4, label: "Cronos", chainIds: [25, 338] },
  ],
  config1: {
    price: {
      blockChain: "",
      currency: "",
      amount: "",
    },
    listingSchedule: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    reserveFor: "", // if user selects reserve buyer
    quantity: 1,
  },
  config2: {
    method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
    startPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    endPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    duration: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    // if user includes reserve price
    reservePrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
  },
  config3: {
    method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
    startPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    endPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    duration: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    // if user includes reserve price
    reservePrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
  },
  tokenStandards: {
    TS1: "ERC-721",
    TS2: "ERC-1155",
  },
  formatAddress: (address) => {
    return address ? address.substr(0, 7) + "..." + address.substr(-4) : null;
  },
  copy: (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Address Copied To Clipboard.");
  },

  TicketOptions: [
    //for ticket Dropdown
    { value: "information", label: "Information" },
    { value: "issue", label: "Issue" },
  ],
  countriesOption: [
    { value: "AD", label: "Andorra" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "AF", label: "Afghanistan" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AI", label: "Anguilla" },
    { value: "AL", label: "Albania" },
    { value: "AM", label: "Armenia" },
    { value: "AO", label: "Angola" },
    { value: "AQ", label: "Antarctica" },
    { value: "AR", label: "Argentina" },
    { value: "AS", label: "American Samoa" },
    { value: "AT", label: "Austria" },
    { value: "AU", label: "Australia" },
    { value: "AW", label: "Aruba" },
    { value: "AX", label: "Åland Islands" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BB", label: "Barbados" },
    { value: "BD", label: "Bangladesh" },
    { value: "BE", label: "Belgium" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BG", label: "Bulgaria" },
    { value: "BH", label: "Bahrain" },
    { value: "BI", label: "Burundi" },
    { value: "BJ", label: "Benin" },
    { value: "BL", label: "Saint Barthélemy" },
    { value: "BM", label: "Bermuda" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BO", label: "Bolivia, Plurinational State of" },
    { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
    { value: "BR", label: "Brazil" },
    { value: "BS", label: "Bahamas" },
    { value: "BT", label: "Bhutan" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BW", label: "Botswana" },
    { value: "BY", label: "Belarus" },
    { value: "BZ", label: "Belize" },
    { value: "CA", label: "Canada" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CD", label: "Congo, Democratic Republic of the" },
    { value: "CF", label: "Central African Republic" },
    { value: "CG", label: "Congo" },
    { value: "CH", label: "Switzerland" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "CK", label: "Cook Islands" },
    { value: "CL", label: "Chile" },
    { value: "CM", label: "Cameroon" },
    { value: "CN", label: "China" },
    { value: "CO", label: "Colombia" },
    { value: "CR", label: "Costa Rica" },
    { value: "CU", label: "Cuba" },
    { value: "CV", label: "Cabo Verde" },
    { value: "CW", label: "Curaçao" },
    { value: "CX", label: "Christmas Island" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czechia" },
    { value: "DE", label: "Germany" },
    { value: "DJ", label: "Djibouti" },
    { value: "DK", label: "Denmark" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "DZ", label: "Algeria" },
    { value: "EC", label: "Ecuador" },
    { value: "EE", label: "Estonia" },
    { value: "EG", label: "Egypt" },
    { value: "EH", label: "Western Sahara" },
    { value: "ER", label: "Eritrea" },
    { value: "ES", label: "Spain" },
    { value: "ET", label: "Ethiopia" },
    { value: "FI", label: "Finland" },
    { value: "FJ", label: "Fiji" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FM", label: "Micronesia, Federated States of" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FR", label: "France" },
    { value: "GA", label: "Gabon" },
    {
      value: "GB",
      label: "United Kingdom of Great Britain and Northern Ireland",
    },
    { value: "GD", label: "Grenada" },
    { value: "GE", label: "Georgia" },
    { value: "GF", label: "French Guiana" },
    { value: "GG", label: "Guernsey" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GL", label: "Greenland" },
    { value: "GM", label: "Gambia" },
    { value: "GN", label: "Guinea" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "GR", label: "Greece" },
    { value: "GS", label: "South Georgia and the South Sandwich Islands" },
    { value: "GT", label: "Guatemala" },
    { value: "GU", label: "Guam" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HK", label: "Hong Kong" },
    { value: "HM", label: "Heard Island and McDonald Islands" },
    { value: "HN", label: "Honduras" },
    { value: "HR", label: "Croatia" },
    { value: "HT", label: "Haiti" },
    { value: "HU", label: "Hungary" },
    { value: "ID", label: "Indonesia" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IM", label: "Isle of Man" },
    { value: "IN", label: "India" },
    { value: "IO", label: "British Indian Ocean Territory" },
    { value: "IQ", label: "Iraq" },
    { value: "IR", label: "Iran, Islamic Republic of" },
    { value: "IS", label: "Iceland" },
    { value: "IT", label: "Italy" },
    { value: "JE", label: "Jersey" },
    { value: "JM", label: "Jamaica" },
    { value: "JO", label: "Jordan" },
    { value: "JP", label: "Japan" },
    { value: "KE", label: "Kenya" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "KH", label: "Cambodia" },
    { value: "KI", label: "Kiribati" },
    { value: "KM", label: "Comoros" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "KP", label: "Korea, Democratic People's Republic of" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" },
    { value: "KY", label: "Cayman Islands" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "LA", label: "Lao People's Democratic Republic" },
    { value: "LB", label: "Lebanon" },
    { value: "LC", label: "Saint Lucia" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LK", label: "Sri Lanka" },
    { value: "LR", label: "Liberia" },
    { value: "LS", label: "Lesotho" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "LV", label: "Latvia" },
    { value: "LY", label: "Libya" },
    { value: "MA", label: "Morocco" },
    { value: "MC", label: "Monaco" },
    { value: "MD", label: "Moldova, Republic of" },
    { value: "ME", label: "Montenegro" },
    { value: "MF", label: "Saint Martin, (French part)" },
    { value: "MG", label: "Madagascar" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MK", label: "North Macedonia" },
    { value: "ML", label: "Mali" },
    { value: "MM", label: "Myanmar" },
    { value: "MN", label: "Mongolia" },
    { value: "MO", label: "Macao" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MS", label: "Montserrat" },
    { value: "MT", label: "Malta" },
    { value: "MU", label: "Mauritius" },
    { value: "MV", label: "Maldives" },
    { value: "MW", label: "Malawi" },
    { value: "MX", label: "Mexico" },
    { value: "MY", label: "Malaysia" },
    { value: "MZ", label: "Mozambique" },
    { value: "NA", label: "Namibia" },
    { value: "NC", label: "New Caledonia" },
    { value: "NE", label: "Niger" },
    { value: "NF", label: "Norfolk Island" },
    { value: "NG", label: "Nigeria" },
    { value: "NI", label: "Nicaragua" },
    { value: "NL", label: "Netherlands" },
    { value: "NO", label: "Norway" },
    { value: "NP", label: "Nepal" },
    { value: "NR", label: "Nauru" },
    { value: "NU", label: "Niue" },
    { value: "NZ", label: "New Zealand" },
    { value: "OM", label: "Oman" },
    { value: "PA", label: "Panama" },
    { value: "PE", label: "Peru" },
    { value: "PF", label: "French Polynesia" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PH", label: "Philippines" },
    { value: "PK", label: "Pakistan" },
    { value: "PL", label: "Poland" },
    { value: "PM", label: "Saint Pierre and Miquelon" },
    { value: "PN", label: "Pitcairn" },
    { value: "PR", label: "Puerto Rico" },
    { value: "PS", label: "Palestine, State of" },
    { value: "PT", label: "Portugal" },
    { value: "PW", label: "Palau" },
    { value: "PY", label: "Paraguay" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Réunion" },
    { value: "RO", label: "Romania" },
    { value: "RS", label: "Serbia" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SC", label: "Seychelles" },
    { value: "SD", label: "Sudan" },
    { value: "SE", label: "Sweden" },
    { value: "SG", label: "Singapore" },
    { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "SI", label: "Slovenia" },
    { value: "SJ", label: "Svalbard and Jan Mayen" },
    { value: "SK", label: "Slovakia" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SM", label: "San Marino" },
    { value: "SN", label: "Senegal" },
    { value: "SO", label: "Somalia" },
    { value: "SR", label: "Suriname" },
    { value: "SS", label: "South Sudan" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SV", label: "El Salvador" },
    { value: "SX", label: "Sint Maarten, (Dutch part)" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "SZ", label: "Eswatini" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TD", label: "Chad" },
    { value: "TF", label: "French Southern Territories" },
    { value: "TG", label: "Togo" },
    { value: "TH", label: "Thailand" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TK", label: "Tokelau" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TN", label: "Tunisia" },
    { value: "TO", label: "Tonga" },
    { value: "TR", label: "Turkey" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TV", label: "Tuvalu" },
    { value: "TW", label: "Taiwan, Province of China" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "UA", label: "Ukraine" },
    { value: "UG", label: "Uganda" },
    { value: "UM", label: "United States Minor Outlying Islands" },
    { value: "US", label: "United States of America" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VA", label: "Holy See" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "VE", label: "Venezuela, Bolivarian Republic of" },
    { value: "VG", label: "Virgin Islands, British" },
    { value: "VI", label: "Virgin Islands, U.S." },
    { value: "VN", label: "Viet Nam" },
    { value: "VU", label: "Vanuatu" },
    { value: "WF", label: "Wallis and Futuna" },
    { value: "WS", label: "Samoa" },
    { value: "YE", label: "Yemen" },
    { value: "YT", label: "Mayotte" },
    { value: "ZA", label: "South Africa" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ],

  swlConfig: (msg, action) => {
    let showIcon = "error";
    let showtitle = "Oops...";
    if (action) {
      showIcon = "success";
      showtitle = "Success...";
    }
    return Swal.fire({
      title: showtitle,
      text: msg,
      icon: showIcon,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  },

  swlConfigAlert: (msg) => {
    return Swal.fire({
      title: "Oops...",
      text: msg,
      icon: "warning",
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  },
};
