import React, { useState,useEffect } from 'react'
import ChooseStaking from '../choose-staking/choose-staking';
import BonusPolicy from '../bonus-policy/bonus-policy';
import {
  getStackingPackage,
  beforeStackingPackage,
} from "../choose-staking/choose-staking.actions";
 import './staking-packages.css';
import {connect } from 'react-redux'
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { getSettings, beforeSetting } from "../footer/footer.action";
const StakingPackages = (props) => {
  const [loader, setLoader] = useState(false);
  const [stackingPackage, setStackingPackage] = useState(null);
  const [tokenLimit, setTokenLimit] = useState(0);
  useEffect(() => {
    props.getStackingPackage()
  }, [])
  
  useEffect(() => {
    if (props.package.pkgAuth) {
      const data = props.package.pkg;
      const slicedArray = data.slice(0, 3);
      setStackingPackage(slicedArray);
      props.beforeStackingPackage();
      setLoader(false);

    }
  }, [props.package.pkgAuth]);

  useEffect(() => {
    if (props.footer.linkAuth) {
      const settingData = props.footer.soical_link;
     
      setTokenLimit(settingData.staketoken)
      // setCoinPriceChnage(settingData.changeInPrice);
      props.beforeSetting();
    }
  }, [props.footer.linkAuth]);

  return (

    <>
    {loader ? (
      <FullPageLoader />
    ) : (
    <div className='staking-section'>
    
      <ChooseStaking setLoader={setLoader} tokenLimit={tokenLimit} stackingPackage={stackingPackage}  currentPKG={false} inputfield={false} name="home" heading={true} allPackageButton={true}/>
      <BonusPolicy />
    </div>
    )}
    </>
  )
}

 const mapStateToProps = (state) => ({
  footer: state.footer,
  package: state.package,
  pkg: state.pkg,
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, {
  getStackingPackage,
  beforeStackingPackage,
  getSettings,
  beforeSetting,
})(StakingPackages);

