import { combineReducers } from "redux";
import errorReducer from "./shared/error/error.reducer";
import userReducer from "./../components/signup/user.reducer";
import qrReucer from "./../components/qrCode/qr.reducer";
import packageReducer from "./../components/choose-staking/choose-staking.reducers";
import historyReducer from "./../components/history/history.reducer";
import footerReducer from "./../components/footer/footer.reducers";
import referralReducer from "./../components/referral-dashobard/referral-dashboard.reducers";
import ticketReducer from "../components/tickets-tab/ticket.reducer";
import ticketMsgsReducer from "../components/ticket-msgs/ticketMsgs.reducer";
import withdrawalReducer from "../components/withdrawl/withdrawal.reducer";
import subscribeReducer from "../components/package-subscribe/subscribe.reducer";
import dashboardReducer from "../components/dashboard/dashboard.reducer";
import faqReducers from "../components/faqs/faq.reducers";
import emailSecurityKey from "../components/emailSecurity/emailSecurity.reducers";
export default combineReducers({
  // app: appReducer,
  // faqs: faqReducer,
  // wallet: walletReducer,
  footer: footerReducer,
  package: packageReducer,
  referral: referralReducer,
  user: userReducer,
  ticket: ticketReducer,
  subscribe: subscribeReducer,
  ticketMsg: ticketMsgsReducer,
  withdrawal: withdrawalReducer,
  dashboard: dashboardReducer,
  history: historyReducer,
  faq: faqReducers,
  error: errorReducer,
  qr: qrReucer,
  secretKey:emailSecurityKey,
});
