import { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets';
import { Waypoint } from 'react-waypoint';


import './how-it-works.css';

const HowWorks = () => {

    const [classCheck, setClassCheck] = useState(false)

    return (
        <div className='how-it-works'>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className='hiw-images'>
                            <Waypoint onEnter={() => {setClassCheck(true)}} onLeave={() => {setClassCheck(false)}} />
                            <div className={`${classCheck ? 'hoverTop' : ''} top-img`}>
                                <img className="img-fluid" src={Images.hiwOne} alt="" />
                            </div>
                            <div className="center-img">
                                <img className="img-fluid" src={Images.hiwThree} alt="" />
                            </div>
                            <div className={`${classCheck ? 'hoverBottom' : ''} bottom-img`}>
                                <img className="img-fluid" src={Images.hiwTwo} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='hiw-data'>
                            <div className='hiw-head'>
                                <span className='small-head'>PROCESS</span>
                                <h2>How it Works</h2>
                            </div>
                            <div className='hiw-content'>
                                <div className='inner-content d-flex align-items-center'>
                                    <div className="inner-content-img">
                                        <img className="img-fluid" src={Images.hiwWallet} alt="" />
                                    </div>
                                    <div className="inner-content-data">
                                        <h3>Connect a Wallet</h3>
                                        <p>Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa.</p>
                                    </div>
                                </div>
                                <div className='inner-content d-flex align-items-center'>
                                    <div className="inner-content-img">
                                        <img className="img-fluid" src={Images.hiwPackage} alt="" />
                                    </div>
                                    <div className="inner-content-data">
                                        <h3>Select the Package</h3>
                                        <p>Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa.</p>
                                    </div>
                                </div>
                                <div className='inner-content d-flex align-items-center'>
                                    <div className="inner-content-img">
                                        <img className="img-fluid" src={Images.hiwSaking} alt="" />
                                    </div>
                                    <div className="inner-content-data">
                                        <h3>Request Staking</h3>
                                        <p>Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HowWorks