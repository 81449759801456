import { toast } from "react-toastify";
import {
  GET_ERRORS,
  DELETE_MSGS,
  POST_MSGS,
  GET_MSGS,
  BEFORE_MSGS,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";
import { ENV } from "./../../config/config";
export const beforeTicketChat = () => {
  return {
    type: BEFORE_MSGS,
  };
};
export const postMsgs =
  (body = null, method = "POST") =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}ticketsMsgs/post`;
    fetch(url, {
      method: method,
      headers: {
        Authorization: ENV.Authorization,
        "content-type": "application/json",
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: POST_MSGS,
            payload: data.data,
          });
        } else {
          ENV.swlConfig(data.message,data.success)
          dispatch({
            type: GET_ERRORS,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message)
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const getChat =
  (id, qs = "", toastCheck = true) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}ticketsMsgs/list/${id}`;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (!qs) {
            dispatch({
              type: GET_MSGS,
              payload: data.data,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message)
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
export const deleteMsg = (id) => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.url}ticketsMsgs/delete/${id}`;
  fetch(url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: DELETE_MSGS,
          payload: data.data,
        });
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        }).catch((error) => {
          if (error.response && error.response.data) {
            const { data } = error.response;
            if (data.message) ENV.swlConfigAlert(data.message);
          }
          dispatch({
            type: GET_ERRORS,
            payload: error,
          });
        });
      }
    });
};
