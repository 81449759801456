import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Table } from "react-bootstrap";
import {
  getStackingPackage,
  getSubscribedPackage,
  beforeStackingPackage,
} from "../choose-staking/choose-staking.actions";

import "./packages-tab.css";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import ChooseStaking from "../choose-staking/choose-staking";
import { getSettings, beforeSetting } from "../footer/footer.action";
const PackagesTab = (props) => {
  const [loader, setLoader] = useState(true);
  const [allStackingPackage, setAllStackingPackage] = useState(null);
  const [currentStackingPackage, setCurrentStackingPackage] = useState(null);
  const [tokenLimit, setTokenLimit] = useState(0);
  
  useEffect(() => {
    props.getSettings();
    props.getSubscribedPackage(); ///get API
    props.getStackingPackage();
  }, []);



  useEffect(() => {
    if (props.package.pkgAuth) {
      const data1 = props.package.pkg;
      setAllStackingPackage(data1);
      setLoader(false);
      props.beforeStackingPackage();
    }
  }, [props.package.pkgAuth]);

  useEffect(() => {
    if (props.package.subPkgAuth) {
      const data2 = props.package.subPkg;
      setCurrentStackingPackage(data2);
    }
  }, [props.package.subPkgAuth]);

  useEffect(() => {
    if (props.footer.linkAuth) {
      const settingData = props.footer.soical_link;
   
      setTokenLimit(settingData.staketoken)
      // setCoinPriceChnage(settingData.changeInPrice);
      props.beforeSetting();
    }
  }, [props.footer.linkAuth]);

  useEffect(() => {
    if (allStackingPackage) {

      // console.log("allStackingPackage",allStackingPackage)
    }
  }, [allStackingPackage]);

  useEffect(() => {
    if (currentStackingPackage) {

       //console.log("currentStackingPackage",currentStackingPackage)
    }
  }, [currentStackingPackage]);

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="packages-tab">
          <div className="package-details d-flex">
            <Container>
              <div className="selected-package">
                <h2>Current Package </h2>
                <Row>
                  <ChooseStaking setLoader={setLoader}   stackingPackage={currentStackingPackage} name={"curr"}  currentPKG={true} noSubscribe={true} heading={false} allPackageButton={false}/>
                </Row>
              </div>
              <div className="all-packages">
                <h2>All Packages </h2>
                <ChooseStaking setLoader={setLoader}  tokenLimit={tokenLimit} stackingPackage={allStackingPackage} currentPKG={false} noSubscribe={false} name={"all"} heading={false} allPackageButton={false}/>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  footer: state.footer,
  package: state.package,
  pkg: state.pkg,
  error: state.error,
});

export default connect(mapStateToProps, {
  getStackingPackage,
  beforeStackingPackage,
  getSubscribedPackage,
  getSettings,
  beforeSetting,
})(PackagesTab);
