import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import {
  getReferralData,
  beforeReferral,
} from "../referral-dashobard/referral-dashboard.action";
import { Link } from "react-router-dom";

import "./referral-policy.css";

const ReferralPolicy = (props) => {
  const [referralString, setReferralString] = useState();
  useEffect(() => {
    if (localStorage.getItem("accessToken")) props.getReferralData();
  }, []);
 
  useEffect(() => {
    if (props.referral.refAuth) {
      setReferralString(props.referral.RefLinkString);
      props.beforeReferral();
    }
  }, [props.referral.refAuth]);
  return (
    <div className="referral-policy">
      <Container>
        <Row>
          <Col lg={5} className="mb-lg-0 mb-5">
            <div className="policy-img">
              <img className="img-fluid" alt="" src={Images.referralPolicy} />
            </div>
          </Col>
          <Col lg={7}>
            <div className="referral-data">
              <div className="referral-head">
                <span className="small-head">GET MORE REWARD</span>
                <h2>Referral Referral Policy</h2>
              </div>
              <div className="referral-para">
                <p>
                  Vestibulum commodo sapien non elit porttitor, vitae volutpat
                  nibh mollis. Nulla porta risus id neque tempor, in efficitur
                  justo imperdiet. Etiam a ex at ante tincidunt imperdiet. Nunc
                  congue ex vel nisl viverra, sit amet aliquet lectus
                  ullamcorper.
                </p>
                <p>
                  Praesent luctus lacus non lorem elementum, eu tristique sapien
                  suscipit. Sed bibendum, ipsum nec viverra malesuada, erat nisi
                  sodales purus, eget hendrerit dui ligula eu enim. Ut non est
                  nisi. Pellentesque tristique pretium dolor eu commodo.
                </p>
              </div>
              <form>
                <div className="input-label">
                  <span>Get Your Referral Code</span>
                </div>
                {referralString ? (
                  <div className="input-section">
                    <input type="text" value={ENV.domainURL + referralString} />
                    <div
                      onClick={() => {
                        ENV.copy(ENV.domainURL + referralString);
                        toast.success("copied");
                      }}
                    >
                      <span className="copy-icon">
                        <img className="img-fluid" src={Images.copyIcon} />
                      </span>
                    </div>
                  </div>
                ) : (
                  <Link to="/login" className="input-label red-data">
                    <span>Login to get your referral code. </span>
                  </Link>
                )}
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  referral: state.referral,
  error: state.error,
});

export default connect(mapStateToProps, {
  beforeReferral,
  getReferralData,
})(ReferralPolicy);
