import {GET_STACKING_PACKAGE,GET_SUBSCRIBED_PACKAGE,BEFORE_STACKING_PACKAGE} from '../../redux/types';

const initialState = {
    pkg: null,
    subPkg:null,
    pkgAuth: false,
    subPkgAuth:false,
}

export default function (state = initialState, action) {
    switch (action.type) {
      
        case GET_STACKING_PACKAGE:
            return {
                ...state,
                pkg: action.payload,
                pkgAuth: true,
            };
            case GET_SUBSCRIBED_PACKAGE:
            return {
                ...state,
                subPkg: action.payload,
                subPkgAuth: true,
            };
        case BEFORE_STACKING_PACKAGE:
            return {
                pkg: null,
                Subpkg:null,
                pkgAuth: false,
                subPkgAuth:false,
            };
        default:
            return {
                ...state,
            };
    }
}