import {
  BEFORE_SUBSCRIBE,
  POST_SUBSCRIBE,
  CHECK_SUBSCRIBE,
} from "../../redux/types";

const initialState = {
  subscribe: null,
  subscribes: null,
  subscribeId: null,
  subscribeFlag: false,
  upsertSubscribeAuth: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case BEFORE_SUBSCRIBE: {
     // console.log("Before subscribe red");
      return {
        ...state,
        subscribe: null,
        subscribes: null,
        subscribeId: null,
        subscribeFlag: false,
        upsertSubscribeAuth: false,
      };
    }
    case POST_SUBSCRIBE:
      return {
        ...state,
        subscribe: action.payload.subscribe,
        upsertSubscribeAuth: true,
      };
    case CHECK_SUBSCRIBE:
      return {
        ...state,
        subscribeFlag: true,
      };
    default:
      return {
        ...state,
      };
  }
}
