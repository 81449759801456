import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import {
  BEFORE_WITHDRAWAL,
  GET_WITHDRAWAL,
  POST_WITHDRAWAL,
  GET_ERRORS,
  SET_USER,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";
export const beforeWithdrawal = () => {
  return {
    type: BEFORE_WITHDRAWAL,
  };
};
export const getWithdrawal =
  (qs = "", toastCheck = true) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}withdrawal/list?${qs}`;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.success) {
          if (!qs) {
            // if (toastCheck) {
            //   toas.success(data.message);
            // }
          }
          //console.log("aaaa", data.data.user);

          await dispatch({
            type: GET_WITHDRAWAL,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
export const createWithdrawal = (body) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}withdrawal/create`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)    
        dispatch({
          type: POST_WITHDRAWAL,
          payload: data.data,
        });
      } else {
     
        ENV.swlConfig(data.message,data.success)    
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
