import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { Form } from 'react-bootstrap'

import "./myAccount.css"

function BankAccount(props) {

    return (
        <div className="bank-modal">
            <div className="modal-content">
                <Form >
                    <strong>You can create a contact list for withdrawal addresses hererr.</strong>
                    <label>Label</label>
                    <div className="input-field">
                        <input name="label"  />
                    </div>
                    <label>Coin</label>
                    <div className="input-field">
                        <Select classNamePrefix="select" placeholder="Select Curreny"  />
                    </div>
                    <label>Beneficiary Name</label>
                    <div className="input-field">
                        <input name="beneficiaryName" defaultValue="" />
                    </div>
                    <label>Beneficiary Address</label>
                    <div className="input-field">
                        <input name="beneficiaryAddress" defaultValue="" />
                    </div>
                    <label>Country</label>
                    <div className="input-field">
                        <Select classNamePrefix="select" placeholder="Select Country" value=""/>
                    </div>
                    <label>Beneficiary Bank Name</label>
                    <div className="input-field">
                        <input name="bankName" defaultValue="" />
                    </div>
                    <label>Beneficiary Bank Address</label>
                    <div className="input-field">
                        <input name="bankAddress" defaultValue="" />
                    </div>
                    <label>Beneficiary Bank Account</label>
                    <div className="input-field">
                        <input name="bankAccount" defaultValue="" />
                    </div>
                    <label>BIC/SWIFT</label>
                    <div className="input-field">
                        <input name="bic_swift" defaultValue="" />
                    </div>
                    <label>Comments</label>
                    <div className="input-field">
                        <input name="comment" defaultValue="" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                    <button type="submit" className="purple-border-btn">Save</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default BankAccount