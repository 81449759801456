import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getReferralData,
  beforeReferral,
  getListOfReferredUser,
  setRewardToInitail,
} from "./referral-dashboard.action";

import { toast } from "react-toastify";
import { ENV } from "../../config/config";
import { Container, Table } from "react-bootstrap";
import { Images } from "../../assets/assets";
import Moment from "react-moment";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { claimReferralRewardWeb3 } from "../../utils/web3";
import "./referral-dashboard.css";
import Swal from "sweetalert2";

const ReferralDashboard = (props) => {
 
  const [referralString, setReferralString] = useState();
  const [refReward, setRefReward] = useState(0);
  const [emailOfReferredBy, setEmailOfReferredBy] = useState();
  const [nameOfReferredBy, setNameOfReferredBy] = useState();
  const [arrayOfReferredUser, setArrayOfReferredUser] = useState();
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    props.getReferralData();
    props.getListOfReferredUser(); ///get API
    
    
  }, []);

  useEffect(() => {
    if (props.referral.refAuth && props.referral.refListAuth) {
      setReferralString(props.referral.RefLinkString);
      setRefReward(props.referral.RefReward);
      setEmailOfReferredBy(props.referral.emailOfReferredBy);
      setNameOfReferredBy(props.referral.nameOfReferredBy);
      setArrayOfReferredUser(props.referral.arrayOfReferredUser);
      props.beforeReferral();
      setLoader(false);
    }
  }, [props.referral.refAuth][props.referral.refListAuth]);

  const claimReferralReward = async () => {
    try {
      let data = {
        amount: refReward,
      };
      setLoader(true);
      const reward = await claimReferralRewardWeb3(data);
      
      if (reward) {
        setRefReward(0);
        props.setRewardToInitail();
        setLoader(false);
      } else {
        ENV.swlConfig("Not able To Transfer");
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="referral-dashboard">
          <div className="refferal-details d-flex">
            <Container>
              <div>
                <h2>Referrals</h2>
                <div className="referral-link">
                  <div className="header-top">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div>
                        <p>Referral Bonus Received</p>
                        <div className="d-flex your-referral">
                          <strong>{refReward}</strong>

                          <span>FIXM</span>
                        </div>
                        {refReward > 0 ? (
                          <button
                            className="transparent-btn"
                            onClick={claimReferralReward}
                          >
                            <span>Claim Reward</span>
                          </button>
                        ) : (
                          <span />
                        )}
                      </div>
                      {arrayOfReferredUser ? (
                        <div>
                          <p>Your Referrals</p>
                          <div className="d-flex your-referral">
                            <strong>{arrayOfReferredUser.length}</strong>
                            <span>Users</span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {nameOfReferredBy || emailOfReferredBy ? (
                    <div className="body-content">
                      <div className="user-details">
                        <div className="refer-tag">
                          <strong className="d-blcok text-center">
                            Your referral information
                          </strong>
                        </div>
                        <div className="refer-user">
                          <strong>Referred By</strong>
                          <span className="text-dark d-block fw-bold">
                            {nameOfReferredBy}
                          </span>
                          <span className="text-dark d-block">
                            {emailOfReferredBy}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="bottom-footer">
                    <div>
                      <strong>Your referral link</strong>
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                      <div className="mb-3 mb-md-0">
                        {/* <span >{ENV.domainURL} + {referralString}</span> */}
                        {referralString ? (
                          <a className="text-white link">
                            {ENV.domainURL}
                            {referralString}
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="ms-3">
                        {referralString ? (
                          <div
                            onClick={() => {
                              ENV.copy(ENV.domainURL + referralString);
                              ENV.swlConfig("Copied", true);
                            }}
                          >
                            <img
                              src={Images.Copy}
                              alt=""
                              className="img-fluid cursor-pointer"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2>Referral Histroy</h2>

                <div className="referral-tree-main">
                  <div className="referral-tree">
                    <div className="tabs-body-table">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th className="text-center">Name</th>
                              <th className="text-center">Email</th>
                              <th className="text-center">Date Created</th>
                            </tr>
                          </thead>
                          <tbody>
                            {arrayOfReferredUser &&
                            arrayOfReferredUser.length > 0 ? (
                              <>
                                {arrayOfReferredUser &&
                                  arrayOfReferredUser.map((i) => (
                                    <tr>
                                      <td className="text-center">
                                        {i.firstName}
                                      </td>
                                      <td className="text-center">{i.email}</td>
                                      <td className="text-center">
                                        <Moment format="YYYY/MM/DD">
                                          {i.createdAt}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="3" className="text-center">
                                  <div
                                    className="alert alert-info"
                                    role="alert"
                                  >
                                    No Histrory Found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  referral: state.referral,
  error: state.error,
});

export default connect(mapStateToProps, {
  beforeReferral,
  getReferralData,
  getListOfReferredUser,
  setRewardToInitail,
})(ReferralDashboard);
