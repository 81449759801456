import React, { useState, useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import { Col, Container, Row } from "react-bootstrap";

import "./privacy-policy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <MainBanner />
      <div className="privacy-policy">
        <Container>
          <Row>
            <Col md={12}>
              <div>
                <div class="p-3 pt-5">
                  <p>
                    <b>1. Introduction</b> Myntist Marketplace is an NFT
                    marketplace interface maintained by a decentralized team of
                    developers. It facilitates interaction with the Myntist
                    Protocol, an NFT ecosystem deployed on the BNB Chain.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>2. Modification of this Agreement</b> We reserve the
                    right, in our sole discretion, to modify this Agreement. All
                    modifications become effective when they are posted, and we
                    will notify you by updating the date at the top of the
                    Agreement.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>3. Assumption of Risk</b> By accessing Myntist software,
                    you accept and acknowledge: The prices of blockchain assets
                    are extremely volatile and we cannot guarantee purchasers
                    will not lose money. Assets available to trade on Myntist
                    Marketplace should not be viewed as investments: their
                    prices are determined by the market and fluctuate
                    considerably. You are solely responsible for determining any
                    taxes that apply to your transactions. Marketplace services
                    are non-custodial, such that we do not at any time have
                    custody of the NFTs owned by our users. We do not store,
                    send, or receive Digital Assets, as they respectively exist
                    on the blockchain. As such, and due to the decentralized
                    nature of the services provided, you are fully responsible
                    for protecting your wallets and assets from any and all
                    potential risks. Our software indexes NFTs on the BNB Chain
                    as they are created, and we are not responsible for any
                    assets that users may mistakenly or willingly access or
                    purchase through the software. You accept responsibility for
                    any risks associated with purchasing such user-generated
                    content, including (but not limited to) the risk of
                    purchasing counterfeit assets, mislabeled assets, assets
                    that are vulnerable to metadata decay, assets on faulty
                    smart contracts, and assets that may become untransferable.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>4. Disclaimers</b> We do not represent or warrant that
                    access to the front-end interface will be continuous,
                    uninterrupted, timely, or secure; that the information
                    contained in the interface will be accurate, reliable,
                    complete, or current; or that the Interface will be free
                    from errors, defects, viruses, or other harmful elements.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>5. Proprietary Rights</b> We own the intellectual
                    property generated by core contributors to Myntist
                    Marketplace for the use of Marketplace, including (but not
                    limited to) software, text, designs, images, and copyrights.
                    Unless otherwise stated, Myntist Marketplace reserves
                    exclusive rights to its intellectual property. Refer to our
                    Brand Assets Documentation for guidance on the use of
                    Myntist’s copyrighted materials.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>6. Eligibility</b> To access or use the front-end
                    interface, you represent that you are at least the age of
                    majority in your jurisdiction. You further represent that
                    your access and use of the front-end interface will fully
                    comply with all applicable laws and regulations and that you
                    will not access or use the front-end interface to conduct,
                    promote, or otherwise facilitate any illegal activity.
                    Furthermore, you represent that neither you nor any entity
                    you represent are included in any trade embargoes or
                    sanctions list (“Subject to Restrictions”), nor resident,
                    citizen, national or agent of, or an entity organized,
                    incorporated or doing business in such territories
                    (“Restricted Territories”).
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>7. Privacy</b> When you use the front-end interface, the
                    only information we collect from you is your blockchain
                    wallet address, completed transaction hashes, and token
                    identifiers. We do not collect any personal information from
                    you. We do, however, use third-party services like Google
                    Analytics, which may receive your publicly available
                    personal information. We do not take responsibility for any
                    information you make public on the BNB Chain by taking
                    actions through the front-end interface.
                  </p>
                </div>
                <div class="p-3">
                  <p>
                    <b>8. Prohibited Activity</b> You agree not to engage in any
                    of the following categories of prohibited activity in
                    relation to your access and use of the front-end interface:
                    Intellectual property infringement, such as violations to
                    copyright, trademark, service mark or patent. Interaction
                    with assets, listings, smart contracts, and collections that
                    include metadata that may be deemed harmful or illegal,
                    including (but not limited to): metadata that promotes
                    suicide or self-harm, incites hate or violence against
                    others, degrades or doxxes another individual, depicts
                    minors in sexually suggestive situations, or raises funds
                    for terrorist organizations. Transacting in any Restricted
                    Territory or interacting with any blockchain addresses
                    controlled indirectly or directly by persons or entities
                    Subject to Restrictions, that is, included in any trade
                    embargoes or sanctions list.
                  </p>
                </div>
                <div class="p-3 pb-5">
                  <p>
                    <b>9. Limitation of Liability</b> Myntist Marketplace is in
                    no way liable for any damages of any form resulting from
                    your access or use of Marketplace software, including (but
                    not limited to) any loss of profit, digital assets, or
                    intangible property, and assumes no liability or
                    responsibility for any errors, omissions, mistakes, or
                    inaccuracies in the content provided on Myntist
                    Marketplace-controlled software or media; unauthorized
                    access or use of any server or database controlled by
                    Myntist Marketplace; bugs, viruses etc. in the software;
                    suspension of service; or any conduct of any third party
                    whatsoever. Furthermore, any hyperlink or reference to a
                    third party website, product, or person that is shared or
                    published in any software or other channel by Myntist
                    Marketplace is for your convenience only, and does not
                    constitute an endorsement. We accept no legal responsibility
                    for content or information of such third party sites.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
