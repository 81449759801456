import React, { useState, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Images } from "../../assets/assets";
import { beforeUser, signOut } from "../signup/user.action";
import "./dashboardHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { ENV } from "../../config/config";
import Wallet from "../wallet/wallet";

function DashboardHeader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateUser = useSelector((state) => state.user.updateProfileAuth);

  const [user, setUser] = useState(null);
  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) {
      const user = ENV.getUserKeys();
      setUser(user);
      
    } else navigate("/login");
  }, [updateUser]);

  return (
    <>
      <div className="dashboard-header">
        <Container>
          <div className="d-flex justify-content-end align-items-center">
            {/* <div className="header-button">
                            <Link to="" className='purple-btn'><span>Connect Wallet</span></Link>
                        </div> */}
            {<Wallet name="purple-wallet" nameTwo="white-wallet" />}
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="d-flex align-items-center btn-user"
              >
                <div className="connected-user d-flex align-items-cente">
                  <span className="name">
                    {user?.firstName ? user?.firstName : "unnamed"}
                  </span>
                  {/* <span className="user-image"><img className="img -fluid" src={Images.Profile} alt="" /></span> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href=""
                  onClick={() => {
                    dispatch(signOut());
                    navigate("/");
                  }}
                >
                  LOGOUT
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </div>
    </>
  );
}
export default DashboardHeader;
