import logo from '../assets/images/logo.png';
import logoWhite from '../assets/images/logo-white.png';
import etherium from '../assets/images/etherium.png';
import parker from '../assets/images/parker.png';
import hiwOne from '../assets/images/hiw-1.png';
import hiwTwo from '../assets/images/hiw-2.png';
import hiwThree from '../assets/images/hiw-3.png';
import hiwWallet from '../assets/images/hiw-wallet.svg';
import hiwPackage from '../assets/images/hiw-package.svg';
import hiwSaking from '../assets/images/hiw-staking.svg';
import redImg from '../assets/images/price-tag.svg';
import bonusPolicy from '../assets/images/bonus-policy.png';
import copyIcon from '../assets/images/copy-icon.png';
import WhoWEAre from '../assets/images/who-we-are.png';
import OurMission from '../assets/images/our-mission.png';
import Gift from '../assets/images/gift.png';
import Rocket from '../assets/images/rocket.png';
import Graph from '../assets/images/graph.png';
import PayPerClick from '../assets/images/pay-per-click.png';
import bronzeMain from '../assets/images/bronze-main.png';
import silverMain from '../assets/images/silver-main.png';
import goldMain from '../assets/images/gold-main.png';
import referralPolicy from '../assets/images/referral-policy.png';
import notFound from '../assets/images/not-found.png';
import FaqIcon from "../assets/images/faq-image.svg"; 
import termsConditions from "../assets/images/terms-img_gjj0db.png";
/*sidebar*/
import Dashboard_Icon from "../assets/images/sidebar/Icon-material-dashboard.png";
import Deposit_Icon from "../assets/images/sidebar/sidebar-icon3.png";
import Earn_Icon from "../assets/images/sidebar/icon4.png";
import Management_Icon from "../assets/images/sidebar/icon5.png";
import Exchange_Icon from "../assets/images/sidebar/icon6.png";
import LiveTriage_Icon from "../assets/images/sidebar/icon7.png";
import Withdrawal_Icon from "../assets/images/sidebar/icon8.png";
import MyAccount_Icon from "../assets/images/sidebar/icon9.png";
import KYC_Icon from "../assets/images/sidebar/icon10.png";
import Account_Icon from "../assets/images/sidebar/icon11.png";
import Referral_Icon from "../assets/images/sidebar/icon12.png";
import History_Icon from "../assets/images/sidebar/icon13.png";
import Promo_Icon from "../assets/images/sidebar/icon14.png";
import Logout_Icon from "../assets/images/sidebar/icon15.png";
import Wallet_Icon from "../assets/images/sidebar/wallet-thin-svgrepo-com.png";
/*lower footer*/
import AS_Logo from "../assets/images/as-logo.svg"
import Profile from "../assets/images/profile.png"
/*my-account*/
import bannerImagePlaceholder from "../assets/images/cover-photo.png"
import profileImagePlaceholder from "../assets/images/dp.png"
import Level from "../assets/images/level-logo.svg"
import Book from "../assets/images/book.svg"
/*referral-dashboard*/ 
import UserICon from "../assets/images/user.svg"
import Plus from "../assets/images/Icon feather-plus-circle.svg"
import Minus from "../assets/images/Icon feather-minus-circle.svg"
import Copy from "../assets/images/copy-text.svg"
import UserDp from "../assets/images/dp.png"


export const Images = {
 logo,
 etherium,
 parker,
 hiwOne,
 hiwTwo,
 hiwThree,
 hiwWallet,
 hiwPackage,
 hiwSaking,
 redImg,
 bonusPolicy,
 copyIcon,
 WhoWEAre,
 OurMission,
 Gift,
 Rocket,
 Graph,
 PayPerClick,
 bronzeMain,
 silverMain,
 goldMain,
 referralPolicy,
 notFound,
 FaqIcon,
 termsConditions,
 Dashboard_Icon,
 Deposit_Icon,
 Earn_Icon,
 Management_Icon,
 Exchange_Icon,
 LiveTriage_Icon,
 Withdrawal_Icon,
 MyAccount_Icon,
 KYC_Icon,
 Account_Icon,
 Referral_Icon,
 History_Icon,
 Promo_Icon,
 Logout_Icon,
 Wallet_Icon,
 AS_Logo,
 Profile,
 bannerImagePlaceholder,
 profileImagePlaceholder,
 Level,
 Book,
 logoWhite,
 UserICon,
 Plus,
 Minus,
 Copy,
 UserDp
}