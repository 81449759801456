import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./packages-section.css";
import {
  subscribePackage,
  beforeSubscribe,
  checkButton,
} from "../package-subscribe/subscribe.action";
import { connect } from "react-redux";

const PackagesPrintComponent = ({
  pkg,
  checkButton,
  subscribePackage,
  beforeSubscribe,
}) => {
  const [buttonFlag, setButtonFlag] = useState(false);

  function packageSubscribe(e, Id) {
    e.preventDefault();
    //console.log("in the function");
    let object = { pckgId: Id };
    beforeSubscribe();
    subscribePackage(object);
  }
  return (
    <>
      {/* {" "} */}
      <Col lg={7}>
        <div className="h-100 d-flex align-items-center justify-content-end">
          <div className="package-content">
            <h3>{pkg.title}</h3>
            <span className="blue-head">{pkg.duration} MONTHS PACKAGE</span>
            {/* <span className="blue-head">
              MINIMUM LIMIT : {pkg.minLimit} FIXM
            </span> */}
            <p>
              <div dangerouslySetInnerHTML={{ __html: pkg.description }} />
            </p>
            <div className="three-boxes d-flex align-items-center flex-wrap">
              {pkg.attributes?.map((val) => (
                <div className="inner-boxes d-flex">
                  <span className="tick">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="box-text">{val}</span>
                </div>
              ))}
            </div>
            {buttonFlag ? (
              <button
                className="purple-border-btn"
                onClick={(e) => {
                  packageSubscribe(e, pkg._id);
                }}
              >
                <span>Subscribe</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
  subscribe: state.subscribe,
});
export default connect(mapStateToProps, {
  subscribePackage,
  beforeSubscribe,
  checkButton,
})(PackagesPrintComponent);
