// ERRORS
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

//STACKING PACKAGES
export const GET_STACKING_PACKAGE = "GET_STACKING_PACKAGE";
export const GET_SUBSCRIBED_PACKAGE = "GET_SUBSCRIBED_PACKAGE";
export const BEFORE_STACKING_PACKAGE = "BEFORE_STACKING_PACKAGE";

//Setting LINKS
export const GET_SETINGS_LINKS = "GET_SETINGS_LINKS";
export const BEFORE_SETTING_LINKS = "BEFORE_SETTING_LINKS";

//referral links

export const GET_REFERRAL_DATA = "GET_REFERRAL_DATA";
export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST";

export const BEFORE_REFERRAL = "BEFORE_REFERRAL";

// USERS
export const BEFORE_USER = "BEFORE_USER";
export const SET_USER = "SET_USER";
export const REGISTER_First = "REGISTER_First";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const VERIFY_USER = "VERIFY_USER";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// REQUEST
export const SEND_REQUEST = "SEND_REQUEST";
export const BEFORE_REQUEST = "BEFORE_REQUEST";

// SECURITY
export const ENABLE_SECURITY = "ENABLE_SECURITY";
export const GET_SECURITY = "GET_SECURITY";
export const BEFORE_SECURITY = "BEFORE_SECURITY";

// WALLET
export const BEFORE_WALLET = "BEFORE_WALLET";
export const SET_WALLET = "SET_WALLET";
export const GET_WALLET = "GET_WALLET";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";
export const REDIRECT_TO_WALLET = "REDIRECT_TO_WALLET";

// 2FA
export const BEFORE_QR = "BEFORE_QR";
export const GET_QR_CODE = "GET_QR_CODE";
export const OTP_VERIFIED = "OTP_VERIFIED";

// emailverify
export const BEFORE_KEY = "BEFORE_KEY";
export const KEY_NOT_VERIFIED = "KEY_NOT_VERIFIED";
export const KEY_VERIFIED = "KEY_VERIFIED";
export const BEFORE_EMAIL_SENT = "BEFORE_EMAIL_SENT";
export const EMAIL_SENT = "EMAIL_SENT";

export const NEW_EMAIL_VERIFIED = "NEW_EMAIL_VERIFIED";
export const NEW_EMAIL_NOT_VERIFIED = "NEW_EMAIL_NOT_VERIFIED";
export const RESET_NEW_EMAIL_FLAG = "RESET_NEW_EMAIL_FLAG";


//Ticket
export const BEFORE_TICKET = "BEFORE_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const GET_TICKET = "GET_TICKET";
export const CREATE_TICKET = "CREATE_TICKET";

//Ticket Msgs
export const BEFORE_MSGS = "BEFORE_MSGS";
export const GET_MSGS = "GET_MSGS";
export const POST_MSGS = "POST_MSGS";
export const DELETE_MSGS = "DELETE_MSGS";

//Withdrawal
export const BEFORE_WITHDRAWAL = "BEFORE_WITHDRAWAL";
export const GET_WITHDRAWAL = "GET_WITHDRAWAL";
export const POST_WITHDRAWAL = "POST_WITHDRAWAL";

//Subcribe
export const BEFORE_SUBSCRIBE = "BEFORE_SUBSCRIBE";
export const POST_SUBSCRIBE = "POST_SUBSCRIBE";
export const CHECK_SUBSCRIBE = "CHECK_SUBSCRIBE";
//history
export const BEFORE_HISTORY = "BEFORE_HISTORY";
export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_LIST = "GET_HISTORY_LIST";

//DASHBOARD
export const BEFORE_DASHBOARD = "BEFORE_DASHBOARD";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_EARN_TOKEN = "SET_EARN_TOKEN";
export const RESET_EARN_TOKEN = "RESET_EARN_TOKEN";

//FAQ
export const BEFORE_FAQ = "BEFORE_FAQ";
export const GET_FAQ = "GET_FAQ";
