import { toast } from "react-toastify";
import { ENV } from "../../config/config";
import {
  GET_ERRORS,
  GET_REFERRAL_LIST,
  GET_REFERRAL_DATA,
  BEFORE_REFERRAL,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";

export const beforeReferral = () => {
  return {
    type: BEFORE_REFERRAL,
  };
};

export const getReferralData = () => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.url}referral/get`;
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_REFERRAL_DATA,
          payload: data.data,
        });
      } else {
     ;
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
       // if (data.message) 
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const getListOfReferredUser = () => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.url}referral/listofrefrence`;
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_REFERRAL_LIST,
          payload: data.data,
        });
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const setRewardToInitail = () => (dispatch) => {
 
  dispatch(emptyError());
  let url = `${ENV.url}referral/updatereward`;
  fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)
        
      } else {
        ENV.swlConfig(data.message,data.success)
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};