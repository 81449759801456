import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import lottie from "lottie-web";
import animationOne from "../../assets/lottie-files/referral/lf30_editor_3jrf48ex.json";
import animationTwo from "../../assets/lottie-files/referral/lf30_editor_l61h8nbm.json";
import animationThree from "../../assets/lottie-files/referral/lf30_editor_wguafpyy.json";

import './main-referral.css';

const MainReferral = () => {

    React.useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#copy"),
            animationData: animationOne,
        });
        lottie.loadAnimation({
            container: document.querySelector("#get"),
            animationData: animationTwo,
        });
        lottie.loadAnimation({
            container: document.querySelector("#share"),
            animationData: animationThree,
        });
    }, []);

    return (
        <div className='main-referral'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='referral-head text-center'>
                            <span className='small-head'>REFERRAL</span>
                            <h2>Nullam tristique molestie</h2>
                            <p>Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl, consectetur et luctus et, porta ut dolor. Curabitur ultricies ultrices nulla. Morbi blandit nec est vitae dictum. Etiam vel consectetur diam. Maecenas vitae egestas dolor. Fusce tempor magna at tortor aliquet finibus. Sed eu nunc sit amet elit euismod faucibus. Class aptent taciti sociosqu ad litora torquent.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className='referral-boxes blue-bg'>
                            <div id="copy" className='referral-lottie'></div>
                            <div className='label-text text-center'>
                                <span>Copy Referral Code</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='referral-boxes blue-pink-bg'>
                            <div id="share" className='referral-lottie'></div>
                            <div className='label-text text-center'>
                                <span>Share with Others</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='referral-boxes pink-bg'>
                            <div id="get" className='referral-lottie'></div>
                            <div className='label-text text-center'>
                                <span>Get Reward</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MainReferral