import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import validator from "validator";
import { createTicketReq } from "../tickets-tab/tickets.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import Select from "react-select";
import { getSettings, beforeSetting } from "../footer/footer.action";
import { ENV } from "../../config/config";
import "./get-in-touch.css";
var striptags = require("striptags");

const GetTouch = (props) => {
  const options = ENV.TicketOptions;
  const [selectedOption,setSelectedOption]=useState({ value: 'Select Your issue type', label: 'Select Your issue type'})
  const [address, setAddress] = useState(null);
  const [addresslink, setAddressLink] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [workingHour, setWorkingHour] = useState(null);
  const [facebook, setfacebook] = useState(null);
  const [linkedin, setlinkedin] = useState(null);
  const [twitter, settwitter] = useState(null);
  const [pinterest, setpinterest] = useState(null);
  const [youtube, setyoutube] = useState(null);
  const [errorMsgs, seterrorMsgs] = useState({
    title: "",
    email: "",
    name: "",
    message: "",
  });
  const [ticketData, setticketData] = useState({
    title: "",
    message: "",
    name: "",
    email: "",
  });
  useEffect(() => {
    props.getSettings();
  }, []);

  useEffect(() => {
    if (props.socialdata.linkAuth) {
      const socialdata = props.socialdata.soical_link;
      setAddress(socialdata.address);
      setAddressLink(socialdata.addresslink);
      setPhone(socialdata.contact);
      setEmail(socialdata.email);
      setWorkingHour(socialdata.workingHours);
      setfacebook(socialdata.facebook);
      setlinkedin(socialdata.linkedin);
      settwitter(socialdata.twitter);
      setpinterest(socialdata.pinterest);
      setyoutube(socialdata.youtube);

      props.beforeSetting();
    }
  }, [props.socialdata.linkAuth]);

  function ticketSetter(e) {
    seterrorMsgs({ ...errorMsgs, [e.target.name]: "" });
    setticketData({ ...ticketData, [e.target.name]: e.target.value });
  }
  async function ticketCreate(e) {
    e.preventDefault();
    let tempFlag = true;
    let errordata = {};

    if (validator.isEmpty(ticketData.title)) {
      errordata.title = "title is missing";
      tempFlag = false;
    }
    if (validator.isEmpty(ticketData.name)) {
      errordata.name = "name is missing";
      tempFlag = false;
    }
    if (validator.isEmpty(ticketData.email)) {

      errordata.email = "email is missing";
      tempFlag = false;
    }
    // if (validator.isEmpty(ticketData.type)) {
    //   errordata.type = "type is missing";
    //   tempFlag = false;
    // }
    if (validator.isEmpty(ticketData.message)) {
      errordata.message = "message is missing";
      tempFlag = false;
    }

    if (tempFlag) {
      props.createTicketReq(ticketData);
      //setSelectedOption({ value: 'Select Your issue type', label: 'Select Your issue type'})
      setticketData({ title: '',email: '',message:'',name:'' });


    } else {
      seterrorMsgs(errordata);
    }
  }

  return (
    <div className="get-touch">
      <Container>
        <Row>
          <Col md={6}>
            <div className="contact-data">
              <div className="contact-head">
                <span className="small-head">GET IN TOUCH</span>
                <h2>Nunc maximus nibh at placerat</h2>
              </div>
              <div className="contact-para">
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
              </div>
              <form onSubmit={ticketCreate}>
                <div className="input-section">
                  <input
                    name="title"
                    type="text"
                    placeholder="Enter Your title"
                    value={ticketData.title}
                    onChange={ticketSetter}
                  />
                  {errorMsgs.title ? (
                    <span style={{ color: "red" }}>title is required</span>
                  ) : (
                    <span />
                  )}
                </div>
                <div className="input-section">
                  <input
                    name="name"
                    type="string"
                    placeholder="Enter Your name"
                    value={ticketData.name}
                    onChange={ticketSetter}
                  />
                  {errorMsgs.name ? (
                    <span style={{ color: "red" }}>name is required</span>
                  ) : (
                    <span />
                  )}
                </div>
                <div className="input-section">
                  <input
                    name="email"
                    type="email"
                    placeholder="Enter Your email"
                    value={ticketData.email}
                    onChange={ticketSetter}
                  />
                  {errorMsgs.email ? (
                    <span style={{ color: "red" }}>email is required</span>
                  ) : (
                    <span />
                  )}
                </div>
                {/* <div className="input-section">
                  <Select
                    value={selectedOption}
                    placeholder="Select Your issue type"
                    options={options}
                    classNamePrefix="select"
                    onChange={(e) => {
                      setSelectedOption(e)
                      setticketData({ ...ticketData, type: e.value });
                      seterrorMsgs({ ...errorMsgs, type: "" });
                    }}
                  />
                  {errorMsgs.type ? (
                    <span style={{ color: "red" }}>type is required</span>
                  ) : (
                    <span />
                  )}
                </div> */}

                <div className="input-section">
                  <textarea
                    name="message"
                    type="text"
                    placeholder="Enter Your message"
                    value={ticketData.message}
                    onChange={ticketSetter}
                  />
                  {errorMsgs.message ? (
                    <span style={{ color: "red" }}>message is required</span>
                  ) : (
                    <span />
                  )}
                </div>

                <div className="contact-button text-end">
                  <button type="submit" className="purple-border-btn">
                    <span> Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </Col>
          <Col md={6}>
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="contact-box d-flex  flex-wrap">
                <div className="contact-data">
                  {address || addresslink ? (
                    <>
                      <h2>Address</h2>

                      {address ? <p>{address}</p> : <></>}
                      {addresslink ? (
                        <a href={addresslink} target="_blank">
                          Get Directions
                        </a>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="contact-data working-hours">
                  {workingHour ? (
                    <>
                      <h2>Working Hours</h2>
                      <ul className=" ">
                        <div
                          dangerouslySetInnerHTML={{ __html: workingHour }}
                        />
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="contact-data">
                  {phone || email ? (
                    <>
                      <h2>Contact us</h2>
                      <ul className="contact-list">
                        {phone ? (
                          <li>
                            <a className="conatct-no" href={"tel:" + phone}>
                              {phone}
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                        {email ? (
                          <li>
                            <a href={"mailto:" + email}>{email}</a>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="contact-data">
                  {facebook || linkedin || pinterest || youtube || twitter ? (
                    <>
                      <h2>Follow Us</h2>
                      <ul className="social-list d-flex align-items-center flex-wrap">
                        {facebook ? (
                          <li>
                            <a
                              href={facebook}
                              target="_blank"
                              className="facebook"
                            >
                              <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                        {linkedin ? (
                          <li>
                            <a
                              href={linkedin}
                              target="_blank"
                              className="linkedin"
                            >
                              <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                        {twitter ? (
                          <li>
                            <a
                              href={twitter}
                              target="_blank"
                              className="twitter"
                            >
                              <FontAwesomeIcon icon={faTwitter} />
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                        {pinterest ? (
                          <li>
                            <a
                              href={pinterest}
                              target="_blank"
                              className="pininterest"
                            >
                              <FontAwesomeIcon icon={faPinterestP} />
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                        {youtube ? (
                          <li>
                            <a
                              href={youtube}
                              target="_blank"
                              className="youtube"
                            >
                              <FontAwesomeIcon icon={faYoutube} />
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  socialdata: state.footer,
  error: state.error,
});

export default connect(mapStateToProps, {
  getSettings,
  beforeSetting,
  createTicketReq,
})(GetTouch);
