import { BEFORE_FAQ, GET_FAQ } from "../../redux/types";
const initialState = {
  faqs: null,
  getFaqAuth: false,
  pagination: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        faqs: action.payload.faqs,
        // pagination: action.payload.pagination,
        getFaqAuth: true,
      };
    case BEFORE_FAQ:
      return {
        faqs: null,
        getFaqAuth: false,
        pagination: null,
      };
    default:
      return {
        ...state,
      };
  }
}
