import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import lottie from "lottie-web";
import loginLottie from "../../assets/lottie-files/login/78126-secure-login.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login, beforeUser } from "../signup/user.action";
import CheckSecurity from "../qrCode/checkSecurity";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import PasswordInput from "../signup/passwordInput";
import "./login.css";
import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import Swal from "sweetalert2";
import {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  beforeSentEmail,
} from "../emailSecurity/emailSecurity.action";

const Login = (props) => {
  const [waitingFlag, setWaitingFlag] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [renderSecurity, setRednerSecuirty] = useState(false);
  const [password, setPassword] = useState("");
  const userAccessToken = ENV.getUserKeys("accessToken");
  const [loader, setLoader] = useState(false);
  useLayoutEffect(() => {
    if (localStorage.getItem("accessToken")) window.location.href = "/";
  }, []);

  const navigate = useNavigate();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#login"),
      animationData: loginLottie,
    });
  });
  useEffect(() => {
    if (props.user?.loginUserAuth) {
      if (props.user.user.status) {
        toast("account deleted");
      } else {
       // setRednerSecuirty(true);
        props.sentSecurityemail();
        //navigate("/dashboard");
      }
      setLoader(false);
      props.beforeUser();
    }
  }, [props.user?.loginUserAuth]);



  useEffect(() => {
   // console.log(" ************ In usereffect flags are *********************",props.secretKey.keyVerfiedAuth,waitingFlag,props.secretKey.keyVerfied)
    if(props.secretKey.keyVerfiedAuth && waitingFlag)
    {
      setVerified(true)
    }
    props.beforeSecretKey()
    setWaitingFlag(false);
  }, [props.secretKey.keyVerfied]);


  useEffect(() => {
    if (props.secretKey.emailSentAuth) {
      
      props.beforeSentEmail()
      Swal.fire({
        text: "Email Sent Succesfully!! Enter 8 digit Secret Code",
        input: "text",
      }).then((result) => {
        if (result.value) {
          const data = {};
          data.secretkey = result.value;
          data.type = "userSettingSecretkey";
          props.verifySecretKey(data);
          setWaitingFlag(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your Action Is unsaved Try Later",
          });
        }
      });
    }
  }, [props.secretKey.emailSentAuth]);

  const setVerified = (value) => {

    if (value) {
      localStorage.setItem("accessToken", userAccessToken.accessToken);
      navigate("/dashboard");
    }
    setRednerSecuirty(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(40, "Password must not exceed 40 characters"),
    }),
    onSubmit: (values) => {
      setLoader(true);
      // alert(JSON.stringify(values, null, 2));
      props.login(values, setLoader);
      // setLoader(false);
    },
  });
  function ChangeData(name, value) {}
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="login-section">
          <div className="login-header">
            <Container>
              <Row>
                <Col md={12}>
                  <strong className="logo">
                    <Link to="/">
                      <img
                        src={Images.logo}
                        className="img-fluid"
                        alt="StalkingStream Logo"
                      />
                    </Link>
                  </strong>
                </Col>
              </Row>
            </Container>
          </div>
          {/* {renderSecurity && (
            <CheckSecurity
              securityType={"login"}
              setVerified={setVerified}
              redirctPath={"/dashboard"}
            />
          )} */}
          <div className="main-section">
            <Container className="h-100">
              <Row className="h-100">
                <Col lg={6} className="d-none d-lg-block">
                  <div className="login-lottie d-flex align-items-center justify-content-center h-100">
                    <div className="login-lottie-in" id="login"></div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="login-data d-flex align-items-center h-100">
                    <div className="login-data-in">
                      <span className="heading">Login</span>
                      <div className="login-form">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="input-section">
                            <label for="email" className="data-label">
                              Email
                            </label>
                            <input
                              id="email"
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter Your Email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            <p className="red">
                              {formik.errors.email && formik.touched.email
                                ? formik.errors.email
                                : null}
                            </p>
                          </div>
                          <div className="input-section last-section">
                            {/* <label for="password" className="data-label">
                          Password
                        </label> */}
                            <div className="relative-input">
                              <PasswordInput
                                label="Password"
                                name="password"
                                changeData={(name, value) => {
                                  let target = { name, value };
                                  let event = { target };
                                  formik.handleChange(event);
                                }}
                                errorMsg={
                                  formik.errors.password &&
                                  formik.touched.password
                                    ? formik.errors.password
                                    : null
                                }
                                forOne="password"
                                id="password"
                              />
                             
                            </div>
                          </div>
                          <div className="forget-button text-end">
                            <Link to="/forget-password" className="forget-pass">
                              Forgot Password?
                            </Link>
                          </div>
                          <div className="login-button text-center">
                            <button type="submit" className="purple-border-btn">
                              <span>Login</span>
                            </button>
                          </div>
                          <div className="signup-button-in d-flex justify-content-center align-items-center">
                            <span className="signup-text">
                              Don't have an Account?{" "}
                            </span>
                            <Link className="signup-button ms-2" to="/signup">
                              Sign Up
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  secretKey: state.secretKey,
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, { 
  sentSecurityemail,
  beforeSentEmail,
  verifySecretKey,
  beforeSecretKey,
  login, 
  beforeUser
 })(Login);
