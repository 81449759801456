import { GET_DASHBOARD, BEFORE_DASHBOARD, GET_ERRORS,SET_EARN_TOKEN,RESET_EARN_TOKEN } from "../../redux/types";
import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";
export const beforeDashboard = () => {
  return {
    type: BEFORE_DASHBOARD,
  };
};
export const getDashboard =
  (qs = "", toastCheck = true) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}dashboard/list`;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_DASHBOARD,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message)  ENV.swlConfigAlert(data.message)
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

  export const getEranReward = (body) => (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}withdrawal/getEranReward`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
    })
    .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: SET_EARN_TOKEN,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message)  ENV.swlConfigAlert(data.message)
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
     
  };
  export const resetEarnToken = () => {
    return {
      type: RESET_EARN_TOKEN,
    };
  };
  
