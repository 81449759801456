import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { beforeWallet, setWalletAddress } from "../wallet/wallet.action";

import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import lottie from "lottie-web";
import whiteWallet from "../../assets/lottie-files/lf30_editor_g5z4wg61.json";
import purpleWallet from "../../assets/lottie-files/lf30_editor_lon2uecv.json";
import { beforeUser, login, signup } from "../signup/user.action";
import connectors from "./connector";
import { ENV } from "./../../config/config";
import ReactTooltip from 'react-tooltip';

const { requiredChainName, chainsConfigs } = ENV;
const CHAIN_NAME = requiredChainName;

const Wallet = (props) => {
 // console.log("props................", props);
  const { account, activate, deactivate, library } = useWeb3React();
  const [connectedAddress, setConnectedAddress] = useState("");
  const [signUpCheck, setSignUpCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [isActiva, setIsActivate] = useState(false)
  const navigate = useNavigate();

  const [curAccount, setcurAccount] = useState("");
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector(`.${props.nameTwo}`),
      animationData: whiteWallet,
    });
    lottie.loadAnimation({
      container: document.querySelector(`.${props.name}`),
      animationData: purpleWallet,
    });
  }, []);

useEffect(() => {
  if(localStorage.getItem("chainId") && account)
  {
  localStorage.setItem("isSet", account)
  localStorage.setItem("connectedAddress", account);
  //console.log("my addreess................", account);
  setcurAccount(account);
}
}, [account])

  useEffect(() => {
    if (props.error) {
      setLoader(false);
    }
  }, [props.error]);

  //Check showRegister or NOt
  useEffect(() => {
    if (props.user.registerFirst) {
      setShow(true);
      props.beforeUser();
    }
  }, [props.user.registerFirst]);
  useEffect(() => {
    if (localStorage.getItem("encuse")) {
      // navigate(`${signUpCheck ? '/profile' : '/explore-all'}`)
    }
  }, []);

  useEffect(() => {
    //console.log(!localStorage.getItem("isSet"),localStorage.getItem("accessToken"), isActiva)
    if (
      !localStorage.getItem("isSet") &&
      localStorage.getItem("accessToken") && isActiva
    ) {
      if (account) {
        setIsActivate(false)
        localStorage.setItem("isSet", account)
        localStorage.setItem("connectedAddress", account);
       // console.log("my addreess................", account);
        props.beforeUser();
        
      }
    }
    // if (account && !localStorage.getItem("encuse")) {
    //   login(account);
    // }

    // console.log("Acccount  ********************************** value", account)
    // setConnectedAddress(account);
    // setWalletAddress(account);
  }, [account, isActiva][curAccount]);

  useEffect(() => {
    if (library) {
      window.library = library;
    }
  }, [library]);

  // useEffect(() => {
  //     if (props.error && props.error.notUnique) {
  //         setErrorMessage(props.error.message);
  //     }
  // }, [props.error]);

  const walletActivation = (walletInjector, name, _chainId) => {
    //console.log("connect click")
    const connector = walletInjector.provider;
    if (walletInjector.name === "MetaMask" && !window.ethereum) {
      alert("Please install Metamask first!");
      return false;
    }
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true)
        .then(async (res) => {
          const web3Provider = await connector.getProvider();
          web3Provider.enable();
          const web3 = new Web3(web3Provider);
          window.walletPO = web3;
         // console.log("i am activator")
          if (localStorage.getItem("accessToken")) {
            //update into db
            // props.updateAddress({ address: connectedAddress });
            // props.beforeUser();
            // console.log("before setting localstorage",connectedAddress)
            // localStorage.setItem("connectedAddress", connectedAddress);
            localStorage.setItem("chainId", _chainId);
            localStorage.setItem("provider", name);
            setIsActivate(true);
            const networkDetails = {
              chainId: `0x${Number(_chainId).toString(16)}`,
              chainName: CHAIN_NAME[_chainId],
              nativeCurrency: chainsConfigs[_chainId].nativeCurrency,
              rpcUrls: [chainsConfigs[_chainId].rpcUrl],
              blockExplorerUrls: [chainsConfigs[_chainId].explorer],
            };
          }

          // await web3.currentProvider.request({
          //     method: 'wallet_switchEthereumChain',
          //     params: [{
          //         chainId: web3.utils.toHex(_chainId)
          //     }]
          // })

          if (window?.ethereum?.networkVersion !== _chainId) {
            await window?.ethereum?.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: web3.utils.toHex(_chainId),
                },
              ],
            });
          }
          // await window?.ethereum?.request({
          //     method: 'wallet_addEthereumChain',
          //     params: [networkDetails],
          // });
        })
        .catch(async (error) => {
          console.log("error", error);
          console.log(
            "error instanceof UnsupportedChainIdErrorerror instanceof UnsupportedChainIdError"
          );
          console.log(error instanceof UnsupportedChainIdError);
          if (error instanceof UnsupportedChainIdError || error.code === 4902) {
            const networkDetails = {
              chainId: `0x${Number(_chainId).toString(16)}`,
              chainName: CHAIN_NAME[_chainId],
              nativeCurrency: chainsConfigs[_chainId].nativeCurrency,
              rpcUrls: [chainsConfigs[_chainId].rpcUrl],
              blockExplorerUrls: [chainsConfigs[_chainId].explorer],
            };

            await window?.ethereum?.request({
              method: "wallet_addEthereumChain",
              params: [networkDetails],
            });
            await window?.ethereum?.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: `0x${Number(_chainId).toString(16)}`,
                },
              ],
            });
            activate(connector);
          } else {
            // setPendingError(error?.message || 'Something went wrong');
          }

          // let chains = '', chainsLength = Object.keys(ENV.requiredChainName)?.length
          // Object.entries(ENV.requiredChainName).forEach(([key, value], index) => {
          //     if (index) {
          //         if (index === chainsLength - 1)
          //             chains += ' or '
          //         else if (index + 1 < chainsLength)
          //             chains += ', '
          //     }

          //     chains += value
          // })

          // toas.error(`Please switch to ${chains} in order to use all features of Marketplace`)
          // activate(connector);
        });
  };

  const disconnect = () => {
    deactivate();
  };

  const formatAddress = (address) => {
    return address
      ? address.substr(0, 7) + "..." + address.substr(-4)
      : "Connect wallet";
  };

  if (props.user.userAuth) {
    return navigate(`${signUpCheck ? "/dashboard" : "/"}`);
  }
  return (
    <>
      {/* {
            loader && <FullPageLoader />
        } */}
      {
        <button
          className="purple-btn"
          onClick={() => {
            if (!localStorage.getItem("accessToken")) navigate("/login");
            // walletActivation(connectors.injected, "injected", 5);
            if (window.ethereum.providers !== undefined) {
              window.ethereum.setSelectedProvider(
                window.ethereum.providers.find(({ isMetaMask }) => isMetaMask)
              );
              localStorage.setItem("connectedWalletName", "MetaMask");
            }
            walletActivation(connectors.injected, "injected", 5);

            if (localStorage.getItem("connectedAddress")) {
              navigate("/dashboard");
            }
          }}
          data-tip={formatAddress(localStorage.getItem("connectedAddress"))}
        >
          <span className={`purple-wallet ${props.name}`}></span>
          <span className={`white-wallet ${props.nameTwo}`}></span>
          <span className="btn-text">
            {formatAddress(localStorage.getItem("connectedAddress"))}
          </span>
          <ReactTooltip backgroundColor="#401c86" className="wallet-datatip" />
        </button>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  user: state.user,
  home: state.home,
  error: state.error,
});

export default connect(mapStateToProps, {
  beforeWallet,
  setWalletAddress,
  login,
  signup,
  beforeUser,
})(Wallet);
