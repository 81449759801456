import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink,useNavigate } from 'react-router-dom'
import { Images } from "../../assets/assets";
import { beforeUser, signOut } from '../signup/user.action'
import  {useDispatch} from 'react-redux'
import { useWeb3React } from "@web3-react/core";

import './sidebar.css';

function Sidebar(props) {

    const { pathname } = useLocation();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const { deactivate } = useWeb3React();

    const sideRoutes = [
        { path: '/dashboard', name: "Dashboard", image: Images.Dashboard_Icon },
        { path: '/dashboard/packages', name: "Packages", image: Images.Management_Icon },
        { path: '/dashboard/my-account', name: "User Settings", image: Images.MyAccount_Icon },
        { path: '/dashboard/history', name: "Packages History", image: Images.History_Icon },
        { path: '/dashboard/withdrawal', name: "Withdrawal", image: Images.Withdrawal_Icon },
        { path: '/dashboard/tickets', name: "Tickets", image: Images.Promo_Icon },
        { path: '/dashboard/referrals', name: "Referrals", image: Images.Referral_Icon }
    ]

    return (
        <>
            <div
                className={`sidebar ${props.isActive ? "show" : " "}`}
            >
                <div className="logo d-flex align-items-center">
                    <Link to={'/'} ><img className="img-fluid" src={Images.logoWhite} alt="" /></Link>
                </div>
                <ul className="list-unstyled">
                    {
                        sideRoutes.map((route) => <li className="d-flex align-items-center">
                           
                            <NavLink
                                className={
                                    ({ isActive }) =>
                                        isActive ? `text-decoration-none d-flex flex-fill ${route.path === pathname && 'active'}` : undefined
                                }
                            //  activeClassName={route.path === pathname ? "active" : undefined} 
                             activeKey={pathname} to={route.path} 
                            //  className={`text-decoration-none d-flex flex-fill`}
                            >
                                <div className="menu-white-icons">
                                    <img className="img-fluid" src={route.image} alt="" />
                                </div>
                                <span>{route.name}</span>
                            </NavLink>
                        </li>
                        )}

                    <li onClick={() => {  
                                deactivate();
                                dispatch(signOut());
                                navigate('/login') 
                                }} className="d-flex align-items-center">
                        <span className="text-decoration-none d-flex flex-fill logout-span" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.Logout_Icon} alt="" />
                            </div>
                            <span>Logout</span>
                        </span>
                    </li>
                </ul>

            </div>
        </>

    )
}


{/* <li className="d-flex">
                        <NavLink to={'/dashboard/packages'} activeClassName="active" className="text-decoration-none d-flex flex-fill"  >
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.Management_Icon} alt="" />
                            </div>
                            <span>Packages</span>
                        </NavLink>
                    </li>
                    <li className="d-flex align-items-center">
                        <NavLink to="/dashboard/my-account" className="text-decoration-none d-flex flex-fill" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.MyAccount_Icon} alt="" />
                            </div>
                            <span>User Settings</span>
                        </NavLink>
                    </li>
                    <li className="d-flex align-items-center">
                        <NavLink to="/dashboard/history" className="text-decoration-none d-flex flex-fill" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.History_Icon} alt="" />
                            </div>
                            <span>History</span>
                        </NavLink>
                    </li>
                    <li className="d-flex align-items-center">
                        <NavLink to="/dashboard/withdrawal" className="text-decoration-none d-flex flex-fill" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.Withdrawal_Icon} alt="" />
                            </div>
                            <span>Withdrawal</span>
                        </NavLink>
                    </li>
                    <li className="d-flex align-items-center">
                        <NavLink to="/dashboard/tickets" className="text-decoration-none d-flex flex-fill" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.Promo_Icon} alt="" />
                            </div>
                            <span>Tickets</span>
                        </NavLink>
                    </li>
                    <li className="d-flex align-items-center">
                        <NavLink to="/dashboard/referrals" className="text-decoration-none d-flex flex-fill" activeClassName="active">
                            <div className="menu-white-icons">
                                <img className="img-fluid" src={Images.Referral_Icon} alt="" />
                            </div>
                            <span>Referrals</span>
                        </NavLink>
                    </li> */}


export default Sidebar;