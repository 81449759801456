import React, { useState, useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import GetTouch from "../get-in-touch/get-in-touch";

const ContactUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <MainBanner />
      <GetTouch />
    </React.Fragment>
  );
};

export default ContactUs;
