import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import { BEFORE_FAQ, GET_FAQ, GET_ERRORS } from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";
export const beforeFaq = () => {
  return {
    type: BEFORE_FAQ,
  };
};
export const getFaq =
  (toastCheck = true) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}faq/list`;
    let headerstemp = {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    };
    if (localStorage.getItem("accessToken")) {
      headerstemp = {
        "Content-Type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      };
    }
    fetch(url, {
      method: "GET",
      headers: headerstemp,
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log("data...............", data);
        if (data.success) {
          // if (toastCheck) {
          // }
          dispatch({
            type: GET_FAQ,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
