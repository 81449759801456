import React, {
  useId,
  useState,
  useEffect,
  useCallback,
  useLocation,
} from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../assets/assets";
import lottie from "lottie-web";
import bronzeAnimation from "../../assets/lottie-files/92788-bronze-coin.json";
import silverAnimation from "../../assets/lottie-files/lf30_editor_sypelaoz.json";
import goldAnimation from "../../assets/lottie-files/18089-gold-coin.json";
import {
  getStackingPackage,
  beforeStackingPackage,
} from "./choose-staking.actions";
import "./choose-staking.css";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import Subscribe from "./subscribe";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ENV } from "../../config/config";

const ChooseStaking = (props) => {
  const integerNumberValidator = ENV.integerNumberValidator;
  const [loader, setLoader] = useState(true);
  const [stackingPackage, setStackingPackage] = useState(null);
  const [stackingPackage1, setStackingPackage1] = useState(null);
  const [tokenLimit, setTokenLimit] = useState(0);
  const uId = useId();

  useEffect(() => {
  
    if (props.stackingPackage) {
      setStackingPackage(props.stackingPackage);
      setTokenLimit(props.tokenLimit);
    }
    setLoader(false);
  }, [props.stackingPackage]);

  const ref = useCallback((node) => {
    const animationByName = {
      bronze: bronzeAnimation,
      gold: goldAnimation,
      silver: silverAnimation,
    };
    stackingPackage.map((item, i) => {
      lottie.loadAnimation({
        container: document.querySelector(`.${item.type + props.name + i}`),
        loop: true,
        autoplay: true,
        animationData: animationByName[item.type],
      });
    });
  });

  const handleClick = () => {
    let arr = stackingPackage?.map((item, index) => {
      return { ...item, TokenAmount: "0" };
    });
    setStackingPackage([...arr]);
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="choose-staking">
        
          <Container>
            <Row>
              {props.heading ? (
                <Col md={12}>
                  <div className="staking-head text-center">
                    <span className="small-head">CHOOSE</span>
                    <h2>Staking Packages</h2>
                    <p>
                      Cras eu elit congue, placerat dui ut, tincidunt nisl.
                      Nulla leo elit, pharetra bibendum justo quis, cursus
                      consectetur erat. Sed nec posuere turpis. Maecenas nec
                      bibendum purus. Nulla fringilla, lorem iaculis iaculis
                      fermentum, ligula nibh mollis ipsum
                    </p>
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col md={12}>
                {stackingPackage && stackingPackage?.length > 0 && (
                  <Row ref={ref}>
                    
                    {stackingPackage?.map((pckg, index) => (
                      <SkeletonTheme
                        baseColor="#f5f5f5"
                        highlightColor="#ebebeb"
                      >
                        <Col xxl={4} lg={6}>
                          <div className="packages-data" key={pckg.id}>
                            <div
                              className={`lottie-cover ${
                                pckg.type + props.name + index
                              }`}
                            ></div>
                            <div className="red-img">
                              <img
                                className="img-fluid"
                                src={Images.redImg}
                                alt=""
                              />
                              <span className="percentage">
                                {pckg.profit + "%" || <Skeleton />}
                              </span>
                              <span className="reward-tag">Reward</span>
                            </div>
                            <h2 className={pckg.type}>
                              {pckg.title || <Skeleton />}
                            </h2>
                            <span className="duration">
                              <span className="min-limit">
                                {pckg.duration || <Skeleton />}
                              </span>
                              <span className="months"> MONTHS PACKAGE</span>
                            </span>
                            <p>
                              {pckg.description ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: pckg.description,
                                  }}
                                />
                              ) : (
                                <Skeleton count={3} />
                              )}
                            </p>
                            {!props.noSubscribe == true ? (
                              <span className="duration">
                                <Skeleton count={1} />
                                <div className="d-flex w-100 justify-content-center align-items-center">
                                  <span className="currency-1">MIN LIMIT:</span>
                                  <span className="amount-1">
                                    <span className="limit">{tokenLimit}</span>{" "}
                                    <span className="fixm">FIXM</span>
                                  </span>
                                </div>
                              </span>
                            ) : (
                              <span className="duration">
                                <Skeleton count={1} />
                                <div className="d-flex w-100 justify-content-center align-items-center">
                                  <span className="currency">
                                    STAKED TOKENS:
                                  </span>
                                  <span className="amount">
                                    <span className="limit">
                                      {pckg.stakeToken}
                                    </span>{" "}
                                    <span className="fixm">FIXM</span>
                                  </span>
                                </div>
                              </span>
                            )}
                            {localStorage.getItem("accessToken") &&
                            !props.currentPKG == true ? (
                              <div>
                                <label for="tokeninput">
                                  ENTER TOKENS TO STAKE
                                  <span className="red">*</span>
                                </label>
                                
                                <div className="py-2">
                                  <input
                                    id="tokeninput"
                                    type="number"
                                    className="token-stake-input w-100"
                                    placeholder="Enter Your Amount"
                                    Value={stackingPackage[index]?.TokenAmount}
                                    onKeyDown={(e) => integerNumberValidator(e)}
                                    onChange={(e) => {
                                      stackingPackage[index].TokenAmount =
                                        e.target.value;
                                      setStackingPackage(stackingPackage);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="expiry-date">
                                {localStorage.getItem("accessToken") ? (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="">
                                      EXPIRY DATE:
                                    </span>
                                    <span className="">
                                      <span className="ms-2">
                                        {moment(pckg.createdAt)
                                          .add(pckg.duration, "M")
                                          .format("DD/MM/YYYY")}
                                      </span>{" "}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}

                            <div className="card-button">
                              {!props.noSubscribe ? (
                                <Subscribe
                                  handleClick={handleClick}
                                  pckg={pckg}
                                  setLoader={props.setLoader}
                                  name={props.name}
                                  tokenLimit={tokenLimit}
                                />
                              ) : (
                                <button className="purple-border-btn" disabled>
                                  <span>Subscribed</span>
                                </button>
                              )}
                            </div>
                            <Link to="/packages">More Details</Link>
                          </div>
                        </Col>
                      </SkeletonTheme>
                    ))}
                  </Row>
                )}
              </Col>
              {props.allPackageButton ? (
                <Col md={12}>
                  <div className="all-packages text-center">
                    <Link to="/packages" className="transparent-btn">
                      <span>View All Packages</span>
                    </Link>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  package: state.package,
  pkg: state.pkg,
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, {
  // subscribePackage,
  // beforeSubscribe,
  getStackingPackage,
  beforeStackingPackage,
})(ChooseStaking);
