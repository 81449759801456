import React, { useEffect, useState } from "react";
import { ENV } from "./../../config/config";
import { useNavigate } from 'react-router-dom';
import {
  subscribePackage,
  beforeSubscribe,
  forwardReferralBonus,
  getupdateduser,
} from "../package-subscribe/subscribe.action";
import moment from 'moment';
import Moment from "react-moment";
import {
  getSubscribedPackage,
} from "../choose-staking/choose-staking.actions";
import "./choose-staking.css";
import Swal from 'sweetalert2';
import { stakeWeb3, unStakeWeb3 } from "../../utils/web3";
import { getSettings, beforeSetting } from "../footer/footer.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { checkButton } from "../package-subscribe/subscribe.action";
import { toast } from "react-toastify";
const Subscribe = (props) => {
  const navigate = useNavigate();
  let  { uId }= ENV.getUserKeys("uId");
  const accessToken = localStorage.getItem("accessToken");
  //console.log("accessToken.................", accessToken);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [buttonFlag, setButtonFlag] = useState(false);

  const upsertSubscribeAuth = useSelector(
    (state) => state.subscribe.upsertSubscribeAuth
  );
  const error = useSelector((state) => state.error?.error);

  useEffect(() => {
    if(!uId && accessToken){
   
      dispatch(getupdateduser())
      const  { uId1 }= ENV.getUserKeys("uId");
       uId=uId1
    }
  }, []);
  useEffect(() => {
    if (accessToken == null) {
      setButtonFlag(true);
      // props.checkButton();
    }
  }, [upsertSubscribeAuth]);

  useEffect(() => {
    if (upsertSubscribeAuth) {
      setLoader(false);
      dispatch(beforeSubscribe);
    }
  }, [upsertSubscribeAuth]);

  useEffect(() => {
    if (error) {
      setLoader(false);
    }
  }, [error]);

  const convertToDate = (dateSting) => {
    const [day, month, year] = dateSting.split("/");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

  async function packageSubscribe({ _id, profit, duration,TokenAmount },tokenLimit) {
    
  const minToken = tokenLimit;
      
            if(TokenAmount >= (minToken))
            {
              const data = {
                //uID dneed to pass here as per requiremnt 
                amount: parseInt(TokenAmount),
                shares: profit,
                period: (duration*30),//In days
                percentage: profit,
                userId: uId
              };
              
              
               packSubscribe(_id,data)
            }
            else{
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Minimum Staking limit is '+ minToken,
              })
            }
            
          }
    
  async function packSubscribe(_id,data) {

   
    
    props.setLoader(true);
    const StakeTokenAmount = data.amount
    const packageDuration  = data.period/30;

  

    const subscribe = await stakeWeb3(data);
    
     if (subscribe) {
       if (accessToken != null) {

    
        var packageExpireDate = moment().add(packageDuration, "M") 
    .format("DD/MM/YYYY");
    //console.log("aaaaa",packageExpireDate,packageDuration)
    let abc = convertToDate(packageExpireDate)
       
        let object = {
           pckgId: _id,
           txHash: subscribe.txHash,
           stakingId: subscribe.stakingId,
           stakeToken: StakeTokenAmount,
           isSubscribed: true,
           packageExpireDate:abc,
           
        };
        
        dispatch(subscribePackage(object));
        dispatch(forwardReferralBonus())
        dispatch(getSubscribedPackage())
        props.setLoader(false);
        navigate('/dashboard/history');

      }
    } else {
      ENV.swlConfigAlert("Please re-connect your wallet",)   
      props.setLoader(false);}
  }
  return (

    <>
      {buttonFlag ? (
        <button className="purple-border-btn" disabled>
          <span>Subscribe</span>
        </button>
      ) : (
        
        <button
        
          className="purple-border-btn"
          onClick={(e) => {
            packageSubscribe(props.pckg,props.tokenLimit);
            if(props.handleClick)
            props.handleClick()
          }}
        >
          {/* {  console.log(props.pckg)  } */}
          {loader ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <></>
          )}
          <span>Subscribe</span>
        </button>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
  subscribe: state.subscribe,
  settings: state.footer,
});
export default connect(mapStateToProps, {checkButton })(Subscribe);

//simple react snippet
//ff
//sfc stateless functional component
//imr import react
//usf use-setter
//uef use-state
