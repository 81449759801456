import React, { useState, useEffect } from "react";
import { Container, Button, Modal, Table, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { ENV } from "./../../config/config";
import Select from "react-select";
import placeholder from "../../assets/images/placeholder.jpg";
import validator from "validator";
import Moment from "react-moment";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./tickets.css";
import {
  createTicketReq,
  deleteTicket,
  getTickets,
  beforeTicket,
} from "./tickets.action";
import {
  deleteMsg,
  getChat,
  beforeTicketChat,
  postMsgs,
} from "../ticket-msgs/ticketMsgs.action";
const options = ENV.TicketOptions;

const Tickets = (props) => {
  const [showOne, setShowOne] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [loader, setLoader] = useState(true);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const [tickets, setTickets] = useState([]);
  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);
  const [message, setMessage] = useState("");
  const [showThree, setShowThree] = useState(false);
  const handleCloseThree = () => setShowThree(false);
  const handleShowThree = () => setShowThree(true);
  const [ticketId, setticketId] = useState();
  const [chatData, setchatData] = useState();
  const [currentTicket, setCurrentTicket] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    value: "enterType",
    label: "Select Type",
  });

  const [errorMsgs, seterrorMsgs] = useState({
    message: "",
    title: "",
  });
  const [ticketData, setTicketData] = useState({
    title: "",
    message: "",
  
  });

  useEffect(() => {
    props.getTickets();
  }, [props.ticket.upsertTicketAuth, props.ticket.deleteTicketAuth]);
  useEffect(() => {
    if (props.ticket.getTicketAuth) {
      const { tickets, pagination } = props.ticket;
      setTickets(tickets);
      setPagination(pagination);
      setLoader(false);
      props.beforeTicket();
    }
  }, [props.ticket.getTicketAuth]);

  function deleteTicket(e) {
    e.preventDefault();
    handleCloseTwo();
    props.deleteTicket(ticketId);
  }
  async function getTicketChat(id) {
    await props.getChat(id);
  }
  useEffect(() => {
    if (props.ticketMsg.getMsgsAuth) {
      const { msgs } = props.ticketMsg;
      setchatData(msgs);
      props.beforeTicketChat();
    }
  }, [props.ticketMsg.getMsgsAuth]);
  async function sendMsg(e) {
    e.preventDefault();

    let obj = {
      ticketId: ticketId,
      message: message,
    };
    await props.postMsgs(obj);
    setMessage("");
    getTicketChat(ticketId);
  }
  function ticketSetter(e) {
    setTicketData({ ...ticketData, [e.target.name]: e.target.value });
    seterrorMsgs({ ...errorMsgs, [e.target.name]: "" });
  }

  async function typesetter(e) {
    setTicketData({ ...ticketData, type: e.value });
    seterrorMsgs({ ...errorMsgs, type: "" });
  }

  async function ticketCreate(e) {
    e.preventDefault();
    let tempFlag = true;
    let errordata = {};
    setLoader(true);
    if (validator.isEmpty(ticketData.title)) {
      errordata.title = "title is missing";
      tempFlag = false;
    }
    

    if (validator.isEmpty(ticketData.message)) {
      errordata.message = "message is missing";
      tempFlag = false;
    }
    if (tempFlag) {
      setShowOne(false);
      props.createTicketReq(ticketData);
      setTicketData({
        title: "",
        message: "",
       
      });
    } else {
      seterrorMsgs({ ...errordata });
    }
    setLoader(false);
  }
  useEffect(() => {
    if (showOne == false) {
      seterrorMsgs("");
      setTicketData({
        title: "",
        message: "",
       
      });
    }
  }, [showOne]);
  useEffect(() => {
    console.log("....msg...,", errorMsgs);
  }, [errorMsgs]);
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="tickets-section">
          <div className="tickets-details d-flex">
            <Container>
              <div>
                <h2>Tickets</h2>
                <div className="ticket-main">
                  <div className="create-button text-end">
                    <Button
                      className="purple-border-btn"
                      onClick={handleShowOne}
                    >
                      <span>Create Ticket</span>
                    </Button>
                    {/*create ticket modal */}
                    <Modal
                      show={showOne}
                      onHide={handleCloseOne}
                      size="lg"
                      className="address-book-create"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Create Ticket</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form>
                          <div className="input-section">
                            <label>Title</label>
                            <input
                              type="text"
                              name="title"
                              placeholder="Enter Title"
                              defaultValue={""}
                              value={ticketData.title}
                              onChange={ticketSetter}
                            />
                            {errorMsgs?.title ? (
                              <span style={{ color: "red" }}>
                                title is required
                              </span>
                            ) : (
                              <span />
                            )}
                          </div>
                          {/* <div className="input-section">
                            <label>Type</label>
                           
                            <Select
                              Value={ticketData.type}
                              onChange={(e) => typesetter(e)}
                              options={options}
                              classNamePrefix="select"
                            />
                          </div> */}
                          <div className="input-section">
                            <label>Message</label>
                            <textarea
                              name="message"
                              type="text"
                              placeholder="Enter message"
                              value={ticketData.message}
                              onChange={ticketSetter}
                            />
                            {errorMsgs.message ? (
                              <span style={{ color: "red" }}>
                                message is required
                              </span>
                            ) : (
                              <span />
                            )}
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className="transparent-btn"
                          onClick={handleCloseOne}
                        >
                          <span>Close</span>
                        </Button>
                        <Button
                          className="purple-border-btn ms-4"
                          onClick={(e) => {
                            ticketCreate(e);
                          }}
                        >
                          <span>Create</span>
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="ticket-table">
                    <div className="tabs-body-table">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th className="text-center td-start">#</th>

                              <th className="td-title text-center">Title</th>
                              <th className="td-description text-center">
                                Description
                              </th>
                              <th className="td-type text-center">Date</th>
                              <th className="td-type text-center">Time</th>
                              <th className="text-center td-actions">
                                Actions
                              </th>

                            </tr>
                          </thead>
                          <tbody>
                            {tickets && tickets.length > 0 ? (
                              tickets.map((val, index) => (
                                <tr>
                                  <td className="text-center td-start">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="word-break">
                                        {val.contactId}
                                      </div>
                                    </div>
                                  </td>

                                  <td className="td-title">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="word-break">
                                        {val.title}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-description">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="word-break">
                                        {val.message}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-type">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="word-break">
                                        <Moment format="YYYY-MM-DD">
                                          {val?.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                  </td>
                                  
                                  <td className="td-type">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="word-break">
                                        <Moment format="HH:mm:ss">
                                          {val?.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-actions">
                                    <div className="action-buttons d-flex justify-content-around align-items-center main-row">
                                      <Button
                                        className="view-button"
                                        onClick={() => {
                                          handleShowThree();
                                          getTicketChat(val._id);
                                          setticketId(val._id);
                                          setCurrentTicket(val);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faComments}
                                          className=""
                                        />
                                      </Button>
                                      {/* View ticket modal */}
                                      <Modal
                                        show={showThree}
                                        onHide={handleCloseThree}
                                        size="lg"
                                        className="address-book-create"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>View Ticket</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div>
                                            <form onSubmit={sendMsg}>
                                              {/* <div className="input-section">
                                              <div className="uploaded-images">
                                                <Row className="justify-content-center">
                                                  <Col md={3}>
                                                    <div className="images">
                                                      <img
                                                        className="img-fluid"
                                                        src={placeholder}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                            <div className="input-section">
                                              <label for="fnameOne">Name</label>
                                              <input
                                                type="text"
                                                value="Taimoor Shahid"
                                              />
                                            </div> */}
                                              <div className="messenger-section">
                                                <div className="message-title">
                                                  <span>Ticket Information</span>
                                                </div>
                                                <Row>
                                                  <Col md={6}>
                                                    <div className="input-section">
                                                      <label
                                                        className="mb-0"
                                                        for="fnameOne"
                                                      >
                                                        Title:
                                                      </label>
                                                      <span className="input-result">
                                                        {currentTicket?.title}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col md={6}>
                                                    <div className="input-section">
                                                      <label
                                                        className="mb-0"
                                                        for="fnameOne"
                                                      >
                                                        Status:
                                                      </label>
                                                      <span className="input-result">
                                                        {currentTicket?.statusFlag ==
                                                        0
                                                          ? "pending"
                                                          : ""}
                                                        {currentTicket?.statusFlag ==
                                                        1
                                                          ? "resolved"
                                                          : ""}
                                                        {currentTicket?.statusFlag ==
                                                        2
                                                          ? "rejected"
                                                          : ""}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col md={6}>
                                                    <div className="input-section">
                                                      <label
                                                        className="mb-0"
                                                        for="fnameOne"
                                                      >
                                                        Date:
                                                      </label>
                                                      <span className="input-result">
                                                        <Moment format="YYYY/MM/DD">
                                                          {
                                                            currentTicket?.createdAt
                                                          }
                                                        </Moment>
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col md={12}>
                                                    <div className="input-section">
                                                      <label
                                                        className="mb-0"
                                                        for="fnameOne"
                                                      >
                                                        Description:
                                                      </label>
                                                      <span className="input-result">
                                                        {currentTicket?.message}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                              <div className="messenger-section">
                                                <div className="message-title text-center">
                                                  <span>Contact Admin</span>
                                                </div>
                                                <div className="message-area">
                                                  {chatData?.map(
                                                    (val, index) => {
                                                      if (val.userId) {
                                                        return (
                                                          <div className="chat-box text-end">
                                                            <div className="right-hand text-start">
                                                              {val.message}
                                                            </div>
                                                          </div>
                                                        );
                                                      } else if (val.adminId) {
                                                        return (
                                                          <div className="chat-box">
                                                            <div className="left-hand">
                                                              {val.message}
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </div>
                                                <div className="input-section">
                                                  <input
                                                    type="text"
                                                    value={message}
                                                    placeholder="Enter Message"
                                                    onChange={(e) => {
                                                      setMessage(
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="message-button text-center">
                                                  <Button
                                                    className="purple-border-btn"
                                                    type="submit"
                                                  >
                                                    <span>Send Message</span>
                                                  </Button>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            className="transparent-btn"
                                            onClick={handleCloseThree}
                                          >
                                            <span>Close</span>
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>

                                      <Button
                                        className="delete-button"
                                        onClick={() => {
                                          handleShowTwo();
                                          setticketId(val._id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className=""
                                        />
                                      </Button>

                                      <Modal
                                        show={showTwo}
                                        onHide={handleCloseTwo}
                                        size="lg"
                                        className="address-book-create"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Are you sure you want to delete?
                                          </Modal.Title>
                                        </Modal.Header>

                                        <Modal.Footer>
                                          <Button
                                            className="transparent-btn"
                                            onClick={handleCloseTwo}
                                          >
                                            <span>Close</span>
                                          </Button>
                                          <Button
                                            className="purple-border-btn ms-3"
                                            onClick={(e) => deleteTicket(e)}
                                          >
                                            <span>Delete</span>
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <div
                                    className="alert alert-info"
                                    role="alert"
                                  >
                                    No tickets Found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
  ticket: state.ticket,
  ticketMsg: state.ticketMsg,
});

export default connect(mapStateToProps, {
  createTicketReq,
  deleteTicket,
  getTickets,
  beforeTicket,
  deleteMsg,
  getChat,
  beforeTicketChat,
  postMsgs,
})(Tickets);
