import {
  BEFORE_WITHDRAWAL,
  GET_WITHDRAWAL,
  POST_WITHDRAWAL,
} from "../../redux/types";
const initialState = {
  user: null,
  subscription: null,
  withdrawals: null,
  withdrawal: null,
  settings:null,
  upsertWithdrawalAuth: false,
  getWithdrawalAuth: false,
  pagination: null,
  pendingReqAmount: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WITHDRAWAL:
      return {
        ...state,
        withdrawals: action.payload.withdrawal,
        pagination: action.payload.pagination,
        user: action.payload.user,
        settings:action.payload.settings,
        subscription: action.payload.subscription,
        pendingReqAmount:action.payload.PendingReqAmount,
        getWithdrawalAuth: true,
      };
    case POST_WITHDRAWAL:
      return {
        ...state,
        upsertWithdrawalAuth: true,
        withdrawal: action.payload,
      };
    case BEFORE_WITHDRAWAL:
      return {
        ...state,
        user: null,
        withdrawals: null,
        withdrawal: null,
        upsertWithdrawalAuth: false,
        getWithdrawalAuth: false,
        pagination: null,
        subscription: null,
        settings:null,
        pendingReqAmount:null
      };
    default:
      return {
        ...state,
      };
  }
}
