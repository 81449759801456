import React, { useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import PackagesSection from "../packages-section/packages-section";

const Packages = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <MainBanner />
      <PackagesSection />
    </React.Fragment>
  );
};

export default Packages;
