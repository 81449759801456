import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import {
  BEFORE_USER,
  BEFORE_REQUEST,
  SEND_REQUEST,
  UPDATE_PASSWORD,
  GET_ERRORS,
  SET_USER,
  REGISTER_First,
  RESET_PASSWORD,
  VERIFY_USER,
  FORGOT_PASSWORD,
  UPDATE_PROFILE,
  REGISTER_USER,
  LOGIN_USER,
  ENABLE_SECURITY,
  GET_SECURITY,
  BEFORE_SECURITY,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";

export const beforeUser = () => {
  return {
    type: BEFORE_USER,
  };
};

export const beforeSecurity = () => {
  return {
    type: BEFORE_SECURITY,
  };
};

export const signOut = () => {
  localStorage.clear();
  // localStorage.removeItem('accessToken');
  // localStorage.removeItem('encuse');
  return {
    type: BEFORE_USER,
  };
};

export const login = (body, setLoader) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}auth/login`;

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      setLoader(false);
     
      if (data.success) {
        if (data.success) {
          if (data.data.isVerified) {
            //localStorage.setItem("accessToken", data.data.accessToken);
            ENV.encryptUserData(data.data);
            dispatch({
              type: LOGIN_USER,
              payload: data.data,
            });
          } else {
            toast("Account not verified");
          }
        } else {
          ENV.swlConfigAlert("You are not active. Kindly contact admin!");
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      } else {
        ENV.swlConfigAlert(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const signup = (body, setLoader) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}auth/register/`;

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      setLoader(false);
      if (data.success) {
        if (data.success) {
          ENV.swlConfig(data.message2, data.success);
          dispatch({
            type: REGISTER_USER,
            payload: data.data,
          });
        } else {
          ENV.swlConfigAlert("You are not active. Kindly contact admin!");
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      } else {
        ENV.swlConfigAlert(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const verification = (token, accessToken) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}auth/verify-user/${token}`;
  fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
    // body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        localStorage.setItem("accessToken", data.data.accessToken);
        ENV.encryptUserData(data.data);
        ENV.swlConfig(data.message, data.success);
        dispatch({
          type: VERIFY_USER,
          payload: data.data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const forgotPassword = (body) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}auth/forgot-password`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message, data.success);
        // toast(data.message);
        dispatch({
          type: FORGOT_PASSWORD,
          msg: data.message,
        });
      } else {
        toast.error(data.message);
        dispatch({
          type: FORGOT_PASSWORD,
          msg: data.message,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const resetPassword =
  (body, method = "POST") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/reset-password`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          ENV.swlConfig(data.message, data.success);
          dispatch({
            type: RESET_PASSWORD,
            msg: data.message,
          });
        } else {
          ENV.swlConfig(data.message, data.success);
          dispatch({
            type: RESET_PASSWORD,
            msg: data.message,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
        });
      });
  };

export const updateProfile =
  (body, method = "PUT") =>
  (dispatch) => {
    
    dispatch(emptyError());
    const url = `${ENV.url}auth/edit-profile`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          ENV.swlConfig(data.message, data.success);
          data.user["accessToken"] = localStorage.getItem("accessToken");
          ENV.encryptUserData(data.user);

          dispatch({
            type: UPDATE_PROFILE,
            payload: { user: data.data, message: data.message },
          });
        } else {
          ENV.swlConfig(data.message, data.success);
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const updateAddress =
  (body, method = "POST") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/update-address`;
    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          data.data["accessToken"] = localStorage.getItem("accessToken");
          ENV.encryptUserData(data.data);
          dispatch({
            type: UPDATE_PROFILE,
            payload: { user: data.data, message: data.message },
          });
        } else {
          // toa.error(data.message);
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

//Edit security
export const enablesSecurity =
  (body, method = "POST") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}security/create`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: ENABLE_SECURITY,
            payload: { security: data.security, message: data.message },
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

//get security
export const getSecurity =
  (body, method = "GET") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}security/get`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_SECURITY,
            payload: { security: data.data.security[0], message: data.message },
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const updatePassword =
  (body, method = "PUT") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/change-password`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          ENV.swlConfig(data.message, data.success);
          dispatch({
            type: UPDATE_PASSWORD,
            payload: { message: data.message },
          });
        } else {
          ENV.swlConfig(data.message, data.success);
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const beforeRequest = () => {
  return {
    type: BEFORE_REQUEST,
  };
};
export const sendRequest =
  (body, method = "POST") =>
  (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}request/create`;
    fetch(url, {
      method,
      headers: {
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token":
          ENV.getUserKeys("accessToken") &&
          ENV.getUserKeys("accessToken").accessToken
            ? ENV.getUserKeys("accessToken").accessToken
            : "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: SEND_REQUEST,
            payload: data,
            // payload: { message: data.message },
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };


export const resendVerifyEmail = (body) => (dispatch) => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/resendEmail`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          ENV.swlConfig(data.message, data.success);
          
        } else {
          toast.error(data.message);
          
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };