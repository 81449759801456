import React, { useState, useEffect } from "react";
import { connect} from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import lottie from "lottie-web";
import bronzeAnimation from "../../assets/lottie-files/packages/92788-bronze-coin.json";
import silverAnimation from "../../assets/lottie-files/packages/lf30_editor_sypelaoz.json";
import goldAnimation from "../../assets/lottie-files/packages/18089-gold-coin.json";
import { getStackingPackage, beforeStackingPackage } from "../choose-staking/choose-staking.actions";
import "./packages-section.css";
import PackagesRightComponent from "./package-section-component-Right";
import PackagesLeftComponent from "./package-section-component-left";

const PackagesSection = (props) => {
  const [loader, setLoader] = useState(true);
  const [allStackingPackage, setallStackingPackage] = useState(null);

  useEffect(() => {
    props.getStackingPackage(); ///get API
  }, []);

  useEffect(() => {
    if (props.package.pkgAuth) {
      const data1 = props.package.pkg;
      setallStackingPackage(data1);
      props.beforeStackingPackage();
    }
  }, [props.package.pkgAuth]);

  useEffect(() => {
    if (allStackingPackage) {
      setLoader(false);
    }
  }, [allStackingPackage]);

  
  useEffect(() => {
    if (allStackingPackage ) {
      allStackingPackage.map((item, i) => {
        lottie.loadAnimation({
            container: document.querySelector(`.${item.type}`),
            animationData:  item.type === 'gold' ? goldAnimation : item.type === 'silver' ? silverAnimation :  item.type === 'bronze' ? bronzeAnimation :  null
          });
      })

      setLoader(false);
    }
  }, [allStackingPackage]);

  return (
    <>
    {loader ? (
      <h1 />
    ) : (
    <div className="packages-section">
      <div className="normal-box">
        <Container>
          <Row>
            <Col md={12}>
              <div className="packages-head text-center">
                <span className="small-head">PACKAGES</span>
                <h2>Etiam integer</h2>
                <p>
                  Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl,
                  consectetur et luctus et, porta ut dolor. Curabitur ultricies
                  ultrices nulla. Morbi blandit nec est vitae dictum. Etiam vel
                  consectetur diam. Maecenas vitae egestas dolor. Fusce tempor
                  magna at tortor aliquet finibus. Sed eu nunc sit amet elit
                  euismod faucibus. Class aptent taciti sociosqu ad litora
                  torquent.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {allStackingPackage.map((i,index) => (
        <>
        { index%2 ==0  ? (
      <div className="bronze-area">
        <Container>
          <PackagesRightComponent pckg={i}></PackagesRightComponent>
        </Container>
      </div>
        ):(
      <div className="silver-area">
        <Container>
          <PackagesLeftComponent pckg={i}></PackagesLeftComponent>
        </Container>
      </div>
      )}
      </>
        ))}
        
    </div>
    )}
    </>
  );
};
const mapStateToProps = (state) => ({
  package: state.package,
  pkg: state.pkg,
  error: state.error,
});

export default connect(mapStateToProps, {
  getStackingPackage,
  beforeStackingPackage,
})(PackagesSection);

