import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Images } from "../../assets/assets";
import lottie from "lottie-web";
import signupLottie from "../../assets/lottie-files/signup/lf30_editor_gjedppyj.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import { ENV } from "./../../config/config";
import { signup, login } from "./user.action";
import { toast } from "react-toastify";
import { useNavigate, Link, useLocation } from "react-router-dom";
import PasswordInput from "./passwordInput";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { emptyError } from "../../redux/shared/error/error.action";
import queryString from "query-string";
import Select from "react-select";
import "./signup.css";
const countryOptions = ENV.countriesOption;

const Signup = (props) => {
  const [loader, setLoader] = useState(false);
  useLayoutEffect(() => {
    if (localStorage.getItem("accessToken")) window.location.href = "/";
  }, []);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#signup"),
      animationData: signupLottie,
    });
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { referralKey } = queryString.parse(location.search);

  const [data, setData] = useState({
    firstName: "",
    country: "",
    email: "",
    password: "",
    cPassword: "",
    referralString: "",
  });

  const [agreeCheck, setAgreeCheck] = useState(false);
  const [reg, setReg] = useState(false);
  const [err, setErr] = useState({});
  const [showPwd, setShowPwd] = useState(false);
  const [referralLink, setReferralLink] = useState();

  useEffect(() => {
    props.emptyError();
  }, []);

  useEffect(() => {
    if (props.user?.registerUserAuth) {
     
      let token = props.user.user.rndmoken;
     
    }
  
  }, [props.user?.registerUserAuth]);
  // useEffect(() => {
  //   if (props.user.isVerified) {
  //     navigate(`/dashboard`);
  //   }
  // }, [props.user.isVerified]);
  useEffect(() => {
    if (props.error?.error) {
      let error = err;
      error["auth"] = props?.error?.error?.message;
      setErr({ ...error });
    } else {
      setErr({});
    }
  }, [props.error?.error]);

  const changeData = (name, value) => {
    let tempData = data;
    console.log(tempData, "tempData⭐");
    tempData[name] = value;
    setData({ ...tempData });
  };

  const validation = () => {
    
    let error = {};
    let isValid = true;
    if (data.firstName == "") {
      error["firstName"] = "Name is required.";
      isValid = false;
    }
    if (data.country == "") {
      error["country"] = "Country is required.";
      isValid = false;
    }
    if (data.email == "") {
      error["email"] = "Email is required.";
      isValid = false;
    }
    if (data.email[0] == "." && data.email != "") {
      
      error["email"] = "Email is invalid";
      isValid = false;
    }
    if (data.password == "") {
      error["password"] = "Password is required.";
      isValid = false;
    }
    if (data.password != "") {
      if (data.password.length < 8) {
        error["passwordlen"] = "Password is too shot min 8 letter.";
        isValid = false;
      }
    }
    if (data.cPassword == "") {
      error["cPassword"] = "Confirm password is required.";
      isValid = false;
    }

    if (data.password.trim() != data.cPassword.trim()) {
      error["cPassword"] = "Password and Confirm Password not match";
      isValid = false;
    }
    setErr({ ...error });
    return isValid;
  };

  const registerUser = (e) => {
    e.preventDefault();
    setLoader(true);
    if (validation()) {
      const myurl = window.location.pathname;
      var parts = myurl.split("/");
      var lastSegment = parts.pop(); // handle potential trailing slash
      if (lastSegment == "signup") {
        data.referralString = "";
      } else {
        data.referralString = lastSegment;
      }
      props.signup(data, setLoader);

      setReg(true)
    }
    else
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="signup-section">
          <div className="signup-header">
            <Container>
              <Row>
                <Col md={12}>
                  <strong className="logo">
                    <Link to="/">
                      <img
                        src={Images.logo}
                        className="img-fluid"
                        alt="StalkingStream Logo"
                      />
                    </Link>
                  </strong>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="main-section">
            <Container className="h-100">
              <Row className="h-100">
                <Col lg={6} className="d-none d-lg-block">
                  <div className="signup-lottie d-flex align-items-center justify-content-center h-100">
                    <div className="signup-lottie-in" id="signup"></div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="signup-data d-flex align-items-center h-100">
                    <div className="signup-data-in">
                      <span className="heading">Sign Up</span>
                      <div className="signup-form">
                        <form onSubmit={registerUser}>
                          <div className="input-section">
                            <label for="fname" className="data-label">
                              Name
                            </label>

                            <input
                              id="name"
                              name="firstName"
                              type="text"
                              placeholder="Enter Your Name"
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, firstName: e.target.value })
                              }
                            />
                          
                            {err["firstName"] && (
                              <p className="error red">{err["firstName"]}</p>
                            )}
                          </div>
                          <div className="input-section">
                            <label for="emailTwo" className="data-label">
                              Email
                            </label>
                            <input
                              id="emailTwo"
                              name="email"
                              type="email"
                              placeholder="Enter Your Email"
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, email: e.target.value })
                              }
                            />
                            {err["email"] && (
                              <p className="error red">{err["email"]}</p>
                            )}
                          </div>
                          {countryOptions ? (
                            <div className="input-section">
                              <label for="country" className="data-label">
                                Country
                              </label>
                              <Select
                                className="form-control p-0 no-border"
                                id="country"
                                name="country"
                                placeholder="Enter Your Country"
                                Value={data.country}
                                onChange={(e) => {
                                  setData({ ...data, country: e.label });
                                }}
                                options={countryOptions}
                                classNamePrefix="select"
                              />

                              {err["country"] && (
                                <p className="error red">{err["country"]}</p>
                              )}
                            </div>
                          ) : (
                            <span />
                          )}
                          <div className="input-section">
                            
                            <div className="relative-input">
                              <PasswordInput
                                label="Password"
                                name="password"
                                changeData={(name, value) => {
                                  let target = { name, value };
                                  let event = { target };
                                 
                                  setData({
                                    ...data,
                                    password: event.target.value,
                                  });
                                }}
                                forOne="password"
                                id="passwordOne"
                              />
                              {/* 
                          <input
                            id="passwordOne"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter Your Password"
                            onChange={(e) =>
                              setData({ ...data, password: e.target.value })
                            }
                          /> */}
                              {/* <span className="eye-icon">
                            <FontAwesomeIcon icon={faEye} />
                          </span> */}
                              {err["password"] && (
                                <p className="error red">{err["password"]}</p>
                              )}
                              {err["passwordlen"] && (
                                <p className="error red">
                                  {err["passwordlen"]}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="input-section last-section">
                            {/* <label for="cPassword" className="data-label">
                          Confirm Password
                        </label> */}
                            <div className="relative-input">
                              <PasswordInput
                                label="Confirm Password"
                                name="cPassword"
                                changeData={(name, value) => {
                                  let target = { name, value };
                                  let event = { target };
                                 
                                  setData({
                                    ...data,
                                    cPassword: event.target.value,
                                  });
                                }}
                                forOne="password"
                                id="cPassword"
                              />
                              {/* <input
                            id="cPassword"
                            name="cPassword"
                            type="password"
                            className="form-control"
                            placeholder="Enter Your Password"
                            onChange={(e) =>
                              setData({ ...data, cPassword: e.target.value })
                            }
                          />
                          <span className="eye-icon">
                            <FontAwesomeIcon icon={faEye} />
                          </span> */}
                              {err["cPassword"] && (
                                <p className="error red">{err["cPassword"]}</p>
                              )}
                            </div>
                          </div>
                          <div className="signup-button text-center">
                            <button className="purple-border-btn">
                              <span>Sign Up</span>
                            </button>
                          </div>
                          <div className="signup-button-in d-flex justify-content-center align-items-center">
                            <span className="signup-text">
                              Already have an Account?
                            </span>
                            <Link className="signup-link ms-2" to="/login">
                              Login
                            </Link>
                          </div>
                          {reg==true ? (
                          <div className="pt-2 signup-button-in d-flex justify-content-center align-items-center">
                            <span className="signup-text">
                              Verification Email Not recived ? 
                            </span>
                            <Link className="signup-link ms-2" to="/resendEmail">
                              Resend Email
                            </Link>
                          </div>
                          ) : (
                            <div></div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, {
  signup,
  emptyError,
})(Signup);
