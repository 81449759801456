import React from "react";
import { ENV } from "../../config/config";
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from "../../assets/assets";
import lottie from "lottie-web";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import bronzeAnimation from "../../assets/lottie-files/92788-bronze-coin.json";
import silverAnimation from "../../assets/lottie-files/lf30_editor_sypelaoz.json";
import goldAnimation from "../../assets/lottie-files/18089-gold-coin.json";

import './packages-section.css';
import PackagesPrintComponent from "./package-section-component-diaplay";

const PackagesRightComponent = ({pckg}) => {
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#bronze"),
          animationData: bronzeAnimation,
        });
        lottie.loadAnimation({
          container: document.querySelector("#silver"),
          animationData: silverAnimation,
        });
        lottie.loadAnimation({
          container: document.querySelector("#gold"),
          animationData: goldAnimation,
        });
      }, []);

  return (
    <Row className='flex-lg-row-reverse'>
    <Col lg={5}>
      <div className='package-img'>
        <img className='img-fluid' alt=''
         src={
          pckg.image
                                          ? ENV.imagebaseUrl + pckg.image
                                          : ENV.userDefaultImg
                                      }
                                        />
        <div className={pckg.type}></div>
        <div className="rewards-section">
              <span className="reward-text">Reward</span>
              <span className="percent">{pckg.profit}%</span>
          </div>
      </div>
    </Col>
    <PackagesPrintComponent pkg = {pckg}></PackagesPrintComponent>
    
  </Row>
    
  );
};

export default PackagesRightComponent;
