import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ENV } from "./../../config/config";
import {
  faTrophy,
  faMoneyBill,
  faMoneyBillTransfer,
  faHandHoldingDollar,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import {
  getupdateduser,
} from "../package-subscribe/subscribe.action";
import { connect,useDispatch } from "react-redux";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./dashboard.css";
import { calculateAvailableProfitWeb3 } from "../../utils/web3";
import { getDashboard, beforeDashboard,getEranReward,resetEarnToken} from "./dashborad.action";

const Dashboard = (props) => {
 const dispatch = useDispatch();
  const { uId } = ENV.getUserKeys("uId");
  const [loader, setLoader] = useState(true);
  const [dashboardData, setDashbaordData] = useState(null);
  const [balance, setBalance] = useState(0);
  const [earnBalance, setEarnBalance] = useState(0);
  const [oneFlag, setOneFlag] = useState(true);
  const { claimReferral } = ENV.getUserKeys("claimReferral");

  useEffect(() => {
    const fetchData = async () => {
     if(oneFlag)
    {
      dispatch(getupdateduser())
      setOneFlag(false)
    }
      props.getDashboard();
   
      const blance = await calculateAvailableProfitWeb3(uId);
      if (blance) setBalance(parseFloat(blance).toFixed(8));
      props.getEranReward()
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  // useEffect(async() => {
  //   props.getDashboard();
  //   const blance =await calculateAvailableProfitWeb3(localStorage.getItem('connectedAddress'));
  //   console.log("balance 01", blance)
  //   if(blance)
  //   setBalance(blance)
  // }, []);

  useEffect(() => {
    if (props.dashboard.getDashboardAuth) {
      const { dashboard } = props.dashboard;
      setDashbaordData(dashboard);
      setLoader(false);
      props.beforeDashboard();
    }
  }, [props.dashboard.getDashboardAuth]);


  useEffect(() => {
    if (props.dashboard.earnToeknAuth) {
      let earnamount=0
      
       earnamount = (Number(balance) + Number(props.dashboard.earnToekn))
      setEarnBalance(earnamount)
      props.resetEarnToken()
    }
  }, [props.dashboard.earnToeknAuth]);

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="dashboard">
         
          <div className="dashboard-details d-flex">
            <Container>
              <div>
                <h2>Dashboard</h2>
                <div className="dashboard-boxes">
                  <Row>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">
                                Daily Reward Amount
                              </p>
                              <Card.Title as="h4">
                                {" "}
                                FIXM{" "}
                                <span>
                                  {parseFloat(
                                    dashboardData?.dailyAmount
                                  ).toFixed(3)}
                                </span>
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faMoneyBill}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">
                                Total Rewards Earned
                              </p>
                              <Card.Title as="h4">
                                FIXM{" "}
                                <span>{parseFloat(earnBalance).toFixed(3)}</span>
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faTrophy}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">Withdrawal Amount</p>
                              <Card.Title as="h4">
                                $ <span>{parseFloat(dashboardData?.totalAmount).toFixed(3)}</span>
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faMoneyBillTransfer}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col> */}
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">
                                Total Bonus Amount Of Active Package
                              </p>
                              <Card.Title as="h4">
                                FIXM{" "}
                                {parseFloat(
                                  dashboardData?.willGetAmount
                                ).toFixed(3)}
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faHandHoldingDollar}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">Total Withdrawal Amount</p>
                              <Card.Title as="h4">
                                FIXM{" "}
                                {parseFloat(dashboardData?.totalFIXMAmount).toFixed(
                                  3
                                )}
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faHandHoldingDollar}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">Total Withdrawal Amount</p>
                              <Card.Title as="h4">
                                USDT{" "}
                                {parseFloat(dashboardData?.totalUSDTAmount).toFixed(
                                  3
                                )}
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faHandHoldingDollar}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">Package Purchased</p>
                              <Card.Title as="h4">
                                {dashboardData?.totalPackage}
                              </Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faCartShopping}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xxl={4} md={6}>
                      <Card className="card-stats">
                        <Card.Body>
                          <div className="inner-stats d-flex align-items-center">
                            <div className="numbers order-sm-0 order-1">
                              <p className="card-category">
                                Claimed Referral Reward
                              </p>
                              <Card.Title as="h4">  FIXM{" "}{claimReferral}</Card.Title>
                            </div>
                            <div className="icon-big text-center icon-warning order-0 order-sm-1">
                              <FontAwesomeIcon
                                icon={faMoneyBillTransfer}
                                className="box-icon"
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
  dashboard: state.dashboard,
});
export default connect(mapStateToProps, { getDashboard, beforeDashboard,getEranReward,resetEarnToken })(
  Dashboard
);
