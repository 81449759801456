/*layouts*/
import Layout1 from "./layouts/layout1";
import Layout2 from "./layouts/layout2";
import Layout3 from "./layouts/layout3";
import Layout4 from "./layouts/layout4";

/*components*/
import Home from "./components/Home/Home";
import AboutUs from "./components/about-us/about-us";
import Packages from "./components/packages-page/packages";
import Refferal from "./components/referral-page/referral";
import ContactUs from "./components/contact-us/contact-us";
import Login from "./components/login/login";
import resendEmial from "./components/resend-email/resendEmial";
import Signup from "./components/signup/signup";
import ForgetPassword from "./components/forget-password/forget-password";
import ResetPassword from "./components/reset-password/reset-password";
import NotFound from "./components/not-found/not-found";
import Faqs from "./components/faqs/faq";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
import TermsConditions from "./components/terms-conditions/terms-conditions";
import MyAccount from "./components/my-account/my-account";
import ReferralDashboard from "./components/referral-dashobard/referral-dashboard";
import PackagesTab from "./components/packages-tab/packages-tab";
import History from "./components/history/history";
import Tickets from "./components/tickets-tab/tickets";
import Withdrawl from "./components/withdrawl/withdrawl";
import Dashboard from "./components/dashboard/dashboard";
import VerfiyEmail from "./components/verify-email/VerifyEmail";

export const defaultRoutes = [
  {
    path: "/",
    layout: Layout1,
    access: true,
    exact: true,
    component: Home,
  },
  {
    path: "/about-us",
    layout: Layout1,
    access: true,
    exact: true,
    component: AboutUs,
  },
  {
    path: "/packages",
    layout: Layout1,
    access: true,
    exact: true,
    component: Packages,
  },
  {
    path: "/referral",
    layout: Layout1,
    access: true,
    exact: true,
    component: Refferal,
  },
  {
    path: "/contact-us",
    layout: Layout1,
    access: true,
    exact: true,
    component: ContactUs,
  },
  {
    path: "/login",
    layout: Layout3,
    access: true,
    exact: true,
    component: Login,
  },
  {
    path: "/resendEmail",
    layout: Layout3,
    access: true,
    exact: true,
    component: resendEmial,
  },
  {
    path: "/signup/:key",
    layout: Layout3,
    access: true,
    exact: true,
    component: Signup,
  },
  {
    path: "/signup",
    layout: Layout3,
    access: true,
    exact: true,
    component: Signup,
  },
  {
    path: "/forget-password",
    layout: Layout3,
    access: true,
    exact: true,
    component: ForgetPassword,
  },
  {
    path: "/reset-password/:token",
    layout: Layout3,
    access: true,
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/not-found",
    layout: Layout3,
    access: true,
    exact: true,
    component: NotFound,
  },
  {
    path: "/verify-email/:token",
    layout: Layout3,
    access: true,
    exact: true,
    component: VerfiyEmail,
  },
  {
    path: "/faqs",
    layout: Layout1,
    access: true,
    exact: true,
    component: Faqs,
  },
  {
    path: "/privacy-policy",
    layout: Layout1,
    access: true,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: "/terms-conditions",
    layout: Layout1,
    access: true,
    exact: true,
    component: TermsConditions,
  },
  {
    path: "/dashboard",
    layout: Layout2,
    access: true,
    exact: true,
    component: Dashboard,
  },
  {
    path: "/dashboard/my-account",
    layout: Layout2,
    access: true,
    exact: true,
    component: MyAccount,
  },
  {
    path: "/dashboard/referrals",
    layout: Layout2,
    access: true,
    exact: true,
    component: ReferralDashboard,
  },
  {
    path: "/dashboard/packages",
    layout: Layout2,
    access: true,
    exact: true,
    component: PackagesTab,
  },
  {
    path: "/dashboard/history",
    layout: Layout2,
    access: true,
    exact: true,
    component: History,
  },
  {
    path: "/dashboard/tickets",
    layout: Layout2,
    access: true,
    exact: true,
    component: Tickets,
  },
  {
    path: "/dashboard/withdrawal",
    layout: Layout2,
    access: true,
    exact: true,
    component: Withdrawl,
  },
];
