import React, { useState, useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import WhoWeAre from "../who-we-are/who-we-are";
import OurMission from "../our-mission/our-mission";
import ChooseUs from "../choose-us/choose-us";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <MainBanner />
      <WhoWeAre />
      <ChooseUs />
      <OurMission />
    </React.Fragment>
  );
};

export default AboutUs;
