import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordInput = ({ label, name, changeData, errorMsg, forOne, id }) => {
  const [type, setType] = useState(true);

  return (
    <>
      <div>
        <label className="data-label" for={forOne}>
          {label}
        </label>
        <div className="position-relative">
          <input
            id={id}
            type={type ? "password" : "text"}
            name={name}
            onChange={(e) => changeData(name, e.target.value)}
            className="form-control"
            placeholder="Enter Password"
          />
          <span
            className="eye-icon"
            onClick={() => setType((prevVal) => !prevVal)}
          >
            {type ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} onClick={() => setType(false)} />
            )}
          </span>
        </div>
      </div>
      {errorMsg && <p className="error position-absolute red">{errorMsg}</p>}
    </>
  );
};

export default PasswordInput;
