import React, { useState, useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import MainReferral from "../main-referral/main-referral";
import ReferralPolicy from "../referral-policy/referral-policy";

const Refferal = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <MainBanner />
      <MainReferral />
      <ReferralPolicy />
    </React.Fragment>
  );
};

export default Refferal;
