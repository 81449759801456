import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { getSettings, beforeSetting } from "../footer/footer.action";
import "./main-banner.css";

const MainBanner = (props) => {
  const userLogin = localStorage.getItem("accessToken");
  const [facebook, setfacebook] = useState(null);
  const [linkedin, setlinkedin] = useState(null);
  const [twitter, settwitter] = useState(null);
  const [pinterest, setpinterest] = useState(null);
  const [youtube, setyoutube] = useState(null);
  const { pathname } = useLocation();
  const [CMS, setCMS] = useState(null);
  useEffect(() => {
    props.getSettings(); ///get API
  }, []);

  useEffect(() => {
    if (props.mainbanner.linkAuth) {
      const socialdata = props.mainbanner.soical_link;
      setfacebook(socialdata.facebook);
      setlinkedin(socialdata.linkedin);
      settwitter(socialdata.twitter);
      setpinterest(socialdata.pinterest);
      setyoutube(socialdata.youtube);
      setCMS(socialdata.CMS);
      props.beforeSetting();
    }
  }, [props.mainbanner.linkAuth]);

  return (
    <div className="main-banner">
      <Container>
        <Row>
          <Col md={12}>
            {pathname?.toLowerCase() === "/about-us" && (
              <div className="about-banner inner-banner text-center">
                <h2>About Us</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                {userLogin ? (
                  <></>
                ) : (
                  <Link to="/login" className="purple-border-btn">
                    <span>Join Us</span>
                  </Link>
                )}
              </div>
            )}
            {pathname?.toLowerCase() === "/contact-us" && (
              <div className="contact-banner text-center">
                <h2>Contact Us</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <div className="social-icons">
                  <ul className="d-flex align-items-center justify-content-center">
                    {facebook ? (
                      <li>
                        <a href={facebook} target="_blank" className="facebook">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {linkedin ? (
                      <li>
                        <a href={linkedin} target="_blank" className="linkedin">
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {twitter ? (
                      <li>
                        <a href={twitter} target="_blank" className="twitter">
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {pinterest ? (
                      <li>
                        <a
                          href={pinterest}
                          target="_blank"
                          className="pininterest"
                        >
                          <FontAwesomeIcon icon={faPinterest} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {youtube ? (
                      <li>
                        <a href={youtube} target="_blank" className="youtube">
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            )}
            {pathname?.toLowerCase() === "/packages" && (
              <div className="about-banner inner-banner text-center">
                <h2>Our Packages</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <Link to="/contact-us" className="purple-border-btn">
                  <span>Contact Us</span>
                </Link>
              </div>
            )}
            {pathname?.toLowerCase() === "/referral" && (
              <div className="about-banner inner-banner text-center">
                <h2>Refer and Earn</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <Link to="/contact-us" className="purple-border-btn">
                  <span>Contact Us</span>
                </Link>
              </div>
            )}
            {pathname?.toLowerCase() === "/privacy-policy" && (
              <div className="privacy-banner inner-banner text-center">
                <h2>Privacy Policy</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <Link to="/contact-us" className="purple-border-btn">
                  <span>Contact Us</span>
                </Link>
              </div>
            )}
            {pathname?.toLowerCase() === "/terms-conditions" && (
              <div className="privacy-banner inner-banner text-center">
                <h2>Terms & Conditions</h2>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <Link to="/contact-us" className="purple-border-btn">
                  <span>Contact Us</span>
                </Link>
              </div>
            )}
            {CMS?.map((val, index) => {
              if (val.status) {
               if(pathname === `/${val.slug}`)  {
                  return (<div className="privacy-banner inner-banner text-center">
                  <h2>CMS Page</h2>
                  <Link to="/contact-us" className="purple-border-btn">
                    <span>Contact Us</span>
                  </Link>
                </div>)
               }
              }
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  mainbanner: state.footer,
  error: state.error,
});

export default connect(mapStateToProps, {
  getSettings,
  beforeSetting,
})(MainBanner);
