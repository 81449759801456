import React, { useState, useEffect } from "react";
import { ENV } from "../../config/config";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { getSettings, beforeSetting } from "./footer.action";
import "./footer.css";

const Footer = (props) => {
  const [facebook, setFacebook] = useState(null);
  const [linkedin, setLinkedin] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [pinterest, setPinterest] = useState(null);
  const [youtube, setYoutube] = useState(null);
  const [description, setDescription] = useState(null);
  const { pathname } = useLocation();
  const [CMS, setCMS] = useState(null);
  useEffect(() => {
    props.getSettings(); ///get API
  }, []);

  useEffect(() => {
    if (props.footer.linkAuth) {
      const socialdata = props.footer.soical_link;
      //console.log(" props.footer..................", socialdata.CMS);
      setFacebook(socialdata.facebook);
      setLinkedin(socialdata.linkedin);
      setTwitter(socialdata.twitter);
      setPinterest(socialdata.pinterest);
      setYoutube(socialdata.youtube);
      setDescription(socialdata.desc);
      setCMS(socialdata.CMS);
      props.beforeSetting();
    }
  }, [props.footer.linkAuth]);

  return (
    <footer id="footer">
      <Container>
        <Row className="padding-bottom">
          <Col xl={6} lg={5} className="mb-lg-0 mb-5">
            <div className="footer-data">
              <div className="footer-logo">
                <img
                  className="img-fluid"
                  src={Images.logo}
                  alt="footer logo"
                />
              </div>
              <div className="footer-para">
                <p>{description}</p>
              </div>
              <div className="join-section">
                <div className="join-heading">
                  <span>Join Our Community</span>
                </div>
                <div className="social-icons">
                  <ul className="d-flex align-items-center">
                    {facebook ? (
                      <li>
                        <a href={facebook} target="_blank" className="facebook">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {linkedin ? (
                      <li>
                        <a href={linkedin} target="_blank" className="linkedin">
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {twitter ? (
                      <li>
                        <a href={twitter} target="_blank" className="twitter">
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {pinterest ? (
                      <li>
                        <a
                          href={pinterest}
                          target="_blank"
                          className="pininterest"
                        >
                          <FontAwesomeIcon icon={faPinterest} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {youtube ? (
                      <li>
                        <a href={youtube} target="_blank" className="youtube">
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={7}>
            <Row>
              <Col md={4} sm={6} className="mb-md-0 mb-5">
                <div className="footer-menu">
                  <h2>Useful Links</h2>
                  <ul>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/" ? "active" : ""
                      }`}
                    >
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/contact-us"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/about-us" ? "active" : ""
                      }`}
                    >
                      <Link to="/about-us">About us</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/packages" ? "active" : ""
                      }`}
                    >
                      <Link to="/packages">Packages</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/referral" ? "active" : ""
                      }`}
                    >
                      <Link to="/referral">Referral Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col md={4} sm={6} className="mb-md-0 mb-5">
                <div className="footer-menu">
                  <h2>More Links</h2>
                  <ul>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/packages"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/packages">Choose Package</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/referral" ? "active" : ""
                      }`}
                    >
                      <Link to="/referral">Get Bonus</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/contact-us"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/contact-us">Help & Support</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/packages"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/packages">Subscription</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/about-us"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/about-us">How it Works</Link>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md={4} sm={6}>
                <div className="footer-menu">
                  <h2>Explore</h2>
                  <ul>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/privacy-policy"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/terms-conditions"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                    </li>
                    {/* <li
                      className={`${
                        pathname?.toLowerCase() === "/support" ? "active" : ""
                      }`}
                    >
                      <Link to="/support">Support</Link>
                    </li> */}
                    <li
                      className={`${
                        pathname?.toLowerCase() === "/faqs" ? "active" : ""
                      }`}
                    >
                      <Link to="/faqs">FAQ's</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={6} className="mb-md-0 mb-5">
                <div className="footer-menu">
                  <h2>More Links</h2>
                  <ul>
                    {CMS?.map((val, index) => {
                      return (
                        <li
                          className={`${
                            pathname?.toLowerCase() === `/${val.slug}`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={`/${val.slug}`}>{val.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={11}>
            <div className="copyright-section text-center">
              <span>Copyright © 2022 Staking FIXM. All Rights Reserved.</span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
const mapStateToProps = (state) => ({
  footer: state.footer,
  error: state.error,
});

export default connect(mapStateToProps, {
  getSettings,
  beforeSetting,
})(Footer);
