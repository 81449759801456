import {
  BEFORE_USER,
  BEFORE_REQUEST,
  SEND_REQUEST,
  UPDATE_PASSWORD,
  GET_ERRORS,
  SET_USER,
  VERIFY_USER,
  REGISTER_First,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  UPDATE_PROFILE,
  REGISTER_USER,
  LOGIN_USER,
  ENABLE_SECURITY,
  GET_SECURITY,
  BEFORE_SECURITY,
} from "../../redux/types";

const initialState = {
  user: null,
  userAuth: false,
  registerUserAuth: false,
  loginUserAuth: false,
  registerFirst: false,
  forgotPasswordAuth: false,
  resetPasswordAuth: false,
  forgotMsg: null,
  resetMsg: null,
  updateProfileAuth: false,
  updateProfileMsg: null,
  //verification
  isVerified: false,
  //security
  security: null,
  getSecurityAuth: false,
  createSecurityAuth: false,
  createSecurityAuthMessage: null,

  //request
  sendRequestAuth: false,
  sendRequestMessage: null,
  sendRequeststatus: false,
};

export default function userRed(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        userAuth: true,
      };
    case REGISTER_First: {
      return {
        ...state,
        registerFirst: true,
      };
    }
    case VERIFY_USER:
      return {
        ...state,
        isVerified: true,
      };

    case REGISTER_USER:
      return {
        ...state,
        user: action.payload,
        registerUserAuth: true,
      };
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        loginUserAuth: true,
      };
    case BEFORE_USER:
      return {
        ...state,
        user: null,
        userAuth: false,
        registerUserAuth: false,
        loginUserAuth: false,
        registerFirst: false,
        forgotPasswordAuth: false,
        resetPasswordAuth: false,
        isVerified: false,
        forgotMsg: null,
        resetMsg: null,
        updateProfileAuth: false,
        updateProfileMsg: null,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload.user,
        updateProfileMsg: action.payload.message,
        updateProfileAuth: true,
      };

    case UPDATE_PASSWORD:
      return {
        ...state,
        updateProfileMsg: action.payload.message,
        updatePasswordAuth: true,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordAuth: true,
        forgotMsg: action.msg,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordAuth: true,
        resetMsg: action.msg,
      };

    //security
    case ENABLE_SECURITY:
      return {
        ...state,
        security: action.payload.security,
        createSecurityAuthMessage: action.payload.message,
        createSecurityAuth: true,
      };
    case GET_SECURITY: {
      return {
        ...state,
        security: action.payload.security,
        getSecurityAuth: true,
      };
    }
    case BEFORE_SECURITY:
      return {
        ...state,
        security: null,
        getSecurityAuth: false,
        createSecurityAuth: false,
      };

    case BEFORE_REQUEST:
      return {
        ...state,
        sendRequestAuth: false,
        sendRequestMessage: null,
        sendRequeststatus: false,
      };
    case SEND_REQUEST:
      return {
        ...state,
        sendRequestAuth: true,
        sendRequestMessage: action.payload.message,
        sendRequeststatus: action.payload.status,
      };

    //
    default:
      return {
        ...state,
      };
  }
}
