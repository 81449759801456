import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import { Link } from "react-router-dom";
import "./our-mission.css";

const OurMission = () => {
  return (
    <div className="Our-mission">
      <Container>
        <Row>
          <Col lg={5} className="mb-lg-0 mb-5">
            <div className="mission-img">
              <img className="img-fluid" alt="" src={Images.OurMission} />
            </div>
          </Col>
          <Col lg={7}>
            <div className="mission-data">
              <div className="mission-head">
                <span className="small-head">OUR MISSION</span>
                <h2>Mattis ipsum pellentesque</h2>
              </div>
              <div className="mission-para">
                <p>
                  Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl,
                  consectetur et luctus et, porta ut dolor. Curabitur ultricies
                  ultrices nulla. Morbi blandit nec est vitae dictum. Etiam vel
                  consectetur diam. Maecenas vitae egestas dolor.
                </p>
                <p>
                  Fusce tempor magna at tortor aliquet finibus. Sed eu nunc sit
                  amet elit euismod faucibus. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Duis gravida eget neque vel vulputate.
                </p>
              </div>
              <div className="mission-buttons d-flex align-items-center">
                <button className="purple-border-btn">
                  <span>Learn More</span>
                </button>
                <Link className="transparent-btn" to="/contact-us">
                  <span>Contact Us</span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurMission;
