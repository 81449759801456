import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Modal,
  Table,
  Nav,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Images } from "../../assets/assets";
import "./faq.css";
import { ENV } from "./../../config/config";
import Select from "react-select";
import placeholder from "../../assets/images/placeholder.jpg";
import validator from "validator";
import Moment from "react-moment";
import { beforeFaq, getFaq } from "./faq.action";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
function Faqs(props) {
  const [loader, setLoader] = useState(false);
  const [faq, setFaq] = useState();
  useEffect(() => {
    window.scroll(0, 0);
    props.getFaq();
  }, []);
  useEffect(() => {
    if (props.faq.getFaqAuth) {
      const { faqs } = props.faq;
      console.log("faq", faqs);
      setFaq(faqs);
      setLoader(false);
    }
  }, [props.faq.getFaqAuth]);
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="faqs">
          <div className="faq-section">
            <div className="faq-header">
              <Container>
                <Row className="align-items-center top-section">
                  <Col lg={6} className="index mb-4 mb-xl-0" xs={{ order: 1 }}>
                    <div className="headings">
                      <h2>HAVE ANY QUESTIONS?</h2>
                      <p>
                        Proin vulputate congue metus, eget vestibulum dolor
                        porta ac. In pretium sem quis libero efficitur, nec
                        aliquam lorem convallis. Vivamus rutrum, ligula eget
                        tempus dignissim, metus nibh fringilla quam, nec
                        fermentum.
                      </p>
                    </div>
                    <div>
                      <Link to="/contact-us" className="purple-border-btn">
                        <span>Contact Us</span>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={{ span: 6, order: 2 }} className="index">
                    <div className="faq-image text-center">
                      <img className="img-fluid" src={Images.FaqIcon} alt="" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="faq-accordion-section">
              <Container>
                <Tab.Container id="left-tabs-example">
                  <div className="d-flex justify-content-center index">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link key="1" className="faq-nav-item">
                          General
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content className="d-flex justify-content-center">
                    <div className="general-faqs">
                      <Accordion>
                        {faq?.map((val, index) => {
                          if (val.status == true)
                            return (
                              <Accordion.Item eventKey={index + 1}>
                                <Accordion.Header>{val.title}</Accordion.Header>
                                <Accordion.Body>
                                  <p style={{ color: "white" }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: val.desc,
                                      }}
                                    />
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                        })}
                      </Accordion>
                    </div>
                  </Tab.Content>
                </Tab.Container>
              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  error: state.error,
  faq: state.faq,
});
export default connect(mapStateToProps, {
  beforeFaq,
  getFaq,
})(Faqs);
