import React, { useState, useEffect } from "react";
import "./myAccount.css";
import { connect } from "react-redux";
import {
  sendRequest,
  beforeRequest,
} from "../../components/signup/user.action";

import Swal from "sweetalert2";
import {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  beforeSentEmail,
} from "../emailSecurity/emailSecurity.action";
import MessageAlert from "../messageAlert/messageAlert";
import CheckSecurity from "../qrCode/checkSecurity";
import { Row, Col } from "react-bootstrap";

function DeletingAccount(props) {
  const [loader, setLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [waitingDelFlag1, setWaitingDelFlag] = useState(false);
  const [DelFlag, setDelFlag] = useState(false);
  const [status, setStatus] = useState(null);
  const [showAlertMsgModal, setShowAlertMsgModal] = useState(false);
  const [renderSecurity, setRednerSecuirty] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    if (props.user.sendRequestAuth) {
      setLoader(false);
      setShowAlertMsgModal(true);
      setSuccessMsg(props.user.sendRequestMessage);
      setStatus(props.user.sendRequeststatus);
    }
  }, [props.user.sendRequestAuth]);

  useEffect(() => {
    setShowAlertMsgModal(false);
  }, []);

  useEffect(() => {
    if (props.secretKey.keyVerfiedAuth && waitingDelFlag1) {
      setVerified(true);
    }
    props.beforeSecretKey();
    setWaitingDelFlag(false);
  }, [props.secretKey.keyVerfied]);

  useEffect(() => {
    if (props.secretKey.emailSentAuth && DelFlag) {
      props.beforeSentEmail();
      Swal.fire({
        text: "Email Sent Succesfully!! Enter 8 digit Secret Code",
        input: "text",
      }).then((result) => {
        if (result.value) {
          const data = {};
          data.secretkey = result.value;
          data.type = "userSettingSecretkey";

          props.verifySecretKey(data);
          setWaitingDelFlag(true);
        
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your Action Is Unsaved Try Later",
          });
        }
      });
      setDelFlag(false);
    }
  }, [props.secretKey.emailSentAuth]);

  const deleteAcc = (e) => {
    e.preventDefault();
    setDelFlag(true);
    props.sentSecurityemail();

    //setRednerSecuirty(true);
  };

  const setVerified = (value) => {
    if (value) {
      let payload = {
        type: 1, // account deletion request
        msg: msg,
      };
      props.beforeRequest();
      props.sendRequest(payload);
      setMsg("");
    }
    setRednerSecuirty(false);
    setMsg("");
  };

  return (
    <>
      <MessageAlert
        type={status}
        greeting="Sorry!"
        description={successMsg}
        show={showAlertMsgModal}
        onHide={setShowAlertMsgModal}
      />
      {/* {renderSecurity && <CheckSecurity securityType={"accountDeletion"} setVerified={setVerified} />} */}

      <div className="deleting-account">
        <Row>
          <Col md={12}>
            <h3>Delete Account</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <form onSubmit={deleteAcc}>
              <label>Notes</label>
              <textarea
                className="card"
                name="msg"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
              />
              <div className="d-flex justify-content-end align-items-center">
                <button type="submit" className="purple-border-btn">
                  <span>Delete Account</span>
                  {loader && <div class="loader ms-2"></div>}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  secretKey: state.secretKey,
  user: state.user,
});

export default connect(mapStateToProps, {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  sendRequest,
  beforeRequest,
  beforeSentEmail,
})(DeletingAccount);
