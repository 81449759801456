import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/Header/Header";
import MainBanner from "../components/main-banner/main.banner";

const Layout4 = (props) => {
    return (
        <div className="position-relative">
            <Header />
            <MainBanner />
            {props.children}
            <Footer />
        </div>
    );
}

export default Layout4;
