import { Images } from '../../assets/assets';
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import forgetLottie from "../../assets/lottie-files/forget-password/lf30_editor_zfddkprv.json";
import '../forget-password/forget-password.css';
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { resendVerifyEmail } from '../../components/signup/user.action'
import { connect } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';

function ResendEmail(props) {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#forget"),
            animationData: forgetLottie,
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required").email("Email is invalid"),
        }),
        onSubmit: values => {
             props.resendVerifyEmail(values)
        },
    });



    return (
        <div className='forget-section'>
            <div className='forget-header'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <strong className="logo">
                                <Link to="/">
                                    <img src={Images.logo} className="img-fluid" alt="StalkingStream Logo" />
                                </Link>
                            </strong>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='main-section'>
                <Container className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} className="d-none d-lg-block">
                            <div className="forget-lottie d-flex align-items-center justify-content-center h-100">
                                <div className='forget-lottie-in' id="forget"></div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='forget-data d-flex align-items-center h-100'>
                                <div className='forget-data-in'>
                                    <span className='heading'>Verification Email Not Recieved?</span>
                                    <div className='forget-form'>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className='input-section'>
                                                <label for="fEmail" className='data-label'>Email associated with your account</label>
                                                <input id="fEmail" name="email"
                                                    type="text"
                                                    className='form-control'
                                                    placeholder='Enter Your Email'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email} />
                                                    <p className='red'>{formik.errors.email && formik.touched.email
                                                    ? formik.errors.email
                                                    : null}</p>
                                            </div>
                                            <div className='forget-button text-center'>
                                                <button type="submit" className='purple-border-btn' ><span>Send Verfiy Email</span></button>
                                            </div>
                                            <div className='signup-button-in d-flex justify-content-center align-items-center'>
                                                <span>Return to </span><Link className='signup-button ms-2' to="/login">Login</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
})
export default connect(mapStateToProps, {resendVerifyEmail  })(ResendEmail)





