import {
  DELETE_MSGS,
  POST_MSGS,
  GET_MSGS,
  BEFORE_MSGS,
} from "../../redux/types";

const initialState = {
  msgsId: null,
  msgs: null,
  msg: null,
  getMsgsAuth: false,
  deleteMsgsAuth: false,
  upsetMsgsAuth: false,
  pagaination: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case POST_MSGS:
      return {
        ...state,
        msg: action.payload,
        upsetMsgsAuth: true,
      };
    case DELETE_MSGS:
      return {
        ...state,
        msgsId: action.payload,
        deleteMsgsAuth: true,
      };
    case GET_MSGS:
      return {
        ...state,
        msgs: action.payload,
        getMsgsAuth: true,
      };
    case BEFORE_MSGS:
      return {
        ...state,
        msgsId: null,
        msgs: null,
        msg: null,
        getMsgsAuth: false,
        deleteMsgsAuth: false,
        upsetMsgsAuth: false,
        pagaination: null,
      };
    default:
      return {
        ...state,
      };
  }
}
