import {
  BEFORE_SUBSCRIBE,
  POST_SUBSCRIBE,
  CHECK_SUBSCRIBE,
  GET_ERRORS,
} from "../../redux/types";
import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";

export const beforeSubscribe = () => {
  return {
    type: BEFORE_SUBSCRIBE,
  };
};
export const subscribePackage = (body) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}subscription/create`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: POST_SUBSCRIBE,
          payload: data.data,
        });
        if (data.message == "already subscribe") {
          dispatch({
            type: CHECK_SUBSCRIBE,
          });
        }
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const getSubscribedPackage = () => (dispatch) => {
  dispatch(emptyError());

  let url = `${ENV.url}subscription/get`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          // type: GET_SUBSCRIBED_PACKAGE,
          // payload: data.data[0].packages,
        });
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const checkButton = () => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.url}subscription/check`;
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: CHECK_SUBSCRIBE,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const  forwardReferralBonus = () => () => {
 
  const url = `${ENV.url}/referralReward/create`;
  
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken"),
    },
  })
};

export const getupdateduser = () => (dispatch) => {
  const url = `${ENV.url}users/getuId`;
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        localStorage.setItem("accessToken", data.data.accessToken);
        ENV.encryptUserData(data.data);
      } else {
        //toast.error(data.message);
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
        
    });
  };
