import {
  CREATE_TICKET,
  GET_TICKET,
  DELETE_TICKET,
  BEFORE_TICKET,
  GET_ERRORS,
} from "../../redux/types";

const initialState = {
  ticket: null,
  tickets: null,
  ticketId: null,
  upsertTicketAuth: false,
  deleteTicketAuth: false,
  getTicketAuth: false,
  pagination: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TICKET:
      return {
        ...state,
        tickets: action.payload.Contacts,
        pagination: action.payload.pagination,
        getTicketAuth: true,
      };
    case DELETE_TICKET:
      return {
        ...state,
        ticketId: action.payload,
        deleteTicketAuth: true,
      };
    case CREATE_TICKET:
      return {
        ...state,
        ticket: action.payload,
        upsertTicketAuth: true,
      };
    case BEFORE_TICKET:
      return {
        ...state,
        ticket: null,
        tickets: null,
        ticketId: null,
        upsertTicketAuth: false,
        deleteTicketAuth: false,
        getTicketAuth: false,
        pagination: null,
      };
    default:
      return {
        ...state,
      };
  }
}
