import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import PasswordInput from "../signup/passwordInput";
import { NavLink, useNavigate } from "react-router-dom";
import resetLottie from "../../assets/lottie-files/reset-password/lf30_editor_wlta8c0h.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { resetPassword, beforeUser } from "../signup/user.action";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import "./reset-password.css";
import { useParams } from "react-router-dom";

const ResetPassword = (props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#reset"),
      animationData: resetLottie,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      cPassword: "",
      resetPasswordToken: token,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
      cPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      delete values.cPassword;
      // alert(JSON.stringify(values, null, 2));
      props.resetPassword(values);
      navigate("/login");
    },
  });

  return (
    <div className="reset-section">
      <div className="reset-header">
        <Container>
          <Row>
            <Col md={12}>
              <strong className="logo">
                <Link to="/">
                  <img
                    src={Images.logo}
                    className="img-fluid"
                    alt="StalkingStream Logo"
                  />
                </Link>
              </strong>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="main-section">
        <Container className="h-100">
          <Row className="h-100">
            <Col lg={6} className="d-none d-lg-block">
              <div className="reset-lottie d-flex align-items-center justify-content-center h-100">
                <div className="reset-lottie-in" id="reset"></div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="reset-data d-flex align-items-center h-100">
                <div className="reset-data-in">
                  <span className="heading">Set New Password</span>
                  <div className="reset-form">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="input-section">
                        <label for="rPassword" className="data-label">
                          Password
                        </label>
                        <div className="relative-input">
                          <PasswordInput
                            labal="Password"
                            name="password"
                            changeData={(name, value) => {
                              let target = { name, value };
                              let event = { target };
                              formik.handleChange(event);
                            }}
                            errorMsg={
                              formik.errors.password && formik.touched.password
                                ? formik.errors.password
                                : null
                            }
                            forOne="password"
                            id="rPassword"
                          />
                          {/* <input
                            id="rPassword"
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            values={formik.values.password}
                            className="form-control"
                            placeholder="Enter Your Password"
                          /> */}
                          {/* <p className="red">
                            {formik.errors.password && formik.touched.password
                              ? formik.errors.password
                              : null}
                          </p> */}

                          {/* <span className="eye-icon active">
                            <FontAwesomeIcon icon={faEye} />
                          </span> */}
                        </div>
                      </div>
                      <div className="input-section last-section">
                        <label for="cPasswordTwo" className="data-label">
                          Confirm Password
                        </label>
                        <div className="relative-input">
                          <PasswordInput
                            labal="Confirm Password"
                            name="cPassword"
                            changeData={(name, value) => {
                              let target = { name, value };
                              let event = { target };
                              formik.handleChange(event);
                            }}
                            errorMsg={
                              formik.errors.cPassword &&
                              formik.touched.cPassword
                                ? formik.errors.cPassword
                                : null
                            }
                            forOne="password"
                            id="cPasswordTwo"
                          />
                          {/* <input
                            id="cPasswordTwo"
                            type="password"
                            name="cPassword"
                            onChange={formik.handleChange}
                            values={formik.values.password}
                            className="form-control"
                            placeholder="Re-Enter Your Password"
                          />
                          <p className="red">
                            {formik.errors.cPassword && formik.touched.cPassword
                              ? formik.errors.cPassword
                              : null}
                          </p>
                          <span className="eye-icon">
                            <FontAwesomeIcon icon={faEye} />
                          </span> */}
                        </div>
                      </div>
                      <div className="reset-button text-center">
                        <button className="purple-border-btn">
                          <span>Set Password</span>
                        </button>
                      </div>
                      <div className="signup-button-in d-flex justify-content-center align-items-center">
                        <span>Return to</span>
                        <Link className="signup-button ms-2" to="/login">
                          Login
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, { resetPassword, beforeUser })(
  ResetPassword
);
