import React from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import DisplayImage from "../displayImage/displayImage";
import AddressBook from "./addressBook";
import DeletingAccount from "./deletingAccount";
import Email from "./email";
import FinancialReport from "./financialReport";
import Password from "./password";
import Profile_Setting from "./profile";
import Security from "./security";

import "./myAccount.css";

function MayAccount(props) {
  return (
    
    <div className="my-account">
      <div className="settings d-flex wallet-details">
        <Container>
          <div>
            {/* <DisplayImage firstName="Taimoor" lastName="Shahid" email="taimoor.shahid@arhamsoft.org" buttonText="Save" /> */}
            <h2>Account</h2>
            <div className="profile-settings">
              <div className="tabs-section">

                {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <div className="tabs-header mb-0">
                    <Nav variant="pills" >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          Profile
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Password
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Email
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="forth">
                          Security
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth">
                          Deleting Account
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="sixth">
                          Address Book
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Profile_Setting />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Password />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Email />
                    </Tab.Pane>
                    <Tab.Pane eventKey="forth">
                      <Security />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <DeletingAccount />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <AddressBook />
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <FinancialReport />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container> */}
                <Profile_Setting />
                {/* <Email />
                <Password /> */}
                {/* <Security /> */}
                <DeletingAccount />
                {/* <AddressBook /> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default MayAccount;
