import React, { useState, useEffect } from "react";
import MainBanner from "../main-banner/main.banner";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "../../assets/assets";

import "./terms-conditions.css";

const TermsConditions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <MainBanner />
      <div className="terms-conditions">
        <Container>
          <Row>
            <Col md={12}>
              <Row className="flex-lg-row-reverse">
                <Col lg={6}>
                  <div className="terms-img text-center">
                    <img
                      className="img-fluid"
                      src={Images.termsConditions}
                      alt=""
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="terms-data">
                    <p>
                      <a class="text-theme" target="_blank" href="">
                        Staking FIXM
                      </a>{" "}
                      (hereinafter referred to as “Staking”) is owned and
                      operated in Pakistan by ArhamSoft (Pvt) Ltd. (hereinafter
                      referred to as ‘Company’). Your use of the website{" "}
                      <a class="text-theme" target="_blank" href="">
                        https://staking-stream.arhamsoft.org/
                      </a>{" "}
                      (hereinafter referred to as ‘Site’) and register for
                      participation in the Mathlete contests (hereinafter
                      referred to as ‘Campaigns’) are subjected to the below
                      Terms and Conditions.
                    </p>
                    <p>
                      When you opt to use this Site, these Terms and Conditions
                      shall apply in their entirety under all events and
                      circumstances. Please read these terms and conditions
                      carefully. If you do not agree to any of these terms you
                      should immediately stop using the Site. In addition to
                      these Terms and Conditions, your all activities on the
                      Site are strictly subjected to any additional rules and
                      regulations which shall apply from time to time to the
                      activities on the Site, including, but not limited to the
                      ‘General Rules’, ‘Privacy Policy’, ‘Fair Usage Policy’,
                      ‘Campaigns Rules’ (together the "Additional Rules") in
                      each case as updated from time to time. Any employees,
                      agents, contractors or other persons directly or
                      indirectly associated with the Company, including their
                      relatives, are not eligible to participate in the contests
                      associated with Staking FIXM.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <span class="text-emphasized d-block">
                    Our Right to Modify the Site
                  </span>
                  <p>
                    Anyone who uses our Services needs to agree with our terms
                    of using his Personal Information in relation to this
                    policy. Whatever information we collect from our Site
                    visitors, we use it for providing the improved Services and
                    will not disclose this information to any third-party except
                    as described in this document.
                  </p>
                  <p>
                    Staking FIXM (A Project of ArhamSoft) reserves the right to
                    suspend, modify, remove or add to the Site any content,
                    contests, quiz or any other activity in its sole discretion
                    with immediate effect and without notice. In that case, the
                    Company shall not be liable for any loss or failure suffered
                    by you resulting from any changes made and you shall have no
                    claims against the Company in this regard.
                  </p>
                  <span class="text-emphasized d-block">
                    Acceptance of Terms &amp; Conditions
                  </span>
                  <p>
                    If you do not agree to any of the provisions of these Terms
                    &amp; Conditions you should immediately stop using the Site
                    and participate in Mathlete Campaigns. We reserve the right
                    to amend, modify, update and change any of these Terms and
                    Conditions (including each of the Additional Rules) from
                    time to time without any notice to you. Any modified version
                    of these Terms and Conditions will take effect immediately
                    upon publishing on the Site and your continued use of the
                    Site after the aforementioned modifications will be deemed
                    to constitute your acceptance of the changes to these Terms
                    &amp; Conditions. It remains your responsibility to ensure
                    that you are aware of the correct and current version of
                    these Terms &amp; Conditions and we advise you to check for
                    updates on a regular basis.
                  </p>
                  <span class="text-emphasized d-block">
                    Copyrights And Trademarks:
                  </span>
                  <p>
                    All content published on the Site, including text, questions
                    (with their answers), graphics, user database, logos,
                    artwork, images, button icons, audio clips, product
                    photographs, digital downloads, data compilations, and
                    software, is the property of the Company or partner brands
                    and entities of the Company whose products or services are
                    displayed/mentioned on the Site in the form of prizes or
                    other promotions. All content on the Site is the exclusive
                    property of the Company and is protected by the Pakistani
                    and international copyright laws. All software used on the
                    Site is the sole and exclusive property of the Company and
                    is protected by the Pakistani and international copyright
                    laws.
                  </p>
                  <p>
                    <span class="text-bold">
                      Staking FIXM (A Project of ArhamSoft)
                    </span>
                    ,{" "}
                    <a class="text-theme" target="_blank" href="">
                      https://staking-stream.arhamsoft.org/
                    </a>{" "}
                    and its logo design, artwork and other graphics, page
                    headers, button icons, scripts, and service names used on
                    the Site (which are not copyrights of third parties
                    sponsoring the prizes or publishing ads on the Site) are
                    trademarks, or registered trademarks of the Company.
                  </p>
                  <p>
                    The copyrighted material or trademarks of the Company may
                    not be used in connection with any product or service that
                    does not belong to the Company, in any manner that is likely
                    to cause confusion among the Site users, or in any manner
                    that disparages or discredits the Company. All other
                    trademarks or copyrighted material not owned by the Company
                    that appear on the Site are the property of their respective
                    owners, who may or may not be affiliated with, connected to,
                    or sponsors of the Company.
                  </p>
                  <span class="text-emphasized d-block">Patents:</span>
                  <p>
                    One or more patents owned by the Company apply to the Site,
                    software of the Site, and the features, functionalities,
                    quizzes, questions, and services accessible via the Site.
                  </p>
                  <span class="text-emphasized d-block">
                    Electronic Communications:
                  </span>
                  <p>
                    When you visit the Site or send any email to us, you are
                    communicating with us electronically. We will consider it as
                    your consent to receive electronically generated messages
                    from us. We will either communicate our messages to you via
                    email or by publishing important notices on the Site. As a
                    Site user, you agree that all communications, notices,
                    agreements and disclosures made electronically or digitally
                    satisfy any legal parameters that all such communications be
                    in writing.
                  </p>
                  <span class="text-emphasized d-block">
                    License And Site Access:
                  </span>
                  <p>
                    The Company hereby grants you a non-exclusive,
                    non-transferable, non-commercial and non-sub-licensable
                    license to access the Site and all content and campaigns
                    provided on the Site, the copyright and all other
                    intellectual property rights therein, strictly in accordance
                    with these Terms &amp; Conditions. The Site is for internet
                    use only and you MUST NOT download or install any portion of
                    the Site, including user database, pictures, images, page
                    headers, logos, icons and other artwork, on a hard disk or
                    other storage device and MUST NOT make back-up copies of any
                    content of the Site. The Site’s software code, structure and
                    organization are protected by intellectual property rights.
                    You MUST NOT copy, redistribute, publish, reverse engineer,
                    decompile, disassemble, modify, translate or make any
                    attempt to access the source code of the Site’s software to
                    create derivatives of the source code, or otherwise. Any
                    such violation by you shall entitle us to invoke legal
                    proceedings against you and file a complaint before
                    appropriate legal authorities.
                  </p>
                  <p>
                    You will be solely liable for any damage, costs or expenses
                    arising out of or in connection with the commission of the
                    above prohibited activities. You shall notify the Company
                    immediately upon becoming aware of the commission by any
                    person of any of the above prohibited activities and shall
                    provide the Company with reasonable support for any
                    investigations it may conduct based on your provided
                    information.
                  </p>
                  <div class="dividers">
                    <span class="divider">&nbsp;</span>
                  </div>
                  <span class="text-emphasized d-block">
                    Your Representations &amp; Undertakings
                  </span>
                  <span class="text-theme d-block mb-4">
                    In consideration of the license granted to you hereby to use
                    the Site, you represent, warrant, covenant and agree that:
                  </span>
                  <ul class="list-unstyled content-ordered-list">
                    <li>
                      You are of sound mind and capable of taking responsibility
                      for your own actions and conduct while using the Site.
                    </li>
                    <li>
                      All details provided by you to the Company either during
                      the registration process on the Site or at any time
                      thereafter are correct, true, current, complete and match
                      the name(s) on your passport, national identity card,
                      student card, driving license or other official document
                      of identification. You need to inform us immediately about
                      any changes made to your previously provided details in
                      this regard. From time to time, you may be requested to
                      provide us with certain documents to verify the details
                      you have provided to us on the Site including but not
                      limited to your complete name, date of birth, address and
                      phone number etc. Depending on the outcome of these
                      verification checks you may or may not be permitted to
                      further use the Site. Should any of the information that
                      you provide to us be untrue, inaccurate, misleading or
                      otherwise incomplete, you will be in breach of these Terms
                      &amp; Conditions and we reserve the right to terminate
                      your account immediately and prevent you from using the
                      Site, in addition to any other action that we may choose
                      to take.
                    </li>
                    <li>
                      Your account on the Site is solely for your use and
                      benefit. You shall not allow any third party (including a
                      relative, friend, or teacher) to use your account,
                      password or identity to access or use the Site. The sole
                      responsibility of any activity performed on the Site using
                      your registered account (be it you or any third party)
                      lies with you. It is highly advisable that you should not
                      share or disclose your username and password with any
                      other individual and make sure that no one can access your
                      account. You shall inform us immediately if you suspect
                      that your account is being used or misused by a third
                      party or any third party has access to your account so
                      that we can investigate the matter. For this, we will need
                      your cooperation and you shall abide by providing us all
                      the support and information we need in the due process.
                    </li>
                    <li>
                      You have verified and determined that your use of the Site
                      does not violate any laws or regulations of any
                      jurisdiction that applies to you.
                    </li>
                    <li>
                      You fully understand the methods, rules and procedures of
                      the Site including Additional Rules. You will not commit
                      any acts or display any conduct that damages the
                      reputation of the Site or the Company.
                    </li>
                    <li>
                      You acknowledge that through registration on the Site you
                      have to provide us with certain personal details about
                      yourself (including your address, phone number, and date
                      of birth etc.). We understand and shall diligently handle
                      your personal information stored in the Site’s database,
                      and shall not disclose it otherwise to third parties or
                      otherwise except as stated in our Privacy Policy. We urge
                      you to read the Privacy Policy (below) to ensure that you
                      agree with our policies in relation to how your
                      information is handled.
                    </li>
                    <li>
                      You shall use the Site in complete accordance with these
                      Terms &amp; Conditions and each of the Additional Rules,
                      as amended from time to time, and you shall abide by all
                      of the rules and instructions for participating in
                      Campaigns.
                    </li>
                    <li>
                      You are solely responsible for recording, paying and
                      accounting to any relevant governmental, taxation or other
                      authority for any tax or other levies that may be payable
                      as a result of winning a prize on the Site.
                    </li>
                    <li>
                      You are solely responsible for any telecommunications
                      networks and internet access services and other consents
                      and permissions required in connection with your use of
                      the Site.
                    </li>
                    <li>
                      You shall use the Site only in good faith towards the
                      Company and other players using the Site. In the event
                      that we deem that you have been using the Site in bad
                      faith we shall have the right to terminate your account
                      and we shall be entitled to forfeit, cancel or stop any
                      prizes you won. You hereby expressly waive any future
                      claims against us in such regard.
                    </li>
                    <li>
                      You shall not break into, access or attempt to break into
                      or access or otherwise circumvent such security measures.
                      If we believe, in our sole discretion, that you are in
                      breach of this clause, we may terminate your access to the
                      Site immediately and/or have your account blocked, and we
                      may inform the relevant law enforcement agencies of your
                      breach of this clause.
                    </li>
                    <li>
                      Artificial Intelligence - Robots: You are not allowed to
                      use any software program which, in our opinion, is endowed
                      with artificial intelligence ("AI Software") in connection
                      with your use of the Site. We constantly review the use of
                      the Site in order to detect the use of AI Software and in
                      the event that we deem it has been used we reserve the
                      right to take any action we see fit, including immediately
                      blocking access to the Site, terminating such user's
                      account and detaining any prizes won. We also reserve the
                      right to file complaints with relevant law enforcement and
                      prosecution agencies.
                    </li>
                    <li>
                      We take no responsibility for any third-party access to
                      your account and under no circumstances shall we be liable
                      for any losses incurred by you as a result of misuse of
                      your password by any person or for any unauthorized access
                      to your account and all transactions where your username
                      and password have been entered correctly will be
                      considered valid, whether or not authorized by you.
                    </li>
                    <li>
                      We have no obligation to check whether users are using the
                      Site in accordance with these Terms &amp; Conditions or
                      the Additional Rules, as updated from time to time. We
                      may, at our sole discretion, decide to take appropriate
                      action against any person we suspect of engaging in any
                      unlawful behavior or otherwise violating any of these
                      Terms &amp; Conditions, but are under no obligation to do
                      so.
                    </li>
                    <li>
                      We have no obligation to maintain account names or
                      passwords. If you misplace, forget or lose your account
                      name or password because of anything, we shall not be
                      liable. However, in such a case you are advised to reach
                      through the ‘Contact Us’ page on the Site and we will look
                      into the matter and will help you out.
                    </li>
                  </ul>
                  <div class="dividers mb-0">
                    <span class="divider">&nbsp;</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TermsConditions;
