const ErrorMiddleware = (interceptors) => (store) => (next) => (action) => {
  //console.log("store", interceptors, store, action);
  if (action?.payload?.user404) {
    localStorage.clear();
    window.location.href = "/";
  }
  next(action);
};

const interceptors = [
  { type: "404Error", handler: () => console.log("hi saqib") },
];

export const errorMiddleware = ErrorMiddleware(interceptors);
