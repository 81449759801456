import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import {
  CREATE_TICKET,
  GET_TICKET,
  DELETE_TICKET,
  BEFORE_TICKET,
  GET_ERRORS,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";
export const beforeTicket = () => {
  return {
    type: BEFORE_TICKET,
  };
};
export const getTickets =
  (qs = "", toastCheck = true) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}contact/list`;
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // if (!qs) {
          //   if (toastCheck) {
          //     toas.success(data.message);
          //   }
          // }
          dispatch({
            type: GET_TICKET,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
export const deleteTicket = (Id) => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.url}contact/remove/${Id}`;
  fetch(url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: DELETE_TICKET,
          payload: data.data,
        });
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const createTicketReq = (body) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}contact/submit`;
  let headerstemp = {
    "Content-Type": "application/json",
    Authorization: ENV.Authorization,
    "x-auth-token": ENV.x_auth_token,
  };

  if (localStorage.getItem("accessToken")) {
    headerstemp = {
      "Content-Type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken"),
    };
  }
  fetch(url, {
    method: "POST",
    headers: headerstemp,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: CREATE_TICKET,
          payload: data.data,
        });
      } else {
        ENV.swlConfig(data.message,data.success)
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
