import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets';

import './choose-us.css';

const ChooseUs = () => {

    const chooseReason = [
        { id: 1, head: "Trade Freely", image: Images.Graph, description: "Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue eliteed srf." },
        { id: 2, head: "High Speed", image: Images.Rocket, description: "Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue eliteed srf." },
        { id: 3, head: "Referral Bonus", image: Images.Gift, description: "Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue eliteed srf." },
        { id: 4, head: "Easy to Use", image: Images.PayPerClick, description: "Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue eliteed srf." }
    ]

    return (
        <div className='choose-us'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='choose-head text-center'>
                            <span className='small-head'>WHY CHOOSE US</span>
                            <h2>Vestibulum condimentum</h2>
                            <p>Fusce at nisi eget dolor rhoncus facilisis. Mauris ante nisl, consectetur et luctus et, porta ut dolor. Curabitur ultricies ultrices nulla. Morbi blandit nec est vitae dictum. Etiam vel consectetur diam. Maecenas vitae egestas dolor.</p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Row>
                            {chooseReason.map((i) =>
                                <Col xl={3} md={6}>
                                    <div className='choose-data' key={i.id}>
                                        <div className='lottie-cover d-flex justify-content-center align-items-center'>
                                            <img className='img-fluid' alt='' src={i.image} />
                                        </div>
                                        <h2>{i.head}</h2>
                                        <p>{i.description}</p>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChooseUs