import { BEFORE_HISTORY, GET_HISTORY,GET_HISTORY_LIST, GET_ERRORS } from "../../redux/types";
import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";

export const beforeSubscribe = () => {
  return {
    type: BEFORE_HISTORY,
  };
};

export const getSubscribedPackage = () => (dispatch) => {
  dispatch(emptyError());

  let url = `${ENV.url}subscription/get`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        if (data.successdata) {
          dispatch({
            type: GET_HISTORY,
           payload: data.data[0],
          });
        } else {
          dispatch({
            type: GET_HISTORY,
            payload: data.data,
          });
        }
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const getSubscribedPackageList = () => (dispatch) => {
  dispatch(emptyError());

  let url = `${ENV.url}subscription/list`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        if (data.successdata) {
          dispatch({
            type: GET_HISTORY_LIST,
           payload: data.data,
          });
        } else {
          dispatch({
            type: GET_HISTORY_LIST,
            payload: data.data,
          });
        }
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
