import React, { useId, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { Container, Nav, Tab, Table } from "react-bootstrap";
import { getSubscribedPackageList, beforeSubscribe } from "./history.action";
import "./history.css";

const History = (props) => {
  const [loader, setLoader] = useState(true);
  const [subPackage, setSubPackage] = useState(null);
  useEffect(() => {
    props.getSubscribedPackageList(); ///get API
  }, []);

  useEffect(() => {
    if (props.history.subPkgAuthList) {
      const data2 = props.history.subPkgList;
      setSubPackage(data2);
      //console.log(data2)
      props.beforeSubscribe();
      setLoader(false);
    }
  }, [props.history.subPkgAuthList]);

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="history-section">
          <div className="history-details d-flex">
            <Container>
              <div>
                <h2>Subscribed Packages</h2>
                <div className="history-main">
                  <div className="tabs-section">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="tabs-body-table">
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th className="text-center">Package Name</th>
                                    <th className="text-center">Subscribe Date</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Profit</th>
                                    <th className="text-center">Duration of Package</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {subPackage && subPackage.length ? (
                                    subPackage.map((subPackage, index) => {
                                      return (
                                        <tr>
                                          <td className="text-center">{subPackage?.title}</td>
                                          <td className="text-center">
                                            <Moment format="YYYY/MM/DD">
                                              {subPackage?.createdAt}
                                            </Moment>
                                          </td>
                                          <td className="text-center">
                                              {subPackage.stakeToken}
                                          </td>
                                          <td className="text-center">{subPackage?.profit} %</td>
                                          <td className="text-center">{subPackage?.duration} Months</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        <div
                                          className="alert alert-info"
                                          role="alert"
                                        >
                                          No Package Found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="second">
                                            < div className="tabs-body-table" >
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Amount Sent</th>
                                                                <th>Date</th>
                                                                <th>Amount credited</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                history.map((i) =>
                                                                    <tr>
                                                                        <td>{i.amountSent}</td>
                                                                        <td>{i.date}</td>
                                                                        <td>{i.amountCredited}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            < div className="tabs-body-table" >
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Amount Sent</th>
                                                                <th>Date</th>
                                                                <th>Amount credited</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                history.map((i) =>
                                                                    <tr>
                                                                        <td>{i.amountSent}</td>
                                                                        <td>{i.date}</td>
                                                                        <td>{i.amountCredited}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forth">
                                            < div className="tabs-body-table" >
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Amount Sent</th>
                                                                <th>Date</th>
                                                                <th>Amount credited</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                history.map((i) =>
                                                                    <tr>
                                                                        <td>{i.amountSent}</td>
                                                                        <td>{i.date}</td>
                                                                        <td>{i.amountCredited}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            < div className="tabs-body-table" >
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Amount Sent</th>
                                                                <th>Date</th>
                                                                <th>Amount credited</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                history.map((i) =>
                                                                    <tr>
                                                                        <td>{i.amountSent}</td>
                                                                        <td>{i.date}</td>
                                                                        <td>{i.amountCredited}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="sixth">
                                            < div className="tabs-body-table" >
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Amount Sent</th>
                                                                <th>Date</th>
                                                                <th>Amount credited</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                history.map((i) =>
                                                                    <tr>
                                                                        <td>{i.amountSent}</td>
                                                                        <td>{i.date}</td>
                                                                        <td>{i.amountCredited}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  history: state.history,
  error: state.error,
});

export default connect(mapStateToProps, {
  getSubscribedPackageList,
  beforeSubscribe,
})(History);
