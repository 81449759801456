import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import {
  GET_ERRORS,
  GET_SETINGS_LINKS,
  BEFORE_SETTING_LINKS,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";

export const beforeSetting = () => {
  return {
    type: BEFORE_SETTING_LINKS,
  };
};

export const getSettings = () => (dispatch) => {
  dispatch(emptyError());

  let url = `${ENV.url}settings/get`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type:GET_SETINGS_LINKS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) ENV.swlConfigAlert(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
