import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import connectors from '../components/wallet/connector';
import LowerFooter from "../components/footer/lowerFooter";
import Sidebar from "../components/sidebar/sidebar";
import DashboardHeader from "../components/Header/dashboardHeader";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// import { isFocusable } from "@testing-library/user-event/dist/types/utils";
const Layout2 = (props) => {
    const { account, activate, library } = useWeb3React();
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            const connector = connectors[provider].provider;
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                })
                .catch((error) => {
                    //error
                });
        }
    }, [])
    useEffect(() => {
        //console.log("account37", account)
        if (account && localStorage.getItem('isSet')) {
           // console.log("layout setting connected Address")
            localStorage.setItem('connectedAddress', account);
        }
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])



    return (
        <>
            <div className={isActive ? 'show complete-content' : 'complete-content'}>
                <FontAwesomeIcon className="menu-icon" onClick={toggleClass} icon={faBars} />
                <Sidebar isActive={isActive} />
                <div className="right-content position-relative">
                    <DashboardHeader />
                    {props.children}
                    <LowerFooter />
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        wallet: state.wallet,
    }
}
export default connect(mapStateToProps, null)(Layout2);
