import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Swal from "sweetalert2";
import {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  beforeSentEmail,
} from "../emailSecurity/emailSecurity.action";
import {
  Container,
  Button,
  Modal,
  Table,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { Images } from "../../assets/assets";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./withdrawl.css";
import Moment from "react-moment";
import validator from "validator";
import { connect } from "react-redux";
import CheckSecurity from "../qrCode/checkSecurity";
import {
  createWithdrawal,
  getWithdrawal,
  beforeWithdrawal,
} from "./withdrawal.action";

import { ENV } from "./../../config/config";
import { calculateAvailableProfitWeb3 } from "../../utils/web3";
import { AbiCoder } from "ethers/lib/utils";
const Withdrawl = (props) => {
  const { uId } = ENV.getUserKeys("uId");
  const [showOne, setShowOne] = useState(false);
  const [Page, setPage] = useState(1);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);
  const [waitingFlag, setWaitingFlag] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);
  const [USDTCoversationRate, setUSDTCoversationRate] = useState(1);
  const [showThree, setShowThree] = useState(false);
  const handleCloseThree = () => setShowThree(false);
  const handleShowThree = () => setShowThree(true);
  let counter = 0;
  const [selectedOption, setSelectedOption] = useState({
    value: "enterType",
    label: "Select Type",
  });
  const options = [
    { value: 1, label: "ETH" },
    { value: 2, label: "FIXM" },
  ];
  const options2 = [
    { value: 2, label: "FIXM" },
    { value: 3, label: "USDT" },
  ];
  const [loader, setLoader] = useState(true);
  const [withdrawals, setWithdrawals] = useState(null);
  const [settings, setSettings] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [renderSecurity, setRednerSecuirty] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [user, setUser] = useState(null);
  const [type, setType] = useState("");
  const [withdrawalObj, setwithdrawalObj] = useState({
    subId: "",
    coin: 0,
    amount: 0,
    withdrawalAddress: "",
    type: "",
  });
  const [errorMsgs, seterrorMsgs] = useState({
    flag: false,
    subId: "",
    coin: 0,
    amount: 0,
    withdrawalAddress: "",
    withdrawalAddress1: "",
  });

  //add here
  const [amountAvailable, setAmountAvailable] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceButton, setBalanceButton] = useState(false);
  const [balanceInFIXM, setBalanceFIXM] = useState(0);
  const [balanceInUSDT, setBalanceUSDT] = useState(0);

  useEffect(() => {
    if (showOne == false) {
      seterrorMsgs("");
    }
  }, [showOne]);

  useEffect(() => {
    if (showTwo == false) {
      setAmountAvailable(0)
    }
  }, [showTwo]);
  //here

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const blance = await calculateAvailableProfitWeb3(uId);
      if (blance) {
        const blance1 = blance.toString();
        let a= blance1.slice(0, 8)
        setBalance(parseFloat(a));
      }
      props.getWithdrawal();
         };
    
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [props.withdrawal.upsertWithdrawalAuth]);


  useEffect(() => {
    if (props.withdrawal.getWithdrawalAuth) {
      const { withdrawals, pagination, user, subscription, settings,pendingReqAmount } = props.withdrawal;
      setSettings(settings);
      let tempBalance=balance
      if(pendingReqAmount.totalAmountPending && balance > 0)
      {
       let pendingAmount = pendingReqAmount.totalAmountPending
       console.log(tempBalance,"=",tempBalance,"-",pendingAmount)
       tempBalance = tempBalance - pendingAmount;
       console.log("After",tempBalance)
       if(tempBalance > 0)
       {
        setBalanceButton(true)
       }
       const tempBalanceS = tempBalance.toString();
       let a= tempBalanceS.slice(0, 8)
       setBalance(parseFloat(a));
       setBalanceFIXM(parseFloat(tempBalance));
       setBalanceUSDT((parseFloat(tempBalance))/settings.USDT);
      }
      else
      {
        console.log("case with no pending Request")
        if(balance>0)
        {
          setBalanceButton(true)
        }
        setBalanceFIXM(parseFloat(balance));       
        setBalanceUSDT((parseFloat(balance))/settings.USDT);
      }
      
  
      setWithdrawals(withdrawals);
      setPagination(pagination);
      setSubscription(subscription);
     
      setUser(user);
      setLoader(false);
      props.beforeWithdrawal();
    }
  }, [props.withdrawal.getWithdrawalAuth]);

  function onPageChange(page) {
    setPage(page);
    const qs = ENV.objectToQueryString({
      page,
      pageSize: 10,
    });
    props.getWithdrawal(qs);
  }

  function objSetter(e) {
    if (e.target.name == "withdrawalAddress") {
      seterrorMsgs({ ...errorMsgs, withdrawalAddress1: "" });
    }
    seterrorMsgs({ ...errorMsgs, [e.target.name]: "" });
    setwithdrawalObj({ ...withdrawalObj, [e.target.name]: e.target.value });
  }
  function numberSetter(e) {
    let num = Number(e.target.value);
    seterrorMsgs({ ...errorMsgs, amount: 0 });
    setwithdrawalObj({ ...withdrawalObj, amount: num });
  }
  function dorpdownSetter(val)  {
    if(val == 3)//for USDT
    { 
      setAmountAvailable(balanceInUSDT);
      }
    else if(val == 2)// For FIXM
    { 
      setAmountAvailable(balanceInFIXM);
    }
    
    seterrorMsgs({ ...errorMsgs, coin: 0 });
    setwithdrawalObj({ ...withdrawalObj, coin: val });
  }
  function withdrawalCreate(e) {
    e.preventDefault();
    let tempFlag = true;
    let temp2 = true;
    let errordata = {};
    if (validator.isEmpty(withdrawalObj.withdrawalAddress)) {
      errordata.withdrawalAddress = "Withdrawal Address is missing";
      tempFlag = false;
      temp2 = false;
    }
    try {
      const web3 = new Web3();
      web3.utils.toChecksumAddress(withdrawalObj.withdrawalAddress);
      // return true
    } catch {
      if (temp2) {
        errordata.withdrawalAddress1 = "withdrawal Address is incorrect";
        tempFlag = false;
      }
    }
    if (type == "profit") {
      if (!parseFloat(withdrawalObj.amount)) {
        errordata.amount = "amount is missing";
        tempFlag = false;
      }
      if (withdrawalObj.amount < 0) {
        errordata.amount = "amount is invalid";
        tempFlag = false;
      }
      if (withdrawalObj.amount > balance) {
        errordata.amount = "not enough amount";
        tempFlag = false;
      }
    }
    if (type == "package") {
      withdrawalObj.coin = 2;// always 2 as because there is one token FIXM
      if (validator.isEmpty(withdrawalObj.subId)) {
        errordata.subId = "No package selected";
        tempFlag = false;
      }
    }
    if (!parseFloat(withdrawalObj.coin)) {
      errordata.coin = "coin is missing";
      tempFlag = false;
    }

    if (tempFlag) {
      props.sentSecurityemail();
      //setRednerSecuirty(true);
      setShowOne(false);
      setShowTwo(false);
    } else {
      errordata.flag = true;
      seterrorMsgs(errordata);
    }
  }

  useEffect(() => {
    if (props.secretKey.keyVerfiedAuth && waitingFlag) {
      setVerified(true);
    }
    props.beforeSecretKey();
    setWaitingFlag(false);
  }, [props.secretKey.keyVerfied]);

  useEffect(() => {
    if (props.secretKey.emailSentAuth) {
      props.beforeSentEmail();
      Swal.fire({
        text: "Email Sent Succesfully!! Enter 8 digit Secret Code",
        input: "text",
      }).then((result) => {
        if (result.value) {
          const data = {};
          data.secretkey = result.value;
          data.type = "userSettingSecretkey";

          props.verifySecretKey(data);
          setWaitingFlag(true);
        
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your Action Is Unsaved Try Later",
          });
        }
      });
    }
  }, [props.secretKey.emailSentAuth]);

  const setVerified = (value) => {
    if (value) {
      handleCloseOne();
      let obj = {};
      (obj.type = type),
        (obj.title = withdrawalObj.title),
        (obj.coin = withdrawalObj.coin),
        (obj.withdrawalAddress = withdrawalObj.withdrawalAddress);
      if (type == "profit") {
        obj.amount = Number(withdrawalObj.amount);
        if(obj.coin==2)
        {
          obj.FIXMamount = Number(withdrawalObj.amount);
        }
        else
        {
          obj.FIXMamount = (Number(withdrawalObj.amount)*settings.USDT);
        }
      } else {
        obj.subId = withdrawalObj.subId;
        obj.amount = Number(withdrawalObj.amount);
      }

      props.createWithdrawal(obj);
      setLoader(true);
      seterrorMsgs("");
      setwithdrawalObj({
        subId: "",
        coin: 0,
        amount: 0,
        withdrawalAddress: "",
        type: "",
      });
    }
    setRednerSecuirty(false);
  };
  function dropdownValue(e) {
    let tempToken =0;
    if(e.target.value)
    {
    subscription.map((val, index) => {
      if(val._id == e.target.value) 
      {
       
        tempToken=val.stakeToken

      }
    })
    }
  
    seterrorMsgs({ errorMsgs, subId: "" });
    setwithdrawalObj({ ...withdrawalObj, subId: e.target.value ,amount:tempToken});
  }
  
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="withdrawal-section">
          
          <div className="withdrawal-details d-flex">
            <Container>
              <div>
                <h2>Withdrawal</h2>
                <div className="ticket-main">
                  <div className="total-balance">
                    
                    <p>
                      <strong>Total Balance : </strong>
                      <span className="balance">
                        {balance}{" "}FIXM
                      </span>
                    </p>
                  </div>
                  <div className="create-button text-end">
                    {balanceButton ? (
                      <Button
                        className="purple-border-btn"
                        onClick={() => {
                          handleShowTwo();
                          setType("profit");
                        }}
                      >
                        <span>Withdraw Profit</span>
                      </Button>
                    ) : (
                      <Button className="purple-border-btn" disabled>
                        <span>Withdraw Profit</span>
                      </Button>
                    )}
                    <Modal
                      show={showTwo}
                      onHide={handleCloseTwo}
                      size="lg"
                      className="address-book-create"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Withdrawal Profit</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form
                          onSubmit={(e) => {
                            withdrawalCreate(e);
                          }}
                        >
                         
                          <Row>
                            <Col md={12}>
                              <div className="input-section">
                                <label>
                                  {" "}
                                  Coin <span className="red">*</span>
                                </label>
                                <Select
                                  options={options2}
                                  name="coin"
                                  placeholder={
                                    <span>
                                      <img src="" alt="" /> Select Coin
                                    </span>
                                  }
                                  isSearchable={false}
                                  classNamePrefix="select"
                                  onChange={(e) => {
                                    dorpdownSetter(e.value);
                                  }}
                                />
                                {errorMsgs.coin ? (
                                  <span style={{ color: "red" }}>
                                    select coin
                                  </span>
                                ) : (
                                  <span />
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <label>
                                Amount <span className="red">*</span>
                              </label>
                              <div className="input-section">
                                <div>
                                  <input
                                    type="number"
                                    step="0.001"
                                    placeholder="Enter Amount"
                                    name="amount"
                                    onChange={numberSetter}
                                    min="0"
                                    max={amountAvailable}
                                  />
                                  {errorMsgs.amount ? (
                                    <span style={{ color: "red" }}>
                                      {errorMsgs.amount}
                                    </span>
                                  ) : (
                                    <span />
                                  )}
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex justify-content-start pt-2 detail-1">
                                    Amount available =
                                    {parseFloat(amountAvailable)}
                                  </div>
                                  <div className="d-flex justify-content-end pt-2 detail-1">
                                    {" "}
                                    1% withdrawal fee{" "}
                                    {parseFloat(
                                      withdrawalObj.amount * 0.01
                                    ).toFixed(6)}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            
                          </Row>
                          <div className="input-section">
                            <label>
                              Wallet Address <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Withdrawal Address"
                              name="withdrawalAddress"
                              onChange={objSetter}
                            />
                            {errorMsgs?.withdrawalAddress1 ? (
                              <span style={{ color: "red" }}>
                                Withdrawal Address is not valid
                              </span>
                            ) : (
                              <span />
                            )}
                            {errorMsgs.withdrawalAddress ? (
                              <span style={{ color: "red" }}>
                                Withdrawal Address is required
                              </span>
                            ) : (
                              <span />
                            )}
                          </div>
                          <Row>
                            <Col md={12}>
                              <div className="d-flex align-items-center justify-content-end">
                                <Button
                                  className="transparent-btn"
                                  onClick={handleCloseTwo}
                                >
                                  <span>Close</span>
                                </Button>
                                <Button
                                  className="purple-border-btn ms-3"
                                  type="submit"
                                >
                                  <span>Submit</span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </Modal.Body>
                      <Modal.Footer></Modal.Footer>
                    </Modal>
                    {subscription?.length > 0 ? (
                      <Button
                        className="purple-border-btn ms-3"
                        onClick={() => {
                          handleShowOne();
                          setType("package");
                        }}
                      >
                        <span>Withdraw Staked Amount</span>
                      </Button>
                    ) : (
                      <Button className="purple-border-btn ms-3" disabled>
                        <span>Withdraw Staked Amount</span>
                      </Button>
                    )}
                    {/*create ticket modal */}
                    <Modal
                      show={showOne}
                      onHide={handleCloseOne}
                      size="lg"
                      className="address-book-create"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Withdrawal Staked Amount</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form
                          onSubmit={(e) => {
                            withdrawalCreate(e);
                          }}
                        >
                          <Col md={12}>
                            <div className="input-section">
                              <label>
                                {" "}
                                Coin <span className="red">*</span>
                              </label>
                              {/* <Select
                                options={options}
                                name="coin"
                                placeholder={
                                  <span>
                                    <img src="" alt="" /> Select Coin
                                  </span>
                                }
                                isSearchable={false}
                                classNamePrefix="select"
                                onChange={(e) => {
                                  dorpdownSetter(e.value);
                                }}
                              /> */}
                              <input
                              type="text"
                              placeholder="Withdrawal Address"
                              name="withdrawalAddress"
                              value={"FIXM"}
                              onChange={objSetter}
                            />
                              {errorMsgs.coin ? (
                                <span style={{ color: "red" }}>
                                  select coin
                                </span>
                              ) : (
                                <span />
                              )}
                            </div>
                          </Col>
                          <div className="input-section">
                            <label>
                              {" "}
                              Subscription <span className="red">*</span>
                            </label>
                            <div>
                              <select
                                className="form-control no-border"
                                name="statusFlag"
                                onChange={dropdownValue}
                                classNamePrefix="select"
                              >
                                <option value="">Select Package</option>
                                {subscription?.map((val, index) => {
                                   let flag = true;                                 
                                  counter = counter + 1;
                                  if (
                                    subscription[index]?.packages.title !=
                                    subscription[index - 1]?.packages.title &&
                                    index != 0
                                  ) {
                                    counter = 1;
                                  }
                                  return ( 
                                        <option value={val._id}>
                                          {val.packages?.title}&nbsp; {counter}
                                        </option>
                                  );
                                })}
                              </select>
                              {errorMsgs?.subId ? (
                                <span style={{ color: "red" }}>
                                  No package selected
                                </span>
                              ) : (
                                <span />
                              )}
                            </div>
                          </div>
                          <div className="input-section">
                            <label>
                              Wallet Address <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Withdrawal Address"
                              name="withdrawalAddress"
                              onChange={objSetter}
                            />
                            {errorMsgs?.withdrawalAddress1 ? (
                              <span style={{ color: "red" }}>
                                Withdrawal Address is not valid
                              </span>
                            ) : (
                              <span />
                            )}
                            {errorMsgs.withdrawalAddress ? (
                              <span style={{ color: "red" }}>
                                Withdrawal Address is required
                              </span>
                            ) : (
                              <span />
                            )}
                          </div>
                          <Row>
                            <Col md={12}>
                              <div className="d-flex align-items-center justify-content-end">
                                <Button
                                  className="transparent-btn"
                                  onClick={handleCloseOne}
                                >
                                  <span>Close</span>
                                </Button>
                                <Button
                                  className="purple-border-btn ms-3"
                                  type="submit"
                                >
                                  <span>Submit</span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </Modal.Body>
                      <Modal.Footer></Modal.Footer>
                    </Modal>
                  </div>
                  <div className="withdrawal-table">
                    <div className="tabs-body-table">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th className="text-center td-start">#</th>
                              {/* <th className="td-title text-center">Title</th> */}
                              <th className="td-amount text-center">
                                Withdrawal Amount
                              </th>
                              <th className="td-address text-center">
                                Withdrawal Address
                              </th>
                              {/* <th className="td-address text-center">
                                Withdrawal Profit
                              </th> */}
                              <th className="text-center td-status">Coin</th>
                              <th className="text-center td-status">Status</th>
                              <th className="text-center td-status">Reason</th>
                              <th className="td-date text-center">Date</th>
                              <th className="td-date text-center">Time</th>
                              <th className="td-date text-center">Transaction Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawals && withdrawals.length > 0 ? (
                              withdrawals.map((val, index) => (
                                <tr>
                                  <td className="text-center td-start">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>{pagination &&
                                    pagination.limit * pagination.page -
                                      pagination.limit +
                                      index +
                                      1}</div>
                                    </div>
                                  </td>
                                  <td className="td-amount">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>
                                      {val?.stakeToken ? (
                                            <>{parseFloat(val?.stakeToken).toFixed(2)}</>
                                          ) : (
                                            <>{parseFloat(val?.amount).toFixed(4)}</>
                                          )}
                                        
                                        &nbsp;
                                      </div>
                                    </div>
                                  </td>

                                  <td className="td-address">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="withdrawal-address">
                                        {val.withdrawalAddress}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-status">
                                  <div className="d-flex align-items-center justify-content-center main-row">
                                      <div className="withdrawal-address">
                                  {val?.coin === 1
                                    ? "ETH"
                                    : val?.coin === 2
                                    ? "FIXM"
                                    : val?.coin === 3
                                    ? "USDT"
                                    : "N/A"}
                                    </div>
                                    </div>
                                </td>

                                  <td className="td-status">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div
                                        className={`status-span ${val.class}`}
                                      >
                                        {val?.statusFlag == 0 ? "Pending" : ""}                                     
                                        {val?.statusFlag == 1 ? "Rejected" : ""}
                                        {val?.statusFlag == 2 ? "Approved" : ""}
                                        {val?.statusFlag == 3 ? "Failed" : ""}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-date">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>
                                        <div>
                                          {val?.reason ? (
                                            <>{val?.reason}</>
                                          ) : (
                                            <>-</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-date">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>
                                        {" "}
                                        <Moment format="YYYY-MM-DD">
                                          {val?.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-date">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>
                                        {" "}
                                        <Moment format="HH:mm:ss">
                                          {val?.createdAt}
                                        </Moment>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-date">
                                    <div className="d-flex align-items-center justify-content-center main-row">
                                      <div>
                                        <div>
                                          {val?.txHash ? (
                                            <a
                                            href={ENV.blockchainexplorerUrl+val?.txHash}
                                            target="_blank"
                                            className="linkedin"
                                          >
                                            <button className="red-border-btn">View</button>
                                          </a>
                                          ) : (
                                            <>-</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  <div
                                    className="alert alert-info"
                                    role="alert"
                                  >
                                    No withdrawals Found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        <div className="pb-4">
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={
                            Page > pagination.pages ? pagination.pages : Page
                          } // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  secretKey: state.secretKey,
  error: state.error,
  withdrawal: state.withdrawal,
});
export default connect(mapStateToProps, {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  createWithdrawal,
  getWithdrawal,
  beforeWithdrawal,
  beforeSentEmail,
})(Withdrawl);
