import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import { defaultRoutes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { getSettings, beforeSetting } from "./components/footer/footer.action";
import Layout1 from "./layouts/layout1";
import Layout2 from "./layouts/layout2";
import Layout3 from "./layouts/layout3";
import Layout4 from "./layouts/layout4";
import NotFound from "./components/not-found/not-found";

const App = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const settings = useSelector((state) => state.footer);
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState([...defaultRoutes]);
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  useEffect(() => {
    let newRoutes = [];
    let lastRoute = routes.slice(-1)[0];
    if (settings?.linkAuth && lastRoute.path == "/dashboard/withdrawal") {
      settings.soical_link.CMS.map((val, index) => {
        if (val.status) {
          let temp = {
            path: `/${val.slug}`,
            layout: Layout4,
            access: true,
            exact: true,
            component: () => (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cms-page"
                      dangerouslySetInnerHTML={{
                        __html: val.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          };
          newRoutes.push(temp);
        }
      });
      let temp1 = {
        path: "/*",
        layout: Layout3,
        access: true,
        exact: true,
        component: NotFound,
      };
      newRoutes.push(temp1);
      setRoutes([...routes, ...newRoutes]);
      dispatch(beforeSetting);
    }
  }, [settings?.linkAuth]);
  return (
    <Routes>
   
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            element={
              <route.layout {...props} title={route.title}>
                <route.component
                  {...props}
                  navigate={navigate}
                  params={params}
                />
              </route.layout>
            }
          />
        );
      })}
    </Routes>
  );
};
export default App;
