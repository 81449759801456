import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
import {
  KEY_VERIFIED,
  KEY_NOT_VERIFIED,
  BEFORE_KEY,
  BEFORE_EMAIL_SENT,
  EMAIL_SENT,
  RESET_NEW_EMAIL_FLAG,
  NEW_EMAIL_NOT_VERIFIED,
  NEW_EMAIL_VERIFIED,
} from "../../redux/types";
import { emptyError } from "../../redux/shared/error/error.action";
import { Toast } from "react-bootstrap";

export const sentSecurityemail = () => (dispatch) => {
  // dispatch(emptyError());
  const url = `${ENV.url}users/securityEmail`;
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token":
        ENV.getUserKeys("accessToken") &&
        ENV.getUserKeys("accessToken").accessToken
          ? ENV.getUserKeys("accessToken").accessToken
          : "",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: EMAIL_SENT,
        });
        // toast.success(data.message);
      } else {
        //toast.error(data.message);
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type:EMAIL_SENT,
          });
         
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        
      });
  };
export const verifySecretKey = (body = null,) => (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}users/matchSecurityCode`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : '',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: KEY_VERIFIED,
          });
        } else {
          dispatch({
            type: KEY_NOT_VERIFIED,
          });
          ENV.swlConfigAlert(data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const beforeSecretKey = () => {
  return {
    type: BEFORE_KEY,
  };
};

export const beforeSentEmail = () => {
  return {
    type: BEFORE_EMAIL_SENT,
  };
};

export const sentCodeOnNewEmail =
  (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}users/verifyNewEmailSentCode`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // toast.success(data.message);
        } else {
          ENV.swlConfigAlert(data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const verifyNewEmail =
  (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}users/matchNewEmailCode`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: NEW_EMAIL_VERIFIED,
          });
        } else {
          dispatch({
            type: NEW_EMAIL_NOT_VERIFIED,
          });
          ENV.swlConfigAlert(data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) ENV.swlConfigAlert(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };

export const resetNewEmailFlag = () => {
  return {
    type: RESET_NEW_EMAIL_FLAG,
  };
};
