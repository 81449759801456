import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/Header/Header";

const Layout1 = (props) => {
    return (
        <div className="position-relative">
            <Header />
            {props.children}
            <Footer />
        </div>
    );
}

export default Layout1;
