import React from 'react'

const TextInput = ({name, type, label, errorMsg, changeData, forTwo, id}) => {
    return (
        <>
            <div>
                <label for={forTwo}>{label}</label>
                <input id={id} type={type} name={name} onChange={(e) => changeData(name, e.target.value)}   />
            </div>
            {errorMsg && <p className="error position-absolute">{errorMsg}</p>}            
        </>
    )
}

export default TextInput