import {
  BEFORE_HISTORY,
  GET_HISTORY,
  GET_HISTORY_LIST,
} from "../../redux/types";

const initialState = {
  subPkg: null,
  subPkgList: null,
  subPkgAuth: false,
  subPkgAuthList: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case BEFORE_HISTORY: {
      return {
        subPkg: null,
        subPkgList: null,
        subPkgAuth: false,
        subPkgAuthList: false,
      };
    }
    case GET_HISTORY:
      return {
        ...state,
        subPkg: action.payload.packages,
        subPkgAuth: true,
      };
    case GET_HISTORY_LIST:
      return {
        ...state,
        subPkgList: action.payload,
        subPkgAuthList: true,
      };

    default:
      return {
        ...state,
      };
  }
}
