import React, { useState, useEffect } from "react";
import "./myAccount.css";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row } from "react-bootstrap";
import PasswordInput from "../signup/passwordInput";
import InputMask from "react-input-mask";
//import CheckSecurity from "../qrCode/checkSecurity";
import CheckEmailSecurity from "../emailSecurity/checkEmailSecurity";
import {
  sentSecurityemail,
  verifySecretKey,
  beforeSecretKey,
  beforeSentEmail,
  resetNewEmailFlag,
  verifyNewEmail,
  sentCodeOnNewEmail,
} from "../emailSecurity/emailSecurity.action";
import { connect,useDispatch } from "react-redux";
import {
  updateProfile,
  beforeUser,
  updatePassword,
  enablesSecurity,
  getSecurity,
  beforeSecurity,
} from "../../components/signup/user.action";

import {
  getupdateduser,
} from "../package-subscribe/subscribe.action";

import TextInput from "../textInput/textInput";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { ENV } from "../../config/config";

function Profile_Setting(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = ENV.getUserKeysLimited("_id email address");
  const [data, setData] = useState(null);
  const [verifedData, setVerifedData] = useState(null);
  const [renderSecurity, setRednerSecuirty] = useState(false);
  const [waitingFlag, setWaitingFlag] = useState(false);
  const [changePassFlag, setChangePassFlag] = useState(false);
  const [changeEmailFlag, setChangeEmailFlag] = useState(false);
  const [loader, setLoader] = useState(true);
  const [nameChange, setNameChange] = useState(false);
  const [err, setErr] = useState({});

  let activeEmail  = ENV.getUserKeys("email").email;
  let ActiveName = ENV.getUserKeys("firstName").firstName;


  let x=activeEmail;

  const validateEmail = (email) => {
    email.toString();
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    if (props.user.updateProfileAuth) {
      //props.beforeUser();
      dispatch(getupdateduser())
      activeEmail=x
    }
  }, [props.user.updateProfileAuth]);


  //code to update the userseeting/notupdate final call in case or email and pass change
  //this is final call reset redux
  useEffect(() => {
    if (props.secretKey.keyVerfiedAuth && waitingFlag) {
      x=data?.newEmail;
      
      props.updateProfile(verifedData);
      
      
    }
    setWaitingFlag(false);
    props.beforeSecretKey();
    initalizeState();
    setLoader(false);
   
  }, [props.secretKey.keyVerfied]);
  //loading time security call
  useEffect(() => {
    {
      // let tempData = data;
      // tempData["currEmail"] = activeEmail;
      // tempData["firstName"] = ActiveName;
      setData({ ...data, currEmail: activeEmail, firstName: ActiveName });
      setLoader(false);
      //props.beforeSecurity();
    }
  }, []);

  useEffect(() => {
    if (props.error?.error) {
      setLoader(false);
    }
  }, [props.error?.error]);

  //this code is run when we sucessfully verified the Newemail in case of emailchnage
  //1..send Email
  //2..verify the code and update redux for final call
  useEffect(() => {
    if (props.secretKey.newNewVerified) {
      props.sentSecurityemail();
      Swal.fire({
        text: "Email Sent Succesfully!! Enter 8 digit Secret Code",
        input: "text",
      }).then((result) => {
        if (result.value) {
          const data = {};
          data.secretkey = result.value;
          data.type = "userSettingSecretkey";
          props.verifySecretKey(data);
          setWaitingFlag(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your Action Is Unsaved Try Later",
          });
        }
      });
    }
    props.resetNewEmailFlag();
  }, [props.secretKey.newEmailcheck]);

  //this code will run when we chnage email
  //1..send Email on NewEmail
  //2..verify the code and update redux
  const newEmailVerification = () => {
    const emailData = {};
    emailData.newEmail = data.newEmail;
    props.sentCodeOnNewEmail(emailData);
    Swal.fire({
      text: "Email sent on New Account Enter 8 digit code",
      input: "text",
    }).then((result) => {
      if (result.value) {
        const newData = {};
        newData.emailSecretKey = result.value;
        props.verifyNewEmail(newData);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your Action Is Unsaved Try Later",
        });
      }
    });
  };
  const changeData = (name, value) => {
    let tempData = data;
    tempData[name] = value;
    setData({ ...data, ...tempData });
  };
  const initalizeState = () => {
    if(data?.currEmail)
    delete data.currEmail;
    if(data?.newEmail)
    delete data.newEmail;
    if(data?.confirmEmail)
    delete data.confirmEmail;
    if(data?.newPwd)
    delete data.newPwd;
    if(data?.currentPwd)
    delete data.currentPwd;
    if(data?.confirmPwd)
    delete data.confirmPwd;
    setRednerSecuirty(false);
    setChangePassFlag(false);
    setChangeEmailFlag(false);
    setNameChange(false);
    setErr({});
  };

//checkvalidations on forms
  const checkValidaiton = () => {
    setLoader(true);
    let flag = true;
    let error = {};
    let emailFlag = false;
    let passFlag = false;

    if (data?.firstName) {
      if (data?.newEmail || data?.confirmEmail) {
        
        {
          if (data?.confirmEmail != data?.newEmail) {
            flag = false;
            error["sameEmail"] = "Email are Not Same";
          } else if (!data?.confirmEmail) {
            flag = false;
            error["confirmEmail"] = "ConfirmEmail Is Requried";
          } 
          else if (!data?.newEmail) {
            flag = false;
            error["newEmail"] = "NewEmail Is Requried";
          } else {
            emailFlag = true;
            setChangeEmailFlag(true);
          }
        } 
        
      }
      if ((data?.newPwd || data?.confirmPwd || data.currentPwd) && flag == true) {
        if (!data.newPwd) {
          flag = false;
          error["newPwd"] = "New Password Missing";
        } else if (!data.confirmPwd) {
          flag = false;
          error["confirmPwd"] = "Confirm Password Missing";
        } else if (!data.currentPwd) {
          flag = false;
          error["currentPwd"] = "Current Password Missing";
        } else if (data.currentPwd == data.newPwd) {
          flag = false;
          error["oldPassword"] = "Passwords is Same as Old";
        } else if (data.confirmPwd != data.newPwd) {
          flag = false;
          error["samePassword"] = "New Passwords are not Same";
        } else {
          passFlag = true;
          setChangePassFlag(true);
        }
      }
    } else {
      flag = false;
      error["firstName"] = "Name Is Mandatory";
    }

    if (flag) {
    } else {
      setErr(error);
    }
    setVerifedData(data)
    
    return { emailFlag, passFlag, flag, error };
  };
  const submitForm = async (e) => {
    e.preventDefault();

    let { emailFlag, passFlag, flag, error } = await checkValidaiton();

    if (flag == true) {
   
    
      if (emailFlag == true || passFlag == true || nameChange == true) {
        if (emailFlag == true) {
          newEmailVerification();
        } else {
          props.sentSecurityemail();
          setLoader(true);
          Swal.fire({
            text: "Email Sent Succesfully!! Enter 8 Secret digit code",
            input: "text",
          }).then((result) => {
            if (result.value) {
              const data = {};
              data.secretkey = result.value;
              data.type = "userSettingSecretkey";

              props.verifySecretKey(data);
              setWaitingFlag(true);
              
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Your Action Is Unsaved Try Later",
              });
            }
          });
        }
      } 
    } else {
      console.log("error :", error);
      ENV.swlConfigAlert("Incorrect Data");
    }
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div>
          <Form onSubmit={submitForm} autoComplete="off">
            <div className="profile">
              <Row>
                <Col md={12}>
                  <h3>Basic Information</h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div>
                    <div className="account-inputs">
                      <label for="fnameOne">
                        Name <span className="red">*</span>
                      </label>
                      <input
                        id="fnameOne"
                        type="text"
                        placeholder="Enter Your Name"
                        Value={data?.firstName}
                        onChange={(e) => {
                          setData({ ...data, firstName: e.target.value });
                          setNameChange(true);
                        }}
                      />
                      {err.firstName ? (
                        <span className="red mt-2 d-block">
                          {err.firstName}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="email">
              <Row>
                <Col md={12}>
                  <h3>Update Email Address</h3>
                </Col>
              </Row>
              <Row>
                
                <Col md={6}>
                  <div className="account-inputs" autoComplete="new-password">
                  <label for="currEmail">Current Email</label>
                  <input
                        autoComplete="new-password"
                        id="currEmail"
                        name="currEmail"
                        type="text"
                        Value={activeEmail}
                        disabled
                        // onChange={(e) => {
                        //   setData({ ...data, currEmail: e.target.value });
                        // }}
                      />
                    
                    {err.currEmail ? (
                      <span className="d-block mt-2 red">{err.currEmail}</span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </Col>
               
                <Col md={6}>
                <form autoComplete="off">
                  <div className="account-inputs" autoComplete="off">
                  <label for="currEmail">Enter New Email</label>
                  <input
                         autoComplete="off"
                         id="newEmail"
                         name="newEmail"
                        type="email"
                        placeholder="Enter Your New Email"
                        Value={data?.newEmail}
                        onChange={(e) => {
                          setData({ ...data, newEmail: e.target.value });
                        }}
                      />
                  </div>
                  {err.newEmail ? (
                    <span className="d-block mt-2 red">{err.newEmail}</span>
                  ) : (
                    <span></span>
                  )}
                   </form>
                </Col>
               
               
                <Col md={6}>
                <form autoComplete="off">
                  <div className="account-inputs" autoComplete="off">
                  <label for="currEmail">Confirm Email</label>
                  <input
                         autoComplete="new-password"
                         id="cEmail"
                         name="confirmEmail"
                        type="email"
                        placeholder="Confirm Your New Email"
                        Value={data?.confirmEmail}
                        onChange={(e) => {
                          setData({ ...data, confirmEmail: e.target.value });
                        }}
                      />
                    
                  </div>
                  {err.confirmEmail ? (
                    <span className="d-block mt-2 red">{err.confirmEmail}</span>
                  ) : (
                    <span></span>
                  )}
                  </form>
                </Col>
                
               
                <Col>
                  {err.sameEmail ? (
                    <span className="d-block mt-2 red">{err.sameEmail}</span>
                  ) : (
                    <span></span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h3>Reset Password</h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div
                    className="account-inputs password-input-account"
                    autoComplete="new-password"
                  >
                    <PasswordInput
                      autoComplete="new-password"
                      label="Current Password"
                      name="currentPwd"
                      changeData={changeData}
                      //errorMsg={err?.currentPwd}
                      forOne="cPasswordThree"
                      id="cPasswordThree"
                    />
                  </div>
                  {err.currentPwd ? (
                    <span className="d-block mt-2 red">{err.currentPwd}</span>
                  ) : (
                    <span></span>
                  )}
                </Col>
                <Col md={6}>
                  <div className="account-inputs password-input-account">
                    <PasswordInput
                      label="Set New Password"
                      name="newPwd"
                      changeData={changeData}
                      //errorMsg={err?.newPwd}
                      forOne="nPassword"
                      id="nPassword"
                    />
                  </div>
                  {err.newPwd ? (
                    <span className="d-block mt-2 red">{err.newPwd}</span>
                  ) : (
                    <span></span>
                  )}
                </Col>
                <Col md={6}>
                  <div className="account-inputs password-input-account">
                    <PasswordInput
                      label="Confirm Password"
                      name="confirmPwd"
                      changeData={changeData}
                      //errorMsg={err?.confirmPwd}
                      forOne="cPasswordFour"
                      id="cPasswordFour"
                    />
                    {err.confirmPwd ? (
                      <span className="d-block mt-2 red">{err.confirmPwd}</span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                {" "}
                {err.samePassword ? (
                  <span className="d-block mt-2 red">{err.samePassword}</span>
                ) : (
                  <span></span>
                )}
                {err.oldPassword ? (
                  <span className="d-block mt-2 red">{err.oldPassword}</span>
                ) : (
                  <span></span>
                )}
              </Row>
            </div>
            <div className="security">
            
              <div className="d-flex justify-content-end align-items-center pt-3 pb-5">
                <button type="submit" className="purple-border-btn">
                  <span>Save</span>
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  secretKey: state.secretKey,
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, {
  updateProfile,
  beforeUser,
  updatePassword,
  enablesSecurity,
  sentSecurityemail,
  beforeSecretKey,
  getSecurity,
  beforeSecurity,
  beforeSentEmail,
  verifySecretKey,
  sentCodeOnNewEmail,
  verifyNewEmail,
  resetNewEmailFlag,
})(Profile_Setting);

