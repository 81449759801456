import { toast } from 'react-toastify';
import { ENV } from './../../config/config';
import { GET_ERRORS, GET_STACKING_PACKAGE,GET_SUBSCRIBED_PACKAGE,BEFORE_STACKING_PACKAGE} from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';

export const beforeStackingPackage = () => {
    return {
        type: BEFORE_STACKING_PACKAGE
    }
}

export const getStackingPackage = () => dispatch => {
     dispatch(emptyError());
 
    let url = `${ENV.url}package/list`;
   
     fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : '',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,

        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                
                type: GET_STACKING_PACKAGE,
                payload: data.data.packages,
            })
        } else {
            ENV.swlConfig(data.message,data.success)   
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message) 
            ENV.swlConfigAlert(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};


export const getSubscribedPackage = () => dispatch => {
    dispatch(emptyError());

   let url = `${ENV.url}subscription/list`;
  
    fetch(url, {
       method: 'GET',
       headers: {
           'Content-Type': 'application/json',
           'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : '',
           'Authorization': ENV.Authorization,
           'x-auth-token': ENV.x_auth_token,

       },
   }).then(res => res.json()).then(data => {
        //console.log("-->> ya date wala data a  ", data.data)
       if (data.success) {
           dispatch({
            type: GET_SUBSCRIBED_PACKAGE,
            payload: data.data,
           })
       } else {
           ENV.swlConfig(data.message,data.success)
           dispatch({
               type: GET_ERRORS,
               payload: data
           })
       }
   }).catch(error => {
       if (error.response && error.response.data) {
           const { data } = error.response
           if (data.message)
                ENV.swlConfigAlert(data.message)
       }
       dispatch({
           type: GET_ERRORS,
           payload: error
       })
   })
};






