import { InjectedConnector } from '@web3-react/injected-connector';
import { ENV } from '../../config/config';
const { defaultChainId, chainsConfigs } = ENV;
const POLLING_INTERVAL = 15000;
let chains =[];
let chainIds = Object.keys(chainsConfigs);
let networks={};
for(let x=0; x < chainIds.length; x++) {
  let chainId = parseInt(chainIds[x]);
  networks[chainId] = chainsConfigs[chainId].rpcUrl;
  chains.push(chainId);
}
export const injected = new InjectedConnector({
  supportedChainIds: chains,
});

const connectors = {
  injected: {
    provider: injected,
    name: 'MetaMask',
  }
};

export default connectors;
