import React from "react";

const Layout3 = (props) => {
    return (
        <div className="position-relative">
            {props.children}
        </div>
    );
}

export default Layout3;
