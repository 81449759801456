import React, { useState, useEffect } from "react";
import "./myAccount.css";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import TextInput from "../textInput/textInput";
import {
  updateProfile,
  beforeUser,
  updatePassword,
} from "../../components/signup/user.action";
import PasswordInput from "../signup/passwordInput";
import CheckSecurity from "../qrCode/checkSecurity";

function Email(props) {
  const [data, setData] = useState({
    newEmail: "",
    confirmEmail: "",
    password: "",
  });
  const [err, setErr] = useState({});
  const [loader, setLoader] = useState(false);
  const [renderSecurity, setRednerSecuirty] = useState(false);

  const changeData = (name, value) => {
    let tempData = data;
    tempData[name] = value;
    setData({ ...tempData });
  };

  useEffect(() => {
    if (props.user.updateProfileAuth) {
      setLoader(false);
    }
  }, [props.user.updateProfileAuth]);

  useEffect(() => {
    if (props.error.error) {
      setLoader(false);
    }
  }, [props.error.error]);

  const checkValidaiton = () => {
    const { password, newEmail, confirmEmail } = data;
    let isValid = true;
    let error = {};
    if (password === "") {
      error["password"] = "Field is Requried";
      isValid = false;
    }
    if (newEmail === "") {
      error["newEmail"] = "Field is Requried";
      isValid = false;
    }
    if (confirmEmail === "") {
      error["confirmEmail"] = "Field is Requried";
      isValid = false;
    }
    if (newEmail !== "" && confirmEmail !== "" && newEmail !== confirmEmail) {
      error["formErr"] = "Both Email should be same.";
      isValid = false;
    }
    setErr(error);
    return isValid;
  };

  const setVerified = (value) => {
    if (value) {
      setLoader(true);
      props.beforeUser();
      props.updateProfile(data);
    }
    setRednerSecuirty(true);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (checkValidaiton()) {
      setRednerSecuirty(true);
    }
  };

  return (
    <div className="email">
      {renderSecurity && (
        <CheckSecurity
          setVerified={setVerified}
          securityType="changeEmail"
          redirctPath="/dashboard"
        />
      )}
      <Row>
        <Col md={12}>
          <h3>Update Email Address</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="account-inputs">
            <TextInput
              name="newEmail"
              type="email"
              label="Enter new email"
              errorMsg={err["newEmail"]}
              changeData={changeData}
              forTwo="newEmail"
              id="newEmail"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="account-inputs">
            <TextInput
              name="confirmEmail"
              type="email"
              label="Confirm email"
              errorMsg={err["confirmEmail"]}
              changeData={changeData}
              forTwo="cEmail"
              id="cEmail"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3>Reset Password</h3>
        </Col>
      </Row>
      <Row>
        {/* <Col md={6}>
                    <div className="account-inputs password-input-account ">
                        <PasswordInput name="password" label="Password" changeData={changeData} errorMsg={err?.password} for="passwordFour" id="passwordFour" />
                    </div>
                    {err["formErr"] && <p className="error">{err["formErr"]}</p>}
                </Col> */}
        <Col md={6}>
          <div className="account-inputs password-input-account">
            <PasswordInput
              label="Current Password"
              name="currentPwd"
              changeData={changeData}
              errorMsg={err?.currentPwd}
              forOne="cPasswordThree"
              id="cPasswordThree"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="account-inputs password-input-account">
            <PasswordInput
              label="Set new Password"
              name="newPwd"
              changeData={changeData}
              errorMsg={err?.newPwd}
              forOne="nPassword"
              id="nPassword"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="account-inputs password-input-account">
            <PasswordInput
              label="Confirm Password"
              name="confirmPwd"
              changeData={changeData}
              errorMsg={err?.confirmPwd}
              forOne="cPasswordFour"
              id="cPasswordFour"
            />
            {err["formErr"] && (
              <p className="error position-absolute">{err["formErr"]}</p>
            )}
          </div>
          {props?.error?.error?.message && (
            <p>{props?.error?.error?.message}</p>
          )}
        </Col>
      </Row>
      {props.user.updateProfileMsg && <p>{props.user.updateProfileMsg}</p>}
      {props?.error?.error?.message && <p>{props?.error?.error?.message}</p>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  error: state.error,
});

export default connect(mapStateToProps, { updateProfile, beforeUser })(Email);
