import React, { useState, useEffect } from "react";
import HomeBanner from "../home-banner/home-banner";
import HowWorks from "../how-it-works/how-it-works";
import StakingPackages from "../staking-packages/staking-packages";

function Home(props) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <React.Fragment>
      <HomeBanner />
      <HowWorks />
      <StakingPackages />
    </React.Fragment>
  );
}

export default Home;
