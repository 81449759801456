import { GET_SETINGS_LINKS, BEFORE_SETTING_LINKS } from "../../redux/types";

const initialState = {
  soical_link: null,
  linkAuth: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETINGS_LINKS:
      return {
        ...state,
        soical_link: action.payload,
        linkAuth: true,
      };
    case BEFORE_SETTING_LINKS:
      return {
        soical_link: null,
        linkAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}
