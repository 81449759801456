import React, { useState, useEffect } from "react";
import { ENV } from "../../config/config";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Images } from "../../assets/assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getSettings, beforeSetting } from "../footer/footer.action";
import { chainLinkRates } from "../../utils/web3";
import "./home-banner.css";
import Wallet from "../wallet/wallet";

const HomeBanner = (props) => {
  const [coinPrice, setCoinPrice] = useState(0);
  const [USDTRate, setUSDTRate] = useState(0);
  const [rates, setRates] = useState(null);
  const [coinPriceChnage, setCoinPriceChnage] = useState(0);
  const [loader, setLoader] = useState(false);
  const userLogin = localStorage.getItem("accessToken");
  // useEffect(() => {
  //   props.getSettings(); ///get API
  // }, []);

  // useEffect(() => {
  //   if (props.footer.linkAuth) {
  //     const settingData = props.footer.soical_link;
  //     setUSDTRate(parseFloat(settingData.USDT).toFixed(2));
  //     setCoinPrice(parseFloat(settingData.price).toFixed(2));
  //     setCoinPriceChnage(settingData.changeInPrice);
  //     setLoader(false);
  //     props.beforeSetting();
  //   }
  // }, [props.footer.linkAuth]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       let rates = await chainLinkRates();
  //       setRates(rates);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   fetchData();
  //   return () => {
  //     // this now gets called when the component unmounts
  //   };
  // }, []);
  return (
    <div className="home-banner">
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container>
          <Row>
            <Col xxl={8} lg={7} className="mb-lg-0 mb-5">
              <div className="banner-data">
                <span className="small-tag">
                  Receive a <strong>Corresponding</strong>
                </span>
                <h1>Reward EveryDay</h1>
                <p>
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt. Nam sem lacus, ornare non ante sed,
                  ultricies fringilla massa.
                </p>
                <div className="banner-buttons d-flex align-items-center">
                  {userLogin ? (
                    <></>
                  ) : (
                    <Link
                      to="/login"
                      className="purple-border-btn d-flex justify-content-center align-items-center"
                    >
                      <span>Join Us</span>
                    </Link>
                  )}

                  <Wallet name="purple-wallet3" nameTwo="white-wallet3" />
                  {/* <button className='transparent-btn'><span>Connect Wallet 14</span></button> */}
                </div>
              </div>
            </Col>
            {/* <Col xxl={4} lg={5}>
              <div className="realtime-value">
                <div className="card-header text-center">
                  <span>Real Time Value</span>
                </div>
                <div className="card-body">
                  <div className="realtime-data d-flex justtify-content-between align-items-center">
                    <div className="currency-data d-flex justtify-content-between align-items-center">
                      <div className="currency-img purple-bg d-flex justify-content-center align-items-center">
                        <span className="fxm-tag">FIXM</span>
                      </div>
                      <div className="currency-name">
                        <span className="main-value">FIXM Coin</span>
                        <span className="light-value"> 1 FIXM = {USDTRate} USDT </span>
                      </div>
                    </div>
                    <div className="currency-value">
                      <span className="main-value">${coinPrice}</span>
                      {coinPriceChnage > 0 ? (
                        <div className="arc-value green d-flex justify-content-end align-items-center">
                          <span>{coinPriceChnage}</span>
                          <span className="ms-2">
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className="icon"
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="arc-value red d-flex justify-content-end align-items-center">
                          <span>{coinPriceChnage}</span>
                          <span className="ms-2">
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              className="icon"
                            />
                          </span>
                        </div>
                      )}
                         
                    </div>
                   
                  </div>
                  
                  <div className="realtime-data d-flex justtify-content-between align-items-center">
                    <div className="currency-data d-flex justtify-content-between align-items-center">
                      <div className="currency-img purple-bg d-flex justify-content-center align-items-center">
                       <span className="fxm-tag">USDT</span>
                      </div>
                      <div className="currency-name">
                        <span className="main-value">USDT Coin</span>
                        <span className="light-value"> 1 USDT = {parseFloat((1/USDTRate)).toFixed(2)}FIXM</span>
                      </div>
                    </div>
                    <div className="currency-value">
                     
                      <span className="main-value">${parseFloat((USDTRate)*(coinPrice)).toFixed(2)}</span>
                      {coinPriceChnage > 0 ? (
                        <div className="arc-value green d-flex justify-content-end align-items-center">
                          <span>{parseFloat((USDTRate)*coinPriceChnage).toFixed(2)}</span>
                          <span className="ms-2">
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className="icon"
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="arc-value red d-flex justify-content-end align-items-center">
                          <span>{parseFloat((USDTRate)*coinPriceChnage).toFixed(2)}</span>
                          <span className="ms-2">
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              className="icon"
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  footer: state.footer,
  error: state.error,
});

export default connect(mapStateToProps, {
  getSettings,
  beforeSetting,
})(HomeBanner);
